import {
  H6,
  hitSlop,
  MaterialCommunityIcons,
  useTheme,
  VectorIcon,
  VectorIconProps,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import { ActivityIndicator, IconButton } from "react-native-paper"

import { ICON_MEDIUM_SIZE, iconSize } from "../config/Constants"
import { Card } from "../features/models/Card"
import { BottomDoubleButtonsMaterial, SentenceCard } from "./"

export enum Mode {
  share = "home",
  delete = "delete",
  create = "image",
  createMultiple = "image-multiple",
}

export interface ConfirmationDialogProps {
  card?: Card
  mode: Mode
  title?: string
  loading?: boolean
  onClear?: () => void
  onConfirm?: () => void
}

export const ConfirmationDialogContent: FC<ConfirmationDialogProps> = ({
  mode,
  card,
  title,
  loading,
  onClear,
  onConfirm,
}) => {
  const {
    dimensions: { spacing },
    colors: { black, secondary, primary },
  } = useTheme()
  const { t } = useTranslation()

  const s = useMemo(
    () => ({
      icon: {
        padding: spacing / 2,
      },
      iconContainer: [
        styles.iconContainer,
        {
          margin: spacing / 2,
        },
      ],
      iconMultipleContainer: [
        styles.iconMultipleContainer,
        {
          padding: spacing * 2,
        },
      ],
      indicator: {
        paddingVertical: spacing / 2,
      },
      bottom: { marginHorizontal: spacing },
      view: [styles.view, { paddingHorizontal: spacing / 2 }],
      hitSlop: hitSlop(spacing / 2),
      title: [
        styles.title,
        {
          paddingTop: spacing / 2,
          paddingHorizontal: spacing,
          paddingBottom: spacing,
        },
      ],
    }),
    [spacing],
  )

  const iconProps = useMemo(
    () =>
      ({
        size: iconSize.regular,
        category: "MaterialCommunityIcons",
      } as VectorIconProps),
    [],
  )

  return (
    <>
      {title ? <H6 style={s.title}>{title}</H6> : undefined}
      <View style={s.view}>
        {mode === Mode.createMultiple ? (
          <View style={s.iconMultipleContainer}>
            <MaterialCommunityIcons color={primary} size={iconSize.regular * 3} name="cards" />
          </View>
        ) : (
          <SentenceCard card={card} />
        )}
        <View style={s.iconContainer}>
          <MaterialCommunityIcons
            {...iconProps}
            name="arrow-right"
            color={black.disabled}
            style={styles.centerIcon}
          />
          <MaterialCommunityIcons {...iconProps} color={black.disabled} name={mode} />
        </View>
      </View>
      {(mode !== Mode.delete && onConfirm) || mode === Mode.createMultiple ? (
        loading ? (
          <ActivityIndicator style={s.indicator} color={secondary} size={ICON_MEDIUM_SIZE} />
        ) : (
          <>
            <IconButton
              onPress={onConfirm}
              style={styles.button}
              hitSlop={s.hitSlop}
              icon={() => (
                <VectorIcon
                  {...iconProps}
                  name="check-circle"
                  color={secondary}
                  accessibilityLabel={t("common:button.validate")}
                />
              )}
            />
          </>
        )
      ) : (
        <BottomDoubleButtonsMaterial
          clearIcon="close-circle"
          {...{ onClear: onClear ?? _.noop, loading }}
          onSave={onConfirm ?? _.noop}
          bottomViewStyle={s.bottom}
          saveIcon="checkbox-marked-circle"
          clearText={t("common:button.cancel")}
          saveText={t("common:button.validate")}
        />
      )}
    </>
  )
}
const styles = StyleSheet.create({
  centerIcon: { flex: 1, textAlign: "center", justifyContent: "center" },
  iconContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconMultipleContainer: {
    flex: 1,
    alignItems: "center",
    alignSelf: "center",
  },
  button: {
    alignSelf: "center",
  },
  view: {
    alignItems: "center",
    flexDirection: "row",
  },
  title: { textAlign: "center" },
})
