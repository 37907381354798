import {
  Button,
  generateShadow,
  IUserContext,
  ProgressionItem,
  userContext,
  VectorIcon,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { FC, useCallback, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import { IconButton } from "react-native-paper"
import * as Progress from "react-native-progress"

import { ICON_MEDIUM_SIZE, MAX_TIME } from "../../config/Constants"
import { UserData } from "../../features/models/UserData"
import {
  addUserFavorites,
  deleteUserFavorites,
  isEditable,
} from "../../features/models/UserDataFunctions"
import { useTheme } from "../../utils/defaultStyle"
import isWeb from "../../utils/isWeb"
import { ns } from "./i18n/fr"

interface IProps {
  time: number
  l3cardPath: string
  isStopped: boolean
  isFreeSpace: boolean
  onShare: () => void
  onManageCount: () => void
}

const SentenceFooter: FC<IProps> = ({
  isStopped,
  onShare,
  onManageCount,
  time,
  l3cardPath,
  isFreeSpace,
}) => {
  const {
    colors: {
      primary,
      white: { highEmphasis: white },
      black,
      secondary,
    },
    dimensions: { spacing, buttonSize },
  } = useTheme()
  const { t } = useTranslation(ns)
  const { userData, userDocRef } = useContext<IUserContext<UserData>>(userContext)

  const editable = isEditable(userData)
  const isFavorite = _.includes(userData?.favorites, l3cardPath)

  const s = useMemo(
    () => ({
      icon: {
        backgroundColor: white,
        ...generateShadow(4),
      },
      row: [styles.row, { padding: spacing }],
      buttonLeft: [
        styles.button,
        styles.shadow,
        {
          marginRight: isFreeSpace ? 0 : spacing,
        },
      ],
      contentButton: {
        height: buttonSize,
        backgroundColor: white,
      },
    }),
    [isFreeSpace, spacing, white, buttonSize],
  )

  const iconFavProps = useMemo(
    () => ({
      name: isFavorite ? "star" : "star-outline",
      color: isFavorite ? secondary : primary,
    }),
    [isFavorite, primary, secondary],
  )

  const onFavorite = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(isFavorite ? deleteUserFavorites : addUserFavorites)(l3cardPath, userDocRef)
  }, [isFavorite, l3cardPath, userDocRef])

  const props = useMemo(
    () => ({
      icon: {
        size: ICON_MEDIUM_SIZE,
        style: s.icon,
        color: primary,
      },
      progress: {
        color: editable ? black.disabled : primary,
        borderColor: black.transparent,
      },
    }),
    [black, editable, primary, s],
  )

  const commonIconProps = {
    ...props.icon,
    onPress: onManageCount,
    disabled: editable,
    accessibilityLabel: t(isStopped ? "play" : "pause"),
    icon: isStopped ? "play" : "pause",
  }

  return (
    <>
      <View style={s.row}>
        {isWeb ? null : (
          <Button
            onPress={onShare}
            contentStyle={s.contentButton}
            style={s.buttonLeft}
            icon={() => (
              <VectorIcon color={primary} name="share-variant" category="MaterialCommunityIcons" />
            )}
          >
            {t("common:button.share")}
          </Button>
        )}
        {isFreeSpace ? null : (
          <Button
            onPress={onFavorite}
            contentStyle={s.contentButton}
            style={[styles.button, styles.shadow]}
            icon={() => <VectorIcon category="MaterialCommunityIcons" {...iconFavProps} />}
          >
            {t("favorite")}
          </Button>
        )}
      </View>
      <View style={styles.view}>
        {isWeb ? (
          <>
            <ProgressionItem
              size={60}
              borderWidth={4}
              item={{
                key: "1",
                // If the value is 100, the button becomes hidden
                percent: editable ? 99.9 : (time / MAX_TIME) * 100,
                hideSeparator: true,
              }}
            />
            <IconButton
              {...commonIconProps}
              style={{
                ...commonIconProps.style,
                position: "absolute",
                alignSelf: "center",
                backgroundColor: white,
              }}
            />
          </>
        ) : (
          <>
            <IconButton {...commonIconProps} />
            <Progress.Circle
              size={60}
              borderWidth={4}
              pointerEvents="none"
              style={styles.progress}
              progress={editable ? 1 : time / MAX_TIME}
              {...props.progress}
            />
          </>
        )}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  button: {
    flex: 1,
    borderWidth: 0,
  },
  iconButton: {
    borderRadius: 100,
  },
  progress: {
    position: "absolute",
  },
  row: {
    flexDirection: "row",
  },
  shadow: generateShadow(3),
  view: {
    alignSelf: "center",
  },
})

export default SentenceFooter
