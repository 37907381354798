import { delay, logger } from "@siruplab/capsule"
import React, { createContext, ReactNode, useCallback, useState } from "react"

const retNbrForFn = async (fn: (...args: any) => Promise<unknown>, nbr: number) =>
  fn().then(() => nbr)

const DEFAULT_TIMEOUT = 50

interface Props {
  children: ReactNode
  ms?: number
}

export interface IOptimisticContext<T> {
  value: T
  setValue: (
    handler: (val?: T) => void | Promise<void>,
    onContinue: () => void | Promise<void>,
    value: T,
  ) => void | Promise<void>
}

export const optimisticContext = createContext<IOptimisticContext<any>>(
  {} as IOptimisticContext<any>,
)

export const OptimisticProvider = ({ children, ms = 1500 }: Props) => {
  const [optimisticValue, setOptimisticValue] = useState<any>()

  const setValue = useCallback(
    async <T,>(
      handler: () => void | Promise<void>,
      onContinue: () => void | Promise<void>,
      val: T,
    ) => {
      setOptimisticValue(val)
      try {
        const res = await Promise.race([
          retNbrForFn(async () => handler?.(), 0),
          retNbrForFn(async () => delay(ms), 1),
        ])
        // eslint-disable-next-line no-unused-expressions
        res === 0 && (await delay(DEFAULT_TIMEOUT))
      } catch (e) {
        logger("error", e)
      } finally {
        setOptimisticValue(undefined)
        onContinue?.()
      }
    },
    [ms],
  )

  return (
    <optimisticContext.Provider value={{ value: optimisticValue, setValue }}>
      {children}
    </optimisticContext.Provider>
  )
}
