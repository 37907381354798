import { useCallback, useRef, useState } from "react"

type ProcessStatus = "idle" | "running" | "success" | "error"

interface ProcessState<Payload> {
  status: ProcessStatus
  payload?: Payload
}

const defaultState = {
  status: "idle" as const,
}

const useProcess = <Payload>() => {
  const [state, setState] = useState<ProcessState<Payload>>(defaultState)
  const resolvePromise = useRef<(value: Payload | PromiseLike<Payload>) => void>()

  const rejectPromise = useRef<((reason?: any) => void) | null>()

  const process = useCallback(
    (payload: Payload) => {
      setState({ status: "running", payload })

      return new Promise<Payload>((resolve, reject) => {
        resolvePromise.current = resolve
        rejectPromise.current = reject
      })
    },
    [setState],
  )

  const onCompleteProcess = useCallback((payload: Payload) => {
    setState({ status: "success", payload })
    resolvePromise.current?.(payload)
  }, [])

  const onCancelProcess = useCallback(() => {
    setState(defaultState)
    rejectPromise.current?.()
  }, [])

  const onErrorProcess = useCallback(() => {
    setState({ status: "error" })
    rejectPromise.current?.()
  }, [])

  return { state, process, onCompleteProcess, onCancelProcess, onErrorProcess }
}

export default useProcess
