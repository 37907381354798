import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import {
  Feather,
  FontAwesome,
  generateShadow,
  hitSlop,
  ICardItem,
  IUserContext,
  MaterialCommunityIcons,
  PresentationListView,
  userContext,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { FC, useCallback, useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import { Checkbox, IconButton, Menu } from "react-native-paper"

import { Level } from "../common/types"
import { iconSize } from "../config/Constants"
import {
  maxWidthCard,
  webCardWidth,
  webCardWidthTwoCol,
  widthBottomComp,
  widthBottomCompThree,
} from "../features/config/useTabland"
import { FirebaseCards } from "../features/models/Card"
import { FirCardToCard } from "../features/models/CardFunctions"
import { UserData } from "../features/models/UserData"
import { getUserColumns, isEditable } from "../features/models/UserDataFunctions"
import { ns } from "../screens/GalleryScreen/i18n/fr"
import { ValidatedCards } from "../screens/HomeScreen/HomeScreen"
import { useTheme } from "../utils/defaultStyle"
import { useCollection } from "../utils/firestore"
import isWeb from "../utils/isWeb"
import Breadcrumbs from "./Breadcrumbs"
import { GridScreen } from "./GridScreen"
import { ProgressBar } from "./ProgressBar"
import { ValidateCardButton } from "./ValidateCardButton"

export type CardButton = "parameters" | "edit" | "delete" | "validate"

export interface ICardItemExt extends ICardItem {
  ref?: FirebaseFirestoreTypes.DocumentReference
  search: string
}

interface IProps {
  collectionQuery: FirebaseFirestoreTypes.Query | undefined
  collectionRef?: FirebaseFirestoreTypes.CollectionReference
  currentLevel: Level
  onCardPress?: (item: ICardItemExt) => void
  onCardButtonPress: (item: ICardItemExt, button: CardButton) => void
  onCheckCard?: (item: ICardItemExt) => void
  filter?: string
  isGallery?: boolean
  isFreeSpace?: boolean
  checkedCards?: Record<string, boolean>
  isAddRoute?: boolean
  validatedCards?: ValidatedCards
  isValidation?: boolean
  specificColumnNumber?: number | undefined
  hierarchySettingsLoading?: boolean
}

export const CardsView: FC<IProps> = ({
  collectionQuery,
  collectionRef,
  onCardPress,
  onCardButtonPress,
  isGallery = false,
  isFreeSpace = false,
  onCheckCard,
  currentLevel,
  filter,
  checkedCards,
  isAddRoute,
  validatedCards,
  isValidation: isValidation,
  specificColumnNumber,
  hierarchySettingsLoading,
}) => {
  const {
    colors: {
      primary,
      overrides,
      white: { highEmphasis: white },
      black: { disabled },
      surface: { appUi },
    },
    dimensions: { spacing, borderRadius },
  } = useTheme()

  const { userData } = useContext<IUserContext<UserData>>(userContext)
  const { t } = useTranslation(ns)

  const editable = userData && isEditable(userData)
  const numColumns = isAddRoute
    ? 3
    : isValidation
    ? 1
    : specificColumnNumber ?? getUserColumns(userData, isFreeSpace)

  const numButtons = currentLevel === Level.first && !isGallery ? 3 : 2

  const [cards, loading] = useCollection(collectionQuery) as FirebaseCards

  const [visible, setVisible] = useState(undefined)

  const openMenu = i => () => setVisible(i)

  const closeMenu = () => setVisible(undefined)

  const s = useMemo(
    () => ({
      cardContent: [
        styles.cardContent,
        {
          paddingTop: spacing / 2,
          paddingHorizontal: spacing / 2,
        },
      ],
      card: [
        styles.card,
        {
          width: isWeb ? (numColumns === 2 ? webCardWidthTwoCol : webCardWidth) : maxWidthCard,
          borderRadius,
        },
      ],
      checkboxView: [
        styles.checkBoxView,
        {
          borderRadius: borderRadius / 2,
          backgroundColor: appUi,
        },
      ],
      bottomComp: [
        styles.bottomComp,
        {
          width: numButtons === 3 ? widthBottomCompThree : widthBottomComp,
        },
      ],
      gridTitle: [
        styles.gridTitleStyle,
        {
          width: "100%",
          borderRadius,
          marginBottom: spacing / 2,
        },
      ],
      gridImageStyle: [
        styles.gridImageStyle,
        {
          borderTopRightRadius: borderRadius,
          borderTopLeftRadius: borderRadius,
        },
      ],
      commonIconProps: {
        size: iconSize.common,
        color: primary,
      },
      openMenu: {
        marginHorizontal: spacing / 2,
      },
      buttonContainer: {
        marginTop: spacing / 2,
      },
      hitSlop: hitSlop(spacing / 2),
    }),
    [appUi, numButtons, primary, spacing, borderRadius, numColumns],
  )

  const data = useMemo(
    (): ICardItemExt[] =>
      _.filter(cards?.docs, item => !item.data().h).map(item => {
        const card = FirCardToCard(item.data(), item.id)
        const isValidatedCard = !isGallery && validatedCards?.[item.id] === true
        const backgroundColor = item.data().c === overrides?.textInput ? white : item.data().c
        return {
          ...(numColumns < 3 ? { title: card.title } : { subtitle: card.title }),
          id: card.id,
          ref: item.ref,
          image: card.url,
          order: card.order,
          search: _.deburr(card.title).toLowerCase(),
          titleStyle: [styles.textStyle, isValidatedCard ? { color: disabled } : null],
          subtitleStyle: styles.textStyle,
          titleViewStyle: [s.gridTitle, { backgroundColor }],
          subtitleViewStyle: [s.gridTitle, { backgroundColor }],
          imageStyle: [styles.gridImageStyle, isValidatedCard ? { opacity: 0.3 } : null],
        }
      }),
    [cards, numColumns, overrides, s, white, validatedCards, disabled, isGallery],
  )

  const filteredData = useMemo(
    () =>
      filter
        ? _.filter(data, d => _.includes(d.search, filter) || (isAddRoute && checkedCards?.[d?.id]))
        : data,
    [checkedCards, data, filter, isAddRoute],
  )

  const cardBottomRender = useCallback(
    (
      item: ICardItemExt,
      onPress: (it: ICardItemExt, button: CardButton) => void = onCardButtonPress,
      overflow = false,
    ) => {
      const isCardValidated = validatedCards?.[item.id]
      return (
        <View
          style={[
            s.bottomComp,
            overflow ? s.openMenu : s.buttonContainer,
            Level.third ? styles.overrideButtonContainer : null,
          ]}
        >
          {currentLevel === Level.first && !isGallery ? (
            <IconButton
              style={styles.iconButton}
              onPress={() => onPress(item, "parameters")}
              hitSlop={s.hitSlop}
              icon={() => (
                <FontAwesome
                  {...s.commonIconProps}
                  name="cog"
                  accessibilityLabel={t("cardActions.parameters")}
                />
              )}
            />
          ) : null}
          {editable ? (
            <>
              <IconButton
                style={styles.iconButton}
                onPress={() => onPress(item, "edit")}
                hitSlop={s.hitSlop}
                icon={() => (
                  <MaterialCommunityIcons
                    {...s.commonIconProps}
                    name="pencil"
                    accessibilityLabel={t("cardActions.edit")}
                  />
                )}
              />
              <IconButton
                style={styles.iconButton}
                onPress={() => onPress(item, "delete")}
                hitSlop={s.hitSlop}
                icon={() => (
                  <MaterialCommunityIcons
                    {...s.commonIconProps}
                    name="delete"
                    accessibilityLabel={t("cardActions.delete")}
                  />
                )}
              />
            </>
          ) : isValidation ? (
            <ValidateCardButton
              onPress={() => onPress(item, "validate")}
              isCardValidated={isCardValidated}
              style={styles.validateButton}
            />
          ) : null}
        </View>
      )
    },
    [onCardButtonPress, s, currentLevel, t, validatedCards, editable, isGallery, isValidation],
  )

  const onCardButtonPressOverflow = useCallback(
    (item: ICardItemExt, button: CardButton) => {
      closeMenu()
      onCardButtonPress(item, button)
    },
    [onCardButtonPress],
  )

  const imageInnerRender = useCallback(
    (item: ICardItemExt) => {
      const onPress = () => onCheckCard?.(item)
      return (
        <View style={s.checkboxView}>
          <Checkbox.Android
            {...{ onPress }}
            color={primary}
            hitSlop={s.hitSlop}
            status={checkedCards?.[item?.id] ? "checked" : "unchecked"}
          />
        </View>
      )
    },
    [checkedCards, onCheckCard, primary, s.checkboxView, s.hitSlop],
  )

  const overflowMenu = useCallback(
    item => (
      <View style={s.buttonContainer}>
        <Menu
          visible={visible === item}
          onDismiss={closeMenu}
          anchor={
            <IconButton
              style={styles.iconButton}
              onPress={openMenu(item)}
              icon={() => <Feather name="more-horizontal" {...s.commonIconProps} />}
            />
          }
        >
          {cardBottomRender(item, onCardButtonPressOverflow, true)}
        </Menu>
      </View>
    ),
    [s.buttonContainer, s.commonIconProps, visible, cardBottomRender, onCardButtonPressOverflow],
  )

  const progress = useMemo(() => {
    if (!userData?.theme.breadcrumbs && !isGallery) {
      return <ProgressBar progress={currentLevel} />
    }
    if (currentLevel !== Level.first && userData?.theme.breadcrumbs && collectionRef) {
      return <Breadcrumbs collectionRef={collectionRef} />
    }
    return null
  }, [collectionRef, currentLevel, isGallery, userData])

  return (
    <PresentationListView
      isEmpty={_.isEmpty(filteredData)}
      loading={loading || hierarchySettingsLoading}
      indicator={{ color: primary, size: "small" }}
    >
      {progress}

      <GridScreen
        // @ts-ignore improve later
        data={filteredData}
        loading={false}
        ratio="1"
        cardStyle={s.card}
        cardContentStyle={s.cardContent}
        imageStyle={s.gridImageStyle}
        numColumns={numColumns}
        imageInnerRender={isAddRoute ? imageInnerRender : undefined}
        cardBottomRender={
          (!editable && currentLevel !== Level.third) || isAddRoute
            ? undefined
            : numColumns === 3 && numButtons === 3 && !isWeb
            ? overflowMenu
            : cardBottomRender
        }
        onCardPress={isAddRoute ? onCheckCard : onCardPress}
        listContentStyle={styles.listContent}
        fromWeb={isWeb}
        webCardWidthTwoCol={webCardWidthTwoCol}
        webCardWidth={webCardWidth}
        maxWidthCard={maxWidthCard}
      />
    </PresentationListView>
  )
}

const styles = StyleSheet.create({
  card: {
    width: "100%",
    cursor: "pointer",
    alignSelf: "center",
    ...generateShadow(4),
  },
  checkBoxView: {
    top: 4,
    right: 4,
    position: "absolute",
  },
  listContent: {
    alignSelf: isWeb ? "center" : "auto",
  },
  textStyle: {
    textAlign: "center",
  },
  cardContent: {
    alignItems: "center",
    paddingBottom: 0,
  },
  bottomComp: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  gridImageStyle: {
    aspectRatio: 1,
  },
  gridTitleStyle: {
    height: 68,
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    margin: 0,
  },
  overrideButtonContainer: {
    width: "100%",
    justifyContent: "center",
  },
  validateButton: {
    marginHorizontal: 0,
  },
})
