import { ShareData } from "../../features/models/UserData"

export interface AlertState {
  loading: boolean
  shareVisible: boolean
  confirmVisible: boolean
  error: string | undefined
  shareData: ShareData | undefined
}

export type ActionName =
  | "code"
  | "loading_copy"
  | "loading_code"
  | "loading_share"
  | "error"
  | "completed"
  | "close"
  | "text"
  | "delete"
export interface AlertAction {
  type: ActionName
  error?: string
  data?: ShareData
}

export const defaultValues: AlertState = {
  loading: false,
  shareVisible: false,
  confirmVisible: false,
  error: undefined,
  shareData: undefined,
}

export function alertStateReducer(state: AlertState, action: AlertAction): AlertState {
  switch (action.type) {
    case "code": {
      return {
        ...state,
        shareVisible: true,
        confirmVisible: false,
      }
    }
    case "loading_copy":
      return {
        ...state,
        loading: true,
        shareVisible: true,
        confirmVisible: false,
      }
    case "text":
      return {
        ...state,
        loading: false,
        error: undefined,
        shareVisible: true,
        shareData: action.data,
      }
    case "loading_code":
      return {
        ...state,
        loading: true,
      }
    case "loading_share":
      return {
        ...state,
        loading: true,
        shareVisible: false,
        confirmVisible: true,
      }
    case "error":
      return {
        ...state,
        loading: false,
        confirmVisible: false,
        error: action.error,
      }
    case "completed":
      return {
        ...state,
        loading: false,
        shareData: undefined,
      }
    case "close":
      return {
        loading: false,
        error: undefined,
        shareVisible: false,
        shareData: undefined,
        confirmVisible: false,
      }
    case "delete":
      return {
        ...state,
        loading: false,
        shareVisible: false,
        confirmVisible: true,
      }
    default:
      return state
  }
}
