import { Button, generateShadow, MaterialCommunityIcons } from "@siruplab/capsule"
import React, { FC, useCallback, useMemo } from "react"
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native"

import { useTheme } from "../utils/defaultStyle"

interface IProps {
  disabled?: boolean
  loading?: boolean
  saveIcon: string
  saveText: string
  clearText: string
  clearIcon: string
  onSave: () => void
  onClear: () => void
  bottomViewStyle?: StyleProp<ViewStyle>
}

export const BottomDoubleButtonsMaterial: FC<IProps> = ({
  onSave,
  onClear,
  loading,
  disabled,
  saveIcon,
  saveText,
  clearIcon,
  clearText,
  bottomViewStyle,
}) => {
  const {
    dimensions: { spacing, buttonSize },
    colors: {
      error,
      primary,
      white: { highEmphasis: white },
      black: { disabled: disabledBlack, mediumEmphasis },
    },
  } = useTheme()

  const s = useMemo(
    () => ({
      button: [
        styles.button,
        {
          backgroundColor: white,
          marginVertical: spacing,
        },
      ],
      leftButton: {
        marginRight: spacing,
      },
      labelStyle: [
        {
          color: disabledBlack,
          marginLeft: spacing,
          textTransform: "none" as TextStyle["textTransform"],
        },
      ],
      buttonText: {
        marginLeft: spacing,
        color: disabledBlack,
      },
      buttonContent: {
        height: buttonSize,
      },
    }),
    [disabledBlack, spacing, white, buttonSize],
  )

  const ButtonComp = useCallback(
    ({ style, name, text, color, onButtonPress, cancelDisabled, confirmDisabled, busy }) => {
      const icon = () => (
        <MaterialCommunityIcons
          name={name}
          size={32}
          color={cancelDisabled || confirmDisabled ? disabledBlack : color}
        />
      )

      return (
        <Button
          icon={icon}
          disabled={cancelDisabled || confirmDisabled}
          loading={busy}
          onPress={onButtonPress}
          style={[s.button, style]}
          labelStyle={[
            s.labelStyle,
            { color: confirmDisabled || cancelDisabled ? disabledBlack : mediumEmphasis },
          ]}
          contentStyle={s.buttonContent}
        >
          {text}
        </Button>
      )
    },
    [disabledBlack, mediumEmphasis, s],
  )

  return (
    <View style={[styles.buttonMainView, bottomViewStyle]}>
      {ButtonComp({
        busy: false,
        color: error,
        name: clearIcon,
        text: clearText,
        style: s.leftButton,
        cancelDisabled: loading,
        onButtonPress: onClear,
      })}
      {ButtonComp({
        text: saveText,
        color: primary,
        name: saveIcon,
        busy: loading,
        onButtonPress: onSave,
        confirmDisabled: disabled || loading,
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  buttonMainView: {
    width: 480,
    maxWidth: "100%",
    alignSelf: "center",
    flexDirection: "row",
  },
  button: {
    flex: 1,
    borderWidth: 0,
    justifyContent: "center",
    ...generateShadow(4),
  },
  buttonView: {
    height: 36,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
})
