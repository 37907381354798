import { webCardWidth, webCardWidthTwoCol } from "../features/config/useTabland"

const getColumnWidth = (columns: number, spacing: number) => {
  switch (columns) {
    case 2:
      return columns * (webCardWidthTwoCol + spacing / 2)
    case 3:
      return columns * (webCardWidth + spacing / 2)
    default:
      return webCardWidth
  }
}

export default getColumnWidth
