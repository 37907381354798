export const mainRoutes = {
  NAVIGATION: "Navigation",
  WELCOME: "Welcome",
  LOGIN: "Login",
  SIGNUP: "SignUp",
  DISPLAY_NO_SETTINGS: "Display",
  DISPLAY: "Display_Settings",
  DISPLAY_HIERARCHY: "Hierarchy_Display_Settings",
  HOME_SORT: "Home_Sort",
  SENTENCE: "Sentence",
  MAIN: "MainNavigator",
  ADD_CARD: "Add_Card",
  ADD_PROCESS: "Add_Process",
} as const

export type RootRoutesNames = typeof mainRoutes[keyof typeof mainRoutes]

export const paramsTabsRoutes = {
  TAB_WRITE: "Tab_Write",
  TAB_DRAW: "Tab_Draw",
  TAB_TAKEPIC: "Tab_TakePic",
} as const

export const mainTabsRoutes = {
  CREATE_MAIN: "Create_Main",
  TAB_MAIN: "Tab_Main",
  CREATECARD_MAIN: "CreateCard_Main",
} as const

export const tabsRoutes = {
  HOME: "Home",
  GALLERY: "Gallery",
  CREATE: "Create",
  SETTINGS: "Settings",
  FAVORITES: "Favorites",
  FREESPACE_FAV: "FreeSpaceFav_Sentence",
} as const

export const freeSpaceFavRoutes = {
  FREE_SPACE_FAV_CREATE: "FreeSpaceFav_Create",
  FREE_SPACE_FAV_SORT: "FreeSpaceFav_Sort",
  FREE_SPACE_FAV_MAIN: "FreeSpaceFav_Main",
  FREE_SPACE_FAV_GALLERY_EDIT: "FreeSpaceFav_GalleryEdit",
} as const

export const galleryRoutes = {
  GALLERY_CREATE: "Gallery_Create",
  GALLERY_MENU: "Gallery_CreateMenu",
  GALLERY_MAIN: "Gallery_Main",
  GALLERY_EDIT: "Gallery_Edit",
} as const

export const homeRoutes = {
  HOME_EDIT: "Home_Edit",
  HOME_MAIN: "Home_Main",
  HOME_SORT: "Home_Sort",
} as const

export const settingsRoutes = {
  SETTINGS_MAIN: "Settings_Main",
} as const

export const createRoutes = {
  CREATE_MAIN: "Create_Main",
  CREATECARD_MAIN: "CreateCard_Main",
} as const

export const favoritesRoutes = {
  TAB_FREECOMMSPACE: "Tab_FreeCommSpace",
  TAB_FAVORITES: "Tab_Favorites",
} as const
