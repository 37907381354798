import { FirebaseFirestoreTypes, firestore, functions, logger, openUrl } from "@siruplab/capsule"

import { ComicSansMS, OpenDyslexic, Roboto } from "../../config/Constants"
import { getPaipsUrl } from "../../screens/SettingsScreen/Constants"
import { FirCard } from "./Card"
import { Font, Perso, UserData } from "./UserData"

export const updateUserData = async (
  userDocRef: FirebaseFirestoreTypes.DocumentReference<FirebaseFirestoreTypes.DocumentData> | null,
  values: Partial<UserData>,
) => {
  userDocRef?.set(values, { merge: true }).catch(e => logger("update user error", e))
}

export const addUserFavorites = async (
  card: string,
  userDocRef: FirebaseFirestoreTypes.DocumentReference<FirebaseFirestoreTypes.DocumentData> | null,
) => {
  userDocRef?.set({ favorites: firestore.FieldValue.arrayUnion(card) }, { merge: true })
}

export const deleteUserFavorites = async (
  card: string,
  userDocRef: FirebaseFirestoreTypes.DocumentReference<FirebaseFirestoreTypes.DocumentData> | null,
) => userDocRef?.set({ favorites: firestore.FieldValue.arrayRemove(card) }, { merge: true })

export const getOrderedCardsQuery = (
  collectionRef?: FirebaseFirestoreTypes.CollectionReference<FirebaseFirestoreTypes.DocumentData>,
): FirebaseFirestoreTypes.Query | undefined => collectionRef?.orderBy("o", "asc")

export const getUserFont = (userData?: UserData) => {
  switch (userData?.theme?.font) {
    case Font.comic:
      return ComicSansMS
    case Font.dys:
      return OpenDyslexic
    default:
      return Roboto
  }
}

export const forcePerso: { current?: Perso | null } = { current: undefined }
export const getPerso = (userData?: UserData): Perso =>
  forcePerso.current ?? userData?.perso ?? Perso.unlocked

export const isEditable = (userData?: UserData) => getPerso(userData) === Perso.unlocked

// noinspection SpellCheckingInspection
export const isAdminable = (userData?: UserData): boolean =>
  userData ? getPerso(userData) !== Perso.paramLock || !userData.lockCode : false

export const getUserColumns = (userData: UserData | undefined, isFreeSpace: boolean): number =>
  (isFreeSpace ? userData?.theme?.columnsFreeSpace : userData?.theme?.columns) ?? 2

export const getUserBreadcrumbs = (userData?: UserData): boolean =>
  userData?.theme?.breadcrumbs ?? true

export const getUserGalleryCard = async (
  cardId: string,
  userDocRef: FirebaseFirestoreTypes.DocumentReference<FirebaseFirestoreTypes.DocumentData> | null,
  col = "g",
) => (await userDocRef?.collection(col).doc(cardId).get())?.data() as FirCard

export const getUserCollectionMinOrder = async (
  path: string,
  userDocRef: FirebaseFirestoreTypes.DocumentReference<FirebaseFirestoreTypes.DocumentData> | null,
): Promise<number> => {
  const minDocs = (await userDocRef?.collection(`${path}`).orderBy("o", "asc").limit(1).get())?.docs
  return (minDocs?.[0]?.data() as FirCard)?.o ?? 0
}

export const getUsernameOrOverride = (userData?: UserData): string =>
  userData?.wpUsernameOverride ?? userData?.wpUsername ?? "-"

export const handleLoginOnPaips = async () => {
  try {
    const { data } = await functions().httpsCallable("generatePaipsFrToken")()
    openUrl(getPaipsUrl(data)).catch(logger)
  } catch (err) {
    logger(err)
  }
}
