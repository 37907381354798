import { useNavigation } from "@react-navigation/native"
import { generateShadow, useAlert, userContext } from "@siruplab/capsule"
import { useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import { useTheme } from "../../utils/defaultStyle"
import { ns } from "./i18n/fr"

export const useShareCreateCard = () => {
  const {
    colors: {
      primary,
      white: { highEmphasis: white },
    },
    dimensions: { spacing },
    screenStyle,
  } = useTheme()
  const { t } = useTranslation(ns)
  const { navigate } = useNavigation()

  const { userDocRef } = useContext(userContext)
  const { showSnack } = useAlert()

  const collection = "g"

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  const s = useMemo(
    () => ({
      button: [
        createCardStyle.button,
        {
          marginBottom: spacing,
        },
      ],
      contentButton: [
        createCardStyle.contentButton,
        {
          backgroundColor: white,
          paddingVertical: spacing / 2,
          paddingHorizontal: spacing * 1.5,
        },
      ],
      containerView: [
        screenStyle,
        createCardStyle.containerView,
        {
          padding: spacing * 1.5,
        },
      ],
      label: {
        paddingHorizontal: spacing,
      },
      title: {
        paddingVertical: spacing,
      },
    }),
    [screenStyle, spacing, white],
  )

  const buttonProps = useMemo(
    () => ({
      borderless: true,
      rippleColor: primary,
    }),
    [primary],
  )
  const iconProps = useMemo(
    () => ({
      size: 32,
      color: primary,
    }),
    [primary],
  )

  return {
    buttonProps,
    collection,
    iconProps,
    loading,
    navigate,
    s,
    showSnack,
    t,
    userDocRef,
    visible,
    setLoading,
    setVisible,
  }
}

export const createCardStyle = StyleSheet.create({
  button: {
    borderRadius: 8,
    ...generateShadow(4),
  },
  contentButton: {
    borderWidth: 0,
    borderRadius: 8,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  icon: {
    transform: [{ rotateY: "180deg" }],
  },
  containerView: {
    flex: 1,
  },
  view: {
    flex: 1,
  },
})
