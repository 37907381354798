import _ from "lodash"

const synth = window.speechSynthesis;

const getInitStatus = async () => new Promise((resolve, reject) => {
  resolve()
})

const requestInstallEngine = _.noop

const stop = () => {
  synth.pause()
  synth.cancel()
}

const utterance = new SpeechSynthesisUtterance();

const speak = (text) => {
  utterance.text = text;
  utterance.lang = "fr-FR";
  utterance.rate = 0.8;
  synth.speak(utterance);
}

const addEventListener = (_, handler) => {
  utterance.addEventListener("end", handler);
}

const removeListener = (_, handler) => {
  utterance.removeEventListener("end", handler);
}

export default {
  getInitStatus,
  requestInstallEngine,
  removeListener,
  stop,
  speak,
  addEventListener
}
