import { ImageSourcePropType } from "react-native"

const images: { [key: string]: ImageSourcePropType | ImageSourcePropType[] } = {
  welcome: [
    require("./welcome1.png"),
    require("./welcome2.png"),
    require("./welcome3.png"),
    require("./welcome4.png"),
  ],
}

export default images
