export interface UserData {
  theme: {
    columns: number
    columnsFreeSpace: number
    breadcrumbs: boolean
    font: Font // enum
  }
  favorites?: string[]
  lockCode?: string // if undefined, the app is not locked;
  // if defined and perso is paramLock, user is prompted when accessing the settings
  perso?: Perso // by default unlocked
  wpUsername?: string
  wpUsernameOverride?: string
  wpId?: string
  structure?: string
  structureName?: string
  email?: string
}

export enum Font {
  normal,
  dys,
  comic,
}

export enum Perso {
  unlocked, // params and edit possible
  editLock, // params possible, edit not possible
  paramLock, // params and edit not possible
}

export interface ShareData {
  code: string
  path: string
  exist?: boolean
}
