import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs"
import { useNavigation } from "@react-navigation/native"
import { ParamListBase } from "@react-navigation/routers"
import { StackNavigationProp } from "@react-navigation/stack"
import {
  ActionBar,
  generateShadow,
  IUserContext,
  MaterialIcons,
  userContext,
  useTheme,
} from "@siruplab/capsule"
import React, { useCallback, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import { ICardItemExt } from "../../components"
import { HeaderIcon } from "../../components/HeaderIcon"
import { ICON_SIZE } from "../../config/Constants"
import { UserData } from "../../features/models/UserData"
import { isEditable } from "../../features/models/UserDataFunctions"
import {
  favoritesRoutes,
  freeSpaceFavRoutes,
  tabsRoutes,
} from "../../features/Navigation/Constants"
import FavoritesScreen from "../FavoritesScreen/FavoritesScreen"
import GalleryScreen from "../GalleryScreen/GalleryScreen"
import { IFreeSpaceFavParamList } from "../MainScreen/MainTabs"
import { ns } from "./i18n/fr"

export interface ITabParamList extends ParamListBase {
  [favoritesRoutes.TAB_FREECOMMSPACE]: { freeSpace: true }
  [favoritesRoutes.TAB_FAVORITES]: undefined
}

const Tab = createMaterialTopTabNavigator<ITabParamList>()

const commonIconProps = {
  size: ICON_SIZE,
}

const FreeSpaceFavTabNavigatorScreen = () => {
  const {
    colors: {
      primary,
      surface: { header },
      white: { highEmphasis: white },
      black: { inactive },
    },
  } = useTheme()
  const { t } = useTranslation(ns)
  const { userData } = useContext<IUserContext<UserData>>(userContext)

  const s = useMemo(
    () => ({
      indicator: [
        styles.indicator,
        {
          borderColor: primary,
          backgroundColor: header,
        },
      ],
      mainTab: {
        backgroundColor: white,
      },
    }),
    [header, primary, white],
  )
  const { setOptions, navigate } = useNavigation<
    StackNavigationProp<IFreeSpaceFavParamList, "FreeSpaceFav_Main">
  >()

  const listeners = useCallback(
    (title: string, freeSpace = false) => ({
      focus: () => {
        setOptions({
          title,
          headerRight:
            !freeSpace || !isEditable(userData)
              ? undefined
              : () => (
                  <ActionBar
                    icons={[
                      {
                        id: 1,
                        icon: (
                          <HeaderIcon
                            iconName="swap-vertical"
                            label="sortCards"
                            onPress={() =>
                              navigate(freeSpaceFavRoutes.FREE_SPACE_FAV_SORT, { freeSpace: true })
                            }
                          />
                        ),
                      },
                      {
                        id: 2,
                        icon: (
                          <HeaderIcon
                            iconName="plus-box"
                            label="headers.createCard"
                            onPress={() =>
                              navigate(freeSpaceFavRoutes.FREE_SPACE_FAV_CREATE, {
                                collectionPath: "c",
                                freeSpace: true,
                              })
                            }
                          />
                        ),
                      },
                    ]}
                  />
                ),
        })
      },
    }),
    [navigate, setOptions, userData],
  )

  return (
    <Tab.Navigator
      style={s.mainTab}
      // I had to apply this weird style here because of an issue from react-native-tab-view: https://github.com/satya164/react-native-tab-view/issues/804#issuecomment-685018485
      tabBarOptions={{
        style: styles.tab,
        indicatorStyle: s.indicator,
        activeTintColor: primary,
        inactiveTintColor: inactive,
      }}
    >
      <Tab.Screen
        name={favoritesRoutes.TAB_FREECOMMSPACE}
        listeners={listeners(t("freeCommSpace"), true)}
        initialParams={{ freeSpace: true }}
        options={{
          title: t("freeCommSpace"),
          tabBarAccessibilityLabel: t("freeCommSpace"),
          tabBarLabel: ({ color }) => (
            <MaterialIcons name="chat" color={color} {...commonIconProps} />
          ),
        }}
      >
        {({ navigation }) => {
          const onCardPress = (item: ICardItemExt) => {
            navigation.navigate(tabsRoutes.FREESPACE_FAV, { singleCardId: item.id })
          }

          return <GalleryScreen collection="c" onCardPress={onCardPress} />
        }}
      </Tab.Screen>
      <Tab.Screen
        name={favoritesRoutes.TAB_FAVORITES}
        component={FavoritesScreen}
        listeners={listeners(t("fav"))}
        options={{
          title: t("fav"),
          tabBarAccessibilityLabel: t("fav"),
          tabBarLabel: ({ color }) => (
            <MaterialIcons name="star" color={color} {...commonIconProps} />
          ),
        }}
      />
    </Tab.Navigator>
  )
}

const styles = StyleSheet.create({
  tab: {
    ...generateShadow(1),
  },
  indicator: {
    height: "100%",
    borderBottomWidth: 1.5,
  },
})

export default FreeSpaceFavTabNavigatorScreen
