import { getFocusedRouteNameFromRoute, PathConfigMap } from "@react-navigation/core"
import { useNavigation, useRoute } from "@react-navigation/native"
import { ParamListBase } from "@react-navigation/routers"
import { createStackNavigator } from "@react-navigation/stack"
import {
  ActionBar,
  Delayed,
  EmptyView,
  IUserContext,
  ParametersScreen,
  Theme,
  userContext,
  useTheme,
} from "@siruplab/capsule"
import React, { FC, useCallback, useContext, useLayoutEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"

import { config, version } from "../../../package.json"
import { Level } from "../../common/types"
import { HeaderIcon } from "../../components/HeaderIcon"
import { UserData } from "../../features/models/UserData"
import { isAdminable } from "../../features/models/UserDataFunctions"
import {
  freeSpaceFavRoutes,
  galleryRoutes,
  homeRoutes,
  mainTabsRoutes,
  settingsRoutes,
  tabsRoutes,
} from "../../features/Navigation/Constants"
import PictureProvider from "../../features/Providers/PictureProvider"
import { IOptimisticContext, optimisticContext } from "../../utils/optimisticProvider"
import CreateCardMenu from "../CreateCardMenu/CreateCardMenu"
import CreateCardScreen from "../CreateCardScreen/CreateCardScreen"
import CreateNoteScreen from "../CreateNoteScreen/CreateNoteScreen"
import CreateNoteTabNavigatorScreen from "../CreateNoteTabNavigatorScreen/CreateNoteTabNavigatorScreen"
import FreeSpaceFavTabNavigatorScreen from "../FreeCommSpaceTabNavigator/FreeSpaceFavTabNavigatorScreen"
import GalleryScreen from "../GalleryScreen/GalleryScreen"
import HomeScreen from "../HomeScreen/HomeScreen"
import { ns as mainNs } from "../MainScreen/i18n/fr"
import SentenceScreen from "../SentenceScreen/SentenceScreen"
import { settingsItems as settingsItemsGen } from "../SettingsScreen/Constants"
import SortCardScreen from "../SortCardsScreen/SortCardScreen"
import { ns } from "./i18n/fr"

export interface IHomeParamList extends ParamListBase {
  [homeRoutes.HOME_MAIN]: { level?: Level; cardPath?: string }
  [homeRoutes.HOME_EDIT]: { edit: true; path?: string }
  [homeRoutes.HOME_SORT]: { path: string; freeSpace?: boolean }
}

export interface IGalleryParamList extends ParamListBase {
  [galleryRoutes.GALLERY_MAIN]: { title: string }
  [galleryRoutes.GALLERY_EDIT]: { gallery: true; edit: true; path?: string }
  [galleryRoutes.GALLERY_MENU]: { gallery: true; edit: false }
  [galleryRoutes.GALLERY_CREATE]: { gallery: true; edit: false }
}

export interface ICreateParamList extends ParamListBase {
  [mainTabsRoutes.CREATE_MAIN]: { title: string }
  [mainTabsRoutes.TAB_MAIN]: { gallery: boolean; edit: boolean }
  [mainTabsRoutes.CREATECARD_MAIN]: { edit: boolean; uri?: string; isCreate?: boolean }
}

export interface IFreeSpaceFavParamList extends ParamListBase {
  [freeSpaceFavRoutes.FREE_SPACE_FAV_MAIN]: { title: string; freeSpace: true }
  [tabsRoutes.FREESPACE_FAV]: {
    l3card: undefined
    isValidation: undefined
    singleCardId: string
    fromFav: true
  }
  [freeSpaceFavRoutes.FREE_SPACE_FAV_SORT]: { freeSpace: true }
  [freeSpaceFavRoutes.FREE_SPACE_FAV_CREATE]: { collectionPath: string; freeSpace: true }
  [freeSpaceFavRoutes.FREE_SPACE_FAV_GALLERY_EDIT]: { freeSpace: true; edit: true; path?: string }
}

export interface ISettingsParamList extends ParamListBase {
  [settingsRoutes.SETTINGS_MAIN]: { title: string }
}

const homeStack = createStackNavigator<IHomeParamList>()
const galleryStack = createStackNavigator<IGalleryParamList>()

const createStack = createStackNavigator<ICreateParamList>()
const freeSpaceFavStack = createStackNavigator<IFreeSpaceFavParamList>()
const settingsStack = createStackNavigator<ISettingsParamList>()

export const homeScreens: PathConfigMap = {
  [homeRoutes.HOME_MAIN]: "*",
}

type HeaderAlign = "center" | "left"

const commonTabOptions = ({
  fontMaker,
  colors: {
    white: { highEmphasis: white },
    primary,
  },
}: Theme) => ({
  headerTintColor: white,
  headerTitleAlign: "center" as HeaderAlign,
  headerTitleStyle: fontMaker({ weight: "Bold" }),
  headerStyle: {
    backgroundColor: primary,
  },
})

const screenOptions = {
  headerBackTitleVisible: false,
}

export const MainHomeTab: FC = () => {
  const { t } = useTranslation(ns)
  const theme = useTheme()

  return (
    <homeStack.Navigator screenOptions={screenOptions}>
      <homeStack.Screen
        name={homeRoutes.HOME_MAIN}
        component={HomeScreen}
        options={{
          ...commonTabOptions(theme),
          title: t("headers.home"),
          headerBackAccessibilityLabel: t("headers.back"),
        }}
      />
      <homeStack.Screen
        name={homeRoutes.HOME_EDIT}
        component={CreateCardScreen}
        initialParams={{ edit: true }}
        options={{
          ...commonTabOptions(theme),
          title: t("headers.editCard"),
          headerBackAccessibilityLabel: t("headers.back"),
        }}
      />
    </homeStack.Navigator>
  )
}

export const MainGalleryTab: FC = () => {
  const { t } = useTranslation(ns)
  const theme = useTheme()
  const { userData } = useContext<IUserContext<UserData>>(userContext)

  const onButtonPress = useCallback(
    navigation => () => navigation.navigate(galleryRoutes.GALLERY_MENU),
    [],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const headerRight = useCallback(
    navigation =>
      isAdminable(userData)
        ? () => (
            <ActionBar
              icons={[
                {
                  id: 2,
                  icon: (
                    <HeaderIcon
                      iconName="plus"
                      label={t("headers.createCard")}
                      onPress={onButtonPress(navigation)}
                    />
                  ),
                },
              ]}
            />
          )
        : undefined,
    [onButtonPress, t, userData],
  )

  return (
    <galleryStack.Navigator screenOptions={screenOptions}>
      <galleryStack.Screen
        name={galleryRoutes.GALLERY_MAIN}
        component={GalleryScreen}
        options={({ navigation }) => ({
          title: t("headers.gallery"),
          ...commonTabOptions(theme),
          headerRight: headerRight(navigation),
          headerBackAccessibilityLabel: t("headers.back"),
        })}
      />
      <galleryStack.Screen
        name={galleryRoutes.GALLERY_EDIT}
        component={CreateCardScreen}
        initialParams={{ gallery: true, edit: true }}
        options={{
          ...commonTabOptions(theme),
          title: t("headers.editCard"),
          headerBackAccessibilityLabel: t("headers.back"),
        }}
      />
      <galleryStack.Screen
        name={galleryRoutes.GALLERY_MENU}
        component={CreateCardMenu}
        initialParams={{ gallery: true, edit: false }}
        options={{
          ...commonTabOptions(theme),
          title: t("headers.createCard"),
          headerBackAccessibilityLabel: t("headers.back"),
        }}
      />
      <galleryStack.Screen
        name={galleryRoutes.GALLERY_CREATE}
        component={CreateCardScreen}
        initialParams={{ gallery: true, edit: false }}
        options={{
          ...commonTabOptions(theme),
          title: t("headers.createCard"),
          headerBackAccessibilityLabel: t("headers.back"),
        }}
      />
    </galleryStack.Navigator>
  )
}

export const MainCreateTab: FC = () => {
  const { t } = useTranslation(ns)
  const theme = useTheme()

  return (
    <PictureProvider>
      <createStack.Navigator screenOptions={screenOptions}>
        <createStack.Screen
          name={mainTabsRoutes.CREATE_MAIN}
          component={CreateNoteScreen}
          options={{
            ...commonTabOptions(theme),
            title: t(`${ns}:headers.create`),
            headerBackAccessibilityLabel: t("headers.back"),
          }}
        />
        <createStack.Screen
          name={mainTabsRoutes.TAB_MAIN}
          component={CreateNoteTabNavigatorScreen}
          options={{
            ...commonTabOptions(theme),
            title: t(`${ns}:headers.create`),
            headerBackAccessibilityLabel: t("headers.back"),
          }}
        />
        <createStack.Screen
          name={mainTabsRoutes.CREATECARD_MAIN}
          component={CreateCardScreen}
          initialParams={{ edit: false }}
          options={{
            ...commonTabOptions(theme),
            title: t(`${ns}:headers.createCard`),
            headerBackAccessibilityLabel: t("headers.back"),
          }}
        />
      </createStack.Navigator>
    </PictureProvider>
  )
}

export const MainFreeSpaceFavTab: FC = () => {
  const { t } = useTranslation(ns)
  const theme = useTheme()
  const navigation = useNavigation()
  const route = useRoute()

  useLayoutEffect(() => {
    const routeName = getFocusedRouteNameFromRoute(route)
    navigation.setOptions({ tabBarVisible: routeName !== tabsRoutes.FREESPACE_FAV })
  }, [navigation, route])

  return (
    <freeSpaceFavStack.Navigator screenOptions={screenOptions}>
      <freeSpaceFavStack.Screen
        name={freeSpaceFavRoutes.FREE_SPACE_FAV_MAIN}
        component={FreeSpaceFavTabNavigatorScreen}
        initialParams={{ freeSpace: true }}
        options={{
          ...commonTabOptions(theme),
          title: t("headers.favorites"),
          headerBackAccessibilityLabel: t("headers.back"),
        }}
      />
      <freeSpaceFavStack.Screen
        name={tabsRoutes.FREESPACE_FAV}
        options={{
          ...commonTabOptions(theme),
          headerBackAccessibilityLabel: t("headers.back"),
          title: "",
        }}
      >
        {({ route: stRoute }) => <SentenceScreen route={stRoute} />}
      </freeSpaceFavStack.Screen>
      <freeSpaceFavStack.Screen
        name={freeSpaceFavRoutes.FREE_SPACE_FAV_SORT}
        options={{
          ...commonTabOptions(theme),
          headerBackAccessibilityLabel: t("headers.back"),
          title: t("headers.sortCards"),
        }}
        component={SortCardScreen}
      />
      <freeSpaceFavStack.Screen
        name={freeSpaceFavRoutes.FREE_SPACE_FAV_CREATE}
        component={GalleryScreen}
        initialParams={{ freeSpace: true }}
        options={{
          ...commonTabOptions(theme),
          title: t("headers.createCard"),
          headerBackAccessibilityLabel: t("headers.back"),
        }}
      />
      <freeSpaceFavStack.Screen
        name={freeSpaceFavRoutes.FREE_SPACE_FAV_GALLERY_EDIT}
        component={CreateCardScreen}
        initialParams={{ freeSpace: true, edit: true }}
        options={{
          ...commonTabOptions(theme),
          title: t("headers.editCard"),
          headerBackAccessibilityLabel: t("headers.back"),
        }}
      />
    </freeSpaceFavStack.Navigator>
  )
}

export const MainSettingsTab: FC = () => {
  const { t } = useTranslation([ns, mainNs])
  const theme = useTheme()
  const { userData } = useContext<IUserContext<UserData>>(userContext)
  const { value } = useContext<IOptimisticContext<Partial<UserData>>>(optimisticContext)
  const isLocked = value ?? !isAdminable(userData)

  const settingsItems = useMemo(settingsItemsGen, [])

  return isLocked ? (
    <Delayed>
      <EmptyView title={t("locked", { ns: mainNs })} subtitle={undefined} />
    </Delayed>
  ) : (
    <settingsStack.Navigator>
      <settingsStack.Screen
        name={settingsRoutes.SETTINGS_MAIN}
        options={{
          ...commonTabOptions(theme),
          title: t("headers.settings"),
          headerBackAccessibilityLabel: t("headers.back"),
        }}
      >
        {() => (
          <ParametersScreen
            showSeparator={false}
            // @ts-ignore
            sections={settingsItems}
            namespace={tabsRoutes.SETTINGS}
            version={`${version}-${config.build ?? "0"}`}
            rowBackgroundColor={theme.colors.surface.appUi}
          />
        )}
      </settingsStack.Screen>
    </settingsStack.Navigator>
  )
}
