import { useNavigation } from "@react-navigation/native"
import { StackActions } from "@react-navigation/routers"
import {
  FontAwesome,
  functions,
  generateShadow,
  H6,
  logger,
  MaterialCommunityIcons,
  Touchable,
  useAlert,
  useCallbackConnected,
  userContext,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { useCallback, useContext, useMemo, useReducer, useState } from "react"
import { useTranslation } from "react-i18next"
import { ScrollView, StyleSheet, View } from "react-native"

import { OnShareParams } from "../../common/types"
import { ConfirmationModal, Mode, ShareAlertDialog } from "../../components"
import { ICON_MEDIUM_SIZE } from "../../config/Constants"
import { Card, FirCardWithId } from "../../features/models/Card"
import { FirCardToCard } from "../../features/models/CardFunctions"
import { mainRoutes } from "../../features/Navigation/Constants"
import { useTheme } from "../../utils/defaultStyle"
import { useAutoCallback } from "../../utils/useAutoCallback"
import { alertStateReducer, defaultValues } from "./Constants"
import { ns } from "./i18n/fr"

const AddCardsScreen = () => {
  const {
    colors: {
      primary,
      white: { highEmphasis: white },
    },
    dimensions: { spacing, borderRadius },
    screenStyle,
  } = useTheme()
  const { t } = useTranslation(ns)
  const navigation = useNavigation()
  const { showSnack } = useAlert()
  const { userDocRef } = useContext(userContext)
  const [alertState, dispatch] = useReducer(alertStateReducer, defaultValues)
  const [card, setCard] = useState<Card>()
  const s = useMemo(
    () => ({
      button: [
        styles.button,
        {
          borderRadius,
          marginBottom: spacing,
        },
      ],
      contentButton: [
        styles.contentButton,
        {
          borderRadius,
          backgroundColor: white,
          paddingVertical: spacing / 2,
          paddingHorizontal: spacing * 1.5,
        },
      ],
      containerView: [
        screenStyle,
        styles.containerView,
        {
          padding: spacing * 1.5,
        },
      ],
      label: {
        paddingHorizontal: spacing,
      },
    }),
    [screenStyle, spacing, white, borderRadius],
  )

  const buttonProps = useMemo(
    () => ({
      borderless: true,
      rippleColor: primary,
    }),
    [primary],
  )
  const iconProps = useMemo(
    () => ({
      size: ICON_MEDIUM_SIZE,
      color: primary,
    }),
    [primary],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAddCardPress = useCallback(
    _.debounce(
      () => {
        navigation.dispatch(
          StackActions.replace(mainRoutes.ADD_CARD, {
            collectionPath: userDocRef?.collection("h")?.path,
          }),
        )
      },
      300,
      { trailing: false, leading: true },
    ),
    [navigation, userDocRef],
  )

  const onAddCardsPress = useCallbackConnected(async () => {
    dispatch({ type: "code" })
  }, [dispatch])

  const onConfirm = useCallback(() => {
    dispatch({ type: "close" })
    navigation.goBack()
  }, [dispatch, navigation])

  // share code alert validate
  const { onPress: onConfirmPressed, start } = useAutoCallback(onConfirm)
  const onValidPress = useCallback(async () => {
    try {
      const shareParams: OnShareParams = {
        code: alertState.shareData?.code ?? "",
        dev: __DEV__,
      }
      dispatch({ type: "loading_code" })
      const firCard = (await functions().httpsCallable("getShare")(shareParams))
        .data as FirCardWithId
      setCard(FirCardToCard(firCard))
      dispatch({ type: "loading_share" })
      await functions().httpsCallable("shareHierarchy")(shareParams)
      dispatch({ type: "completed" })
      await start()
    } catch (e) {
      logger("check share code error", e)
      if (e.message === "code_invalid") {
        dispatch({ type: "error", error: t(e.message) })
      } else {
        dispatch({ type: "error", error: undefined })
        showSnack({ message: t(e.message) })
      }
    }
  }, [alertState, start, t, showSnack])

  // share code alert cancel
  const onCancelPress = useCallback(() => {
    dispatch({ type: "close" })
  }, [dispatch])

  return (
    <>
      <ScrollView style={styles.view} contentContainerStyle={s.containerView}>
        <Touchable {...buttonProps} style={s.button} onPress={onAddCardPress}>
          <View style={s.contentButton}>
            <MaterialCommunityIcons {...iconProps} name="plus-box" />
            <H6 style={s.label}>{t("add.card")}</H6>
          </View>
        </Touchable>
        <Touchable {...buttonProps} style={styles.button} onPress={onAddCardsPress}>
          <View style={s.contentButton}>
            <View style={styles.icon}>
              <FontAwesome {...iconProps} name="share-square-o" />
            </View>
            <H6 style={s.label}>{t("add.process")}</H6>
          </View>
        </Touchable>
      </ScrollView>
      <ShareAlertDialog {...{ onCancelPress, onValidPress, alertState, dispatch }} />
      <ConfirmationModal
        visible={alertState.confirmVisible}
        dialogProps={{
          card,
          mode: Mode.share,
          onClear: onConfirm,
          loading: alertState.loading,
          onConfirm: onConfirmPressed,
        }}
      />
    </>
  )
}

const styles = StyleSheet.create({
  button: {
    ...generateShadow(4),
  },
  contentButton: {
    borderWidth: 0,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  icon: {
    transform: [{ rotateY: "180deg" }],
  },
  containerView: {
    flex: 1,
  },
  view: {
    flex: 1,
  },
})

export default AddCardsScreen
