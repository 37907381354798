import React, { FC, useCallback, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { ScrollView, StyleSheet, TextInput, View } from "react-native"

import { maxWidthCardEditor } from "../../features/config/useTabland"
import { useTheme } from "../../utils/defaultStyle"
import { ns } from "./i18n/fr"

const TextAreaScreen: FC = () => {
  const {
    typography: { h5 },
    dimensions: { spacing, borderRadius },
    colors: {
      black: { shadow, inactive },
    },
    screenStyle,
  } = useTheme()
  const { t } = useTranslation(ns)
  const edit = useRef<TextInput>(null)

  const s = useMemo(
    () => ({
      text: [h5, styles.textArea],
      view: {
        borderRadius,
        margin: spacing,
        padding: spacing,
        backgroundColor: shadow,
      },

      screenStyle: [
        screenStyle,
        {
          maxWidth: maxWidthCardEditor,
        },
      ],
    }),
    [h5, screenStyle, shadow, spacing, borderRadius],
  )

  const onKeyPress = useCallback(
    ({ nativeEvent: { key: keyValue } }) => {
      if (keyValue === "Enter") {
        edit?.current?.blur()
      }
    },
    [edit],
  )

  return (
    <ScrollView
      scrollEnabled={false}
      keyboardShouldPersistTaps="handled"
      contentContainerStyle={s.screenStyle}
    >
      <View style={s.view}>
        <TextInput
          ref={edit}
          style={s.text}
          multiline={true}
          numberOfLines={10}
          returnKeyType="done"
          textAlignVertical="top"
          onKeyPress={onKeyPress}
          placeholderTextColor={inactive}
          placeholder={t("writeHere")}
          underlineColorAndroid="transparent"
        />
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  textArea: {
    width: "100%",
    aspectRatio: 1,
    justifyContent: "center",
  },
})

export default TextAreaScreen
