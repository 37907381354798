import { generateShadow, hitSlop } from "@siruplab/capsule"
import React, { FC, useMemo } from "react"
import { Platform, ViewStyle } from "react-native"
import { IconButton } from "react-native-paper"
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons"

import { iconSize } from "../config/Constants"
import { useTheme } from "../utils/defaultStyle"

interface IProps {
  isCardValidated: boolean | undefined
  onPress: any
  style?: ViewStyle
}

export const ValidateCardButton: FC<IProps> = ({ isCardValidated, onPress, style }) => {
  const {
    dimensions: { spacing, borderRadius },
    colors: { accent2, accent3, primary, secondary },
  } = useTheme()

  const s = useMemo(
    () => ({
      validateIconProps: {
        size: iconSize.regular,
        color: primary,
      },
      validateIconButton: {
        backgroundColor: accent2,
        flex: 1,
        minHeight: 72,
        width: undefined,
        borderRadius,
        ...(Platform.OS === "web" ? generateShadow(2) : {}),
      },
      hitSlop: hitSlop(spacing / 2),
    }),
    [spacing, primary, accent2, borderRadius],
  )

  return (
    <IconButton
      style={[s.validateIconButton, isCardValidated ? { backgroundColor: accent3 } : null, style]}
      onPress={onPress}
      hitSlop={s.hitSlop}
      icon={() => (
        <MaterialCommunityIcons
          {...s.validateIconProps}
          color={isCardValidated ? secondary : primary}
          name={isCardValidated ? "check-circle" : "circle-outline"}
          //    accessibilityLabel={t("cardActions.validate")} TO-DO : accessibility
        />
      )}
    />
  )
}
