import { useWindowDimensions } from "react-native"

export const webCardWidth = 200
export const webCardWidthTwoCol = 220
export const maxWidth = 800
export const maxWidthCard = 250
export const maxWidthCardEditor = 480
export const maxWidthModal = 380
export const tabSizeLand = 80

export const widthBottomCompThree = 110
export const widthBottomComp = 70

const useTabLand = () => {
  const { width, height } = useWindowDimensions()

  return width > height && width > 480
}

export default useTabLand
