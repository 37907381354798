export enum LicenseStatus {
  Licensed = "licensed",
  Trial = "trial",
  Expired = "expired",
}

export enum LicenseType {
  b2b = "Professionnel",
  b2c = "Aidant",
  bene = "Bénéficiaire",
}

export interface Auth {
  license: LicenseStatus
  category: LicenseType
  endDate: string
  password: string
  wpUsername: string
  structure: string
  enableQrCode: boolean
}
