import { useNavigation } from "@react-navigation/native"
import {
  Button,
  generateShadow,
  MaterialCommunityIcons,
  MaterialIcons,
  useTheme,
} from "@siruplab/capsule"
import React, { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FlatList, ListRenderItem, StyleSheet, View } from "react-native"
import { IconSource } from "react-native-paper/lib/typescript/components/Icon"

import { ICON_MEDIUM_SIZE } from "../../config/Constants"
import { mainTabsRoutes, paramsTabsRoutes } from "../../features/Navigation/Constants"
import useUserData from "../../utils/useUserData"
import { ns } from "./i18n/fr"

interface IData {
  title: string
  screen: string
  icon: IconSource
}

const keyExtractor = (item: IData) => item.title

const CreateNoteScreen: FC = () => {
  const {
    dimensions: { spacing },
    colors: {
      primary,
      white: { highEmphasis: white },
    },
    typography: { h6 },
    screenStyle,
  } = useTheme()
  const { t } = useTranslation(ns)
  const navigation = useNavigation()
  const { user } = useUserData()

  const s = useMemo(
    () => ({
      contentContainer: [
        screenStyle,
        {
          padding: spacing * 1.5,
        },
      ],
      view: { paddingBottom: spacing },
      buttonText: [h6, styles.buttonText],
      button: [{ backgroundColor: white }, styles.button],
      buttonContent: [styles.buttonContent, { padding: spacing * 0.5 }],
    }),
    [h6, screenStyle, spacing, white],
  )

  const renderItem: ListRenderItem<IData> = useCallback(
    ({ item: { title, screen, icon } }) => {
      const onPress = () => navigation.navigate(mainTabsRoutes.TAB_MAIN, { screen })
      const onLongPress =
        user?.uid === "apple-et-google-tests"
          ? () => {
              throw new Error("Intentional crash")
            }
          : undefined

      return (
        <View style={s.view}>
          <Button
            icon={icon}
            style={s.button}
            uppercase={false}
            onPress={onPress}
            onLongPress={onLongPress}
            labelStyle={s.buttonText}
            contentStyle={s.buttonContent}
          >
            {title}
          </Button>
        </View>
      )
    },
    [navigation, s.button, s.buttonContent, s.buttonText, s.view, user?.uid],
  )

  const data: IData[] = [
    {
      screen: paramsTabsRoutes.TAB_WRITE,
      title: t("write"),
      icon: () => (
        <MaterialCommunityIcons name="format-letter-case" color={primary} size={ICON_MEDIUM_SIZE} />
      ),
    },
    {
      screen: paramsTabsRoutes.TAB_DRAW,
      title: t("draw"),
      icon: () => <MaterialIcons name="brush" color={primary} size={ICON_MEDIUM_SIZE} />,
    },
    {
      screen: paramsTabsRoutes.TAB_TAKEPIC,
      title: t("takePic"),
      icon: () => <MaterialIcons name="photo-camera" color={primary} size={ICON_MEDIUM_SIZE} />,
    },
  ]

  return (
    <FlatList
      scrollEnabled={false}
      contentContainerStyle={s.contentContainer}
      {...{ data, renderItem, keyExtractor }}
    />
  )
}

const styles = StyleSheet.create({
  button: {
    borderWidth: 0,
    justifyContent: "center",
    ...generateShadow(4),
  },
  buttonText: {
    textTransform: "none",
    lineHeight: 50,
    height: "100%",
  },
  buttonContent: {
    height: 64,
    justifyContent: "flex-start",
  },
})

export default CreateNoteScreen
