import { ShareData } from "../features/models/UserData"

export interface DevParams {
  dev?: boolean
}

export interface OnAuthenticationParams extends DevParams {
  email: string
  password: string
}

export const Collections = {
  /** the main collection for users and their data */
  LOGIN: "login",
  /** this collection was used to check plaintext passwords (onAuthenticateUser, obsolete)
   * before the WP-based auth (onAuthenticateUser1), which uses it to  */
  AUTH: "auth",
  /** list of administrable links in the application settings */
  LINKS: "urls",
  /** an entry is created in this collection when a user shares a level-1 card
   * and can be used to import the card and sub-cards into another user's hierarchy */
  SHARE: "share",
}

export enum Level {
  first = 0.06,
  second = 0.3,
  third = 0.66,
}

export interface OnCreateShareParams extends DevParams {
  data: ShareData
}

export interface OnCheckExistingShareParams extends DevParams {
  path: string
}

export interface OnShareParams extends DevParams {
  code: string
}

export interface Claims {
  structure?: string
  structureName?: string
  admin: boolean
}

export interface LoginAsParams {
  userId: string
  forcePerso: boolean | undefined
}
