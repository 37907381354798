import {
  Body2,
  ErrorScreen,
  H6,
  ICardItem,
  ICustomCardOptions,
  IDeprecatedGridScreen,
  PresentationListView,
  useGridCalculation,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { FC, ReactNode, useCallback, useMemo } from "react"
import { AspectRatio } from "react-aspect-ratio"
import {
  FlatList,
  ImageSourcePropType,
  ListRenderItemInfo,
  Platform,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native"
import FastImage, { ImageStyle } from "react-native-fast-image"
import { Card } from "react-native-paper"

interface IProps {
  webCardWidthTwoCol?: number
  webCardWidth?: number
  maxCardWidth?: number
  fromWeb?: boolean
  ratio?: string
  loading: boolean
  namespace?: string
  data: ICardItem[] | null
  error?: Error | undefined
  errorImage?: ImageSourcePropType
  cardStyle?: StyleProp<ViewStyle>
  listContentStyle?: StyleProp<ViewStyle>
  imageStyle?: StyleProp<ImageStyle>
  cardContentStyle?: StyleProp<ViewStyle>
  onCardPress?: (item: ICardItem) => void
  cardBottomRender?: (item: ICardItem) => ReactNode
  imageInnerRender?: (item: ICardItem) => ReactNode
  color?: "primary" | "secondary" | "default" | undefined
  info?: string
  options?: Exclude<ICustomCardOptions, IDeprecatedGridScreen>
  csGutter?: number
  cardWidth?: number
  numColumns?: number
}

const keyExtractor = (item: any) => `${item.id}`

const emptyItem: ICardItem = {
  id: "",
  title: "",
  subtitle: "",
}
// We duplicate GridScreen from capsule to apply some specific changes for web
export const GridScreen: FC<IProps> = ({
  options: customOptions,
  csGutter: deprecatedCustomGutter,
  cardWidth: deprecatedCardWidth = "100%",
  numColumns: deprecatedColumns,
  data,
  color,
  error,
  loading,
  namespace,
  errorImage,
  onCardPress,
  ratio = "16/9",
  cardBottomRender,
  imageInnerRender,
  cardContentStyle,
  listContentStyle,
  cardStyle,
  imageStyle,
  fromWeb,
  webCardWidthTwoCol,
  webCardWidth,
  maxCardWidth,
}) => {
  const {
    colors,
    dimensions: { spacing },
  } = useTheme()

  const options = customOptions || {
    gutter: deprecatedCustomGutter,
    columns: deprecatedColumns,
    windowWidth: undefined,
    type: "deprecatedGridScreen" as const,
  }

  const { margin, gutter, columns, cardWidth, cardHeight } = useGridCalculation(options)

  const addPlaceHolderIn = (cards: ICardItem[]) => {
    const emptyItems = _.times(columns - (cards.length % columns), () => emptyItem)
    return _.concat(cards, emptyItems)
  }

  const s = useMemo(
    () => ({
      container: [
        styles.container,
        {
          backgroundColor: colors.surface.appUi,
        },
      ],
      cardContent: [
        {
          width: customOptions ? "100%" : deprecatedCardWidth,
          paddingTop: spacing,
        },
        cardContentStyle,
      ],
      card: [
        cardStyle,
        styles.container,
        customOptions
          ? {
              width: cardWidth,
              height: cardHeight,
            }
          : {},
        {
          marginVertical: gutter / 2,
          marginHorizontal: gutter / 2,
        },
      ],
      emptyCard: [
        styles.container,
        {
          width: fromWeb ? (columns === 2 ? webCardWidthTwoCol : webCardWidth) : maxCardWidth,
          elevation: 0,
          borderRadius: 0,
          marginVertical: gutter / 2,
          marginHorizontal: gutter / 2,
          backgroundColor: colors.black.transparent,
        },
      ],
      flatList: [
        styles.container,
        {
          padding: !customOptions ? margin / 2 : margin, // respecting previous behaviour, using margin/2
        },
      ],
      flatListContent: [
        {
          paddingBottom: margin * 2,
        },
        listContentStyle,
      ],
    }),
    [
      colors.surface.appUi,
      colors.black.transparent,
      customOptions,
      deprecatedCardWidth,
      spacing,
      cardContentStyle,
      cardStyle,
      cardWidth,
      cardHeight,
      gutter,
      fromWeb,
      columns,
      webCardWidthTwoCol,
      webCardWidth,
      maxCardWidth,
      margin,
      listContentStyle,
    ],
  )

  const onPress = useCallback(
    (item: ICardItem) => () => {
      onCardPress?.(item)
    },
    [onCardPress],
  )

  const renderContent = useCallback(
    ({ item }: ListRenderItemInfo<ICardItem>) => {
      const image = (
        <FastImage style={[styles.image, imageStyle, item.imageStyle]} source={{ uri: item.image }}>
          {imageInnerRender?.(item)}
        </FastImage>
      )

      return item === emptyItem ? (
        <Card style={s.emptyCard}>
          <Card.Content style={s.cardContent}>
            <View style={styles.emptyView} />
          </Card.Content>
        </Card>
      ) : (
        <Card style={s.card} onPress={onPress(item)}>
          {Platform.OS === "web" ? <AspectRatio ratio={ratio}>{image}</AspectRatio> : image}
          <Card.Content style={s.cardContent}>
            {item.title ? (
              <View style={item.titleViewStyle}>
                <H6 color={color} numberOfLines={2} style={item.titleStyle}>
                  {item.title}
                </H6>
              </View>
            ) : null}
            {item.subtitle ? (
              <View style={item.subtitleViewStyle}>
                <Body2 color={color} numberOfLines={2} style={item.subtitleStyle}>
                  {item.subtitle}
                </Body2>
              </View>
            ) : null}
            {cardBottomRender?.(item)}
          </Card.Content>
        </Card>
      )
    },
    [imageStyle, imageInnerRender, s, onPress, ratio, color, cardBottomRender],
  )

  return (
    <PresentationListView
      error={error}
      loading={loading}
      childError={
        <ErrorScreen
          color={color}
          image={errorImage}
          namespace={namespace}
          title={error?.name ?? undefined}
          subtitle={error?.message ?? undefined}
        />
      }
      isEmpty={_.isEmpty(data)}
      indicator={{ size: "large", color: colors.primary }}
    >
      <FlatList
        key={columns}
        style={s.flatList}
        numColumns={columns}
        renderItem={renderContent}
        keyExtractor={keyExtractor}
        data={data && addPlaceHolderIn(data)}
        contentContainerStyle={s.flatListContent}
      />
    </PresentationListView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  image: {
    width: "100%",
    aspectRatio: 16 / 9,
  },
  emptyView: {
    flex: 1,
    overflow: "hidden",
  },
})
