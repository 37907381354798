export const ns = "CreateCard"

export default {
  [ns]: {
    placeholder: "Nom",
    error: "Connexion internet instable. Réessayez plus tard.",
    chooseImage: "Choisir une image",
  },
  common: {
    alert: {
      network: "Pas de connexion internet",
    },
  },
}
