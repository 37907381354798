import { tabsRoutes } from "../../../features/Navigation/Constants"

export const ns = tabsRoutes.FAVORITES

export default {
  [ns]: {
    open: "Ouvrir le favori",
    delete: "Supprimer le favori",
    empty: "Vous n'avez pas de favori",
    addFavorite: "Ajoutez-les depuis l'affichage d'une phrase",
  },
}
