import { useEffect, useState } from "react"

const useAutoResetState = <T>(initialState: T, delay = 1000) => {
  const [state, setState] = useState<T>(initialState)

  // AUTO RESET
  useEffect(() => {
    // no need to reset if the state is already the default
    if (state !== initialState) {
      return
    }

    // reset the state after delay
    const resetTimeOut = setTimeout(() => {
      setState(initialState)
    }, delay)
    return () => clearTimeout(resetTimeOut)
  }, [state, delay, initialState])

  return [state, setState] as const
}

export default useAutoResetState
