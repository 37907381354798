import { hitSlop } from "@siruplab/capsule"
import React, { FC, useMemo } from "react"
import { IconButton } from "react-native-paper"

import { ICON_SIZE } from "../config/Constants"
import { useTheme } from "../utils/defaultStyle"
import isWeb from "../utils/isWeb"

interface HeaderIconProps {
  onPress?: () => void
  iconName: string
  label: string
}

export const HeaderIcon: FC<HeaderIconProps> = ({ onPress, iconName, label }) => {
  const {
    dimensions: { spacing, iconSpacing },
    colors: {
      white: { highEmphasis: white },
    },
  } = useTheme()

  const s = useMemo(
    () => ({
      icon: {
        padding: isWeb ? iconSpacing : 0,
      },
    }),
    [iconSpacing],
  )
  return (
    <IconButton
      icon={iconName}
      color={white}
      style={s.icon}
      size={ICON_SIZE}
      onPress={onPress}
      accessibilityLabel={label}
      hitSlop={hitSlop(spacing)}
    />
  )
}
