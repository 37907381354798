import {
  functions,
  logger,
  openUrl,
  SignInScreen,
  ThemeProvider,
  userContext,
  useTheme,
} from "@siruplab/capsule"
import React, { FC, useCallback, useContext } from "react"
import { StatusBar } from "react-native"

import { OnAuthenticationParams } from "../../common/types"
import { ICON_MEDIUM_SIZE, urls } from "../../config/Constants"
import crashlytics from "../../services/Analytics/crashlytics"

const LoginScreen: FC = () => {
  const {
    colors: {
      primary,
      white: { highEmphasis },
    },
  } = useTheme()
  const { customLogin } = useContext(userContext)
  const authenticate = useCallback(async (email: string, password: string): Promise<string> => {
    logger("authenticate")
    const authenticateParams: OnAuthenticationParams = {
      email,
      password,
      dev: __DEV__,
    }
    try {
      return (await functions().httpsCallable("authenticateUser1")(authenticateParams))
        .data as string
    } catch (e) {
      crashlytics.reportError(crashlytics.errorCodes.authentication, e.message, e)
      throw e
    }
  }, [])

  const onSubmit = useCallback(
    async (email: string, password: string) => {
      await customLogin(authenticate, email, password)
    },
    [authenticate, customLogin],
  )

  const onResetPassword = () => {
    openUrl(urls.ADMIN)
  }

  return (
    <ThemeProvider
      customTheme={{
        colors: { overrides: { button: highEmphasis } },
      }}
    >
      <StatusBar barStyle="light-content" animated />
      <SignInScreen
        shadow={4}
        onLogin={onSubmit}
        showHtmlView={true}
        onResetPassword={onResetPassword}
        icon={{
          size: ICON_MEDIUM_SIZE,
          color: primary,
          name: "check-circle",
          category: "MaterialCommunityIcons",
        }}
      />
    </ThemeProvider>
  )
}

export default LoginScreen
