// gets the current screen from navigation state
import { NavigationState } from "@react-navigation/native"
import { analytics, logger } from "@siruplab/capsule"
import _ from "lodash"

import crashlytics from "./crashlytics"

const getActiveRouteName = (
  state: Partial<NavigationState> | undefined,
): Partial<NavigationState> | undefined | string => {
  if (!state || state.index === undefined) {
    return undefined
  }
  const route = state?.routes?.[state?.index]
  if (route?.state) {
    // Dive into nested navigators
    // @ts-ignore
    return getActiveRouteName(route?.state)
  }

  return route?.name
}

const logScreenTracking = _.debounce(currentScreen => {
  logger("screen", currentScreen)

  const event = `s_${currentScreen
    ?.replace(/\/route\//, "/")
    .replace(/Screen\//, "/")
    .replace(/\//g, "_")
    .substring(0, 32)}`

  // TODO: at the moment, Android and iOS builds use different ways of logging screens
  try {
    // @ts-ignore
    analytics().setCurrentScreen(currentScreen)
  } catch (e) {
    // @ts-ignore
    analytics().logScreenView({ screen_name: currentScreen })
  }
  analytics().logEvent(event, {})
  crashlytics.bugsnag.leaveBreadcrumb(event)
}, 100)

// explicit do not use it for implicit tracking forbidden !
// @ts-ignore
export const logEvent: Promise<void> = (
  action = "",
  subject = "",
  params: { [key: string]: string | number | boolean },
) =>
  // @ts-ignore
  analytics.logEvent(`e_${action}_${subject}`, params)

export const setProperty = (name: string, value: string | null): Promise<void> | undefined => {
  try {
    return analytics().setUserProperty(name, String(value))
  } catch (e) {
    logger(e)
  }
  return undefined
}

const actions = {
  open: "open",
  view: "view",
  start: "start",
  end: "end",
  send: "send",
  fail: "fail",
}

const subjects = {
  notif: "notif",
  login: "login",
  reveal: "reveal",
}

export default { logScreenTracking, getActiveRouteName, actions, subjects }
