import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import { RouteProp } from "@react-navigation/core"
import { useNavigation, useRoute } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import {
  ActionBar,
  delay,
  firestore,
  IUserContext,
  logger,
  useAlert,
  useCallbackConnected,
  userContext,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import { Searchbar } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"

import { Level } from "../../common/types"
import {
  BottomDoubleButtonsMaterial,
  CardButton,
  CardsView,
  ConfirmationModal,
  ICardItemExt,
  Mode,
} from "../../components"
import { HeaderIcon } from "../../components/HeaderIcon"
import { Card, FirCard } from "../../features/models/Card"
import { FirCardToCard } from "../../features/models/CardFunctions"
import { UserData } from "../../features/models/UserData"
import {
  getOrderedCardsQuery,
  getUserCollectionMinOrder,
  getUserGalleryCard,
  isAdminable,
} from "../../features/models/UserDataFunctions"
import {
  freeSpaceFavRoutes,
  galleryRoutes,
  homeRoutes,
  mainRoutes,
  tabsRoutes,
} from "../../features/Navigation/Constants"
import { IRootParamList } from "../../features/Navigation/RootNavigator"
import { useTheme } from "../../utils/defaultStyle"
import isWeb from "../../utils/isWeb"
import { IFreeSpaceFavParamList, IHomeParamList } from "../MainScreen/MainTabs"
import { ns } from "./i18n/fr"

type HomeScreenNavigationProp = StackNavigationProp<IHomeParamList, typeof homeRoutes.HOME_MAIN>
type HomeScreenScreenRouteProp = RouteProp<IHomeParamList, typeof homeRoutes.HOME_MAIN>
type AddCardScreenRouteProp = RouteProp<IRootParamList, typeof mainRoutes.ADD_CARD>
type FreeSpaceFavScreenRoureProp = RouteProp<
  IFreeSpaceFavParamList,
  typeof freeSpaceFavRoutes.FREE_SPACE_FAV_MAIN
>

const isAddRoute = (
  route: HomeScreenScreenRouteProp | AddCardScreenRouteProp | FreeSpaceFavScreenRoureProp,
): route is AddCardScreenRouteProp =>
  !_.isUndefined((route as AddCardScreenRouteProp)?.params?.collectionPath)

const isFreeSpaceRoute = (
  route: HomeScreenScreenRouteProp | AddCardScreenRouteProp | FreeSpaceFavScreenRoureProp,
): route is FreeSpaceFavScreenRoureProp =>
  (route as FreeSpaceFavScreenRoureProp)?.params?.freeSpace === true

interface Props {
  collection?: string
  route?: HomeScreenScreenRouteProp | AddCardScreenRouteProp
  onCardPress?: (...args: any) => void
}

const GalleryScreen = ({ collection = "g", onCardPress }: Props) => {
  const {
    colors: {
      white: { highEmphasis: white },
      black: { transparent },
      surface: { input },
    },
    dimensions: { spacing, buttonSize },
  } = useTheme()
  const { showSnack } = useAlert()
  const { t } = useTranslation(ns)
  const route = useRoute<HomeScreenScreenRouteProp | AddCardScreenRouteProp>()

  const navigation = useNavigation<HomeScreenNavigationProp>()
  const { userDocRef, userData } = useContext<IUserContext<UserData>>(userContext)
  const [filter, setFilter] = useState("")
  const [checkedCards, setCheckedCards] = useState<Record<string, boolean>>()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [placeHolderCard, setPlaceHolderCard] = useState<Card & { path: string }>()
  const collectionPath =
    collection === "c"
      ? "c "
      : isFreeSpaceRoute(route)
      ? "c"
      : isAddRoute(route)
      ? `h${_.replace(route.params?.collectionPath ?? "", `${userDocRef?.path}/h`, "")}`
      : undefined

  // navigation
  useEffect(() => {
    if (navigation && !isAddRoute(route) && route.params?.cardPath) {
      navigation.setOptions({
        title: "",
      })
    }
    if (isAddRoute(route) && isAdminable(userData)) {
      navigation.setOptions({
        headerRight: props => (
          <ActionBar
            icons={[
              {
                id: 1,
                icon: (
                  <HeaderIcon
                    iconName="plus"
                    label={t("create")}
                    onPress={() => navigation.navigate(tabsRoutes.CREATE)}
                  />
                ),
              },
            ]}
            {...props}
          />
        ),
      })
    }
  }, [navigation, route, userData, t])

  const collectionRef = useMemo(
    (): FirebaseFirestoreTypes.CollectionReference | undefined =>
      userDocRef ? userDocRef.collection(collection) : undefined,
    [collection, userDocRef],
  )

  const s = useMemo(
    () => ({
      button: [
        styles.button,
        {
          height: buttonSize,
          backgroundColor: white,
        },
      ],
      modal: [
        styles.modal,
        {
          marginTop: spacing * 3.5,
        },
      ],
      bottom: {
        marginHorizontal: spacing,
      },
      iconStyle: {
        marginRight: spacing,
      },
      searchBar: {
        backgroundColor: input,
        shadowColor: transparent,
        marginHorizontal: spacing,
        marginTop: spacing,
        marginBottom: spacing / 2,
        paddingLeft: spacing / 2,
      },
    }),
    [input, spacing, transparent, white, buttonSize],
  )

  // methods to delete a card
  const onCancelPress = useCallback(() => {
    setVisible(false)
    setPlaceHolderCard(undefined)
  }, [])

  const onDeleteConfirm = useCallback(() => {
    firestore()
      .doc(placeHolderCard?.path ?? "")
      .delete()
    setVisible(false)
  }, [placeHolderCard])

  const onDeleteButtonPress = useCallback(async (card: ICardItemExt) => {
    setVisible(true)
    const c = (await card.ref?.get())?.data() as FirCard
    setPlaceHolderCard({ ...FirCardToCard(c, card.id), path: card.ref?.path as string })
  }, [])

  // methods to add cards
  const onCheckCard = useCallback(
    (item: ICardItemExt) => {
      setCheckedCards({ ...checkedCards, [item?.id]: !checkedCards?.[item?.id] })
    },
    [checkedCards],
  )

  const onClear = () => {
    navigation.goBack()
  }

  const onSave = useCallbackConnected(async () => {
    if (!collectionPath) {
      logger("Undefined collectionPath")
      return
    }

    try {
      setLoading(true)
      const cardIds = _(checkedCards)
        .pickBy(val => val)
        .map((val, id) => id)
        .value()
      const galleryCards = await Promise.all(
        _.map(cardIds, async cardId => getUserGalleryCard(cardId, userDocRef, collection)),
      )
      setPlaceHolderCard({ ...FirCardToCard(galleryCards?.[0], ""), path: "" })
      let order = (await getUserCollectionMinOrder(collectionPath, userDocRef)) - 1

      const batch = firestore().batch()
      _.forEach(galleryCards.reverse(), card => {
        const docRef = userDocRef?.collection(collectionPath).doc()
        if (docRef) {
          batch.set(docRef, { ...card, o: order-- })
        }
      })
      await batch.commit()
    } catch (e) {
      logger("clone gallery cards error", e)
      showSnack({ message: t(`error.add_gallery`) })
    } finally {
      setLoading(false)
      await delay(200)
      navigation.goBack()
    }
  }, [checkedCards, navigation, collectionPath, showSnack, t, userDocRef])

  const onCardButtonPress = useCallback(
    (card: ICardItemExt, button: CardButton) => {
      const editRoute =
        route?.params && "freeSpace" in route.params
          ? freeSpaceFavRoutes.FREE_SPACE_FAV_GALLERY_EDIT
          : galleryRoutes.GALLERY_EDIT

      switch (button) {
        case "edit":
          return navigation.navigate(editRoute, { path: card.ref?.path })
        case "delete":
          return onDeleteButtonPress(card)
      }
    },
    [navigation, onDeleteButtonPress, route.params],
  )

  const onChangeText = useCallback((text: string) => setFilter(text), [])

  const ButtonViewComponent = useMemo(() => (isFreeSpaceRoute(route) ? View : SafeAreaView), [
    route,
  ])
  return (
    <View style={isWeb ? { maxHeight: "calc(100vh - 64px)" } : { height: "100%" }}>
      <Searchbar
        {...{ onChangeText }}
        value={filter}
        style={s.searchBar}
        placeholder={t("placeholder")}
        icon={undefined}
        theme={{ roundness: 8 }}
      />
      <CardsView
        currentLevel={Level.first}
        isFreeSpace={isFreeSpaceRoute(route)}
        isGallery
        isAddRoute={isAddRoute(route)}
        filter={_.lowerCase(_.deburr(filter))}
        collectionQuery={getOrderedCardsQuery(collectionRef)}
        {...{ onCheckCard, checkedCards, onCardButtonPress, onCardPress }}
      />
      {isAddRoute(route) ? (
        <ButtonViewComponent edges={["bottom"]}>
          <BottomDoubleButtonsMaterial
            clearIcon="close"
            saveText={t("common:button.validate")}
            clearText={t("common:button.cancel")}
            saveIcon="plus-circle-outline"
            disabled={!_.some(checkedCards, val => val) || loading}
            loading={loading}
            bottomViewStyle={s.bottom}
            {...{ onClear, onSave }}
          />
        </ButtonViewComponent>
      ) : null}
      {!isAddRoute(route) ? (
        <ConfirmationModal
          {...{ visible }}
          dialogProps={{
            loading,
            title: t("delete"),
            onConfirm: onDeleteConfirm,
            mode: Mode.delete,
            card: placeHolderCard,
            onClear: onCancelPress,
          }}
        />
      ) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    justifyContent: "center",
  },
  modal: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  modalSubtitle: {
    textAlign: "center",
  },
})

export default GalleryScreen
