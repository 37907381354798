import { IUserContext, userContext } from "@siruplab/capsule"
import { ReactChild, useContext } from "react"
import { useTranslation } from "react-i18next"

import { UserData } from "../../features/models/UserData"
import { getUsernameOrOverride } from "../../features/models/UserDataFunctions"

const AccountItem = (): ReactChild => {
  const { userData } = useContext<IUserContext<UserData>>(userContext)
  const { t } = useTranslation()

  return `${t("parameters.account")} (${getUsernameOrOverride(userData)})`
}

export default AccountItem
