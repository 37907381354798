import { colors, VectorIconProps } from "@siruplab/capsule"

import { ICON_SIZE } from "../../config/Constants"

export const rightChild: Partial<VectorIconProps> = {
  size: ICON_SIZE,
  name: "chevron-right",
  color: colors.black.mediumEmphasis,
  category: "MaterialCommunityIcons",
}

export const leftChild: Partial<VectorIconProps> = {
  size: ICON_SIZE,
  color: colors.black.mediumEmphasis,
  category: "MaterialCommunityIcons",
}
