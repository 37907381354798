import { RouteProp } from "@react-navigation/core"
import { useNavigation, useRoute } from "@react-navigation/native"
import {
  ActionBar,
  firestore,
  H6,
  logger,
  MaterialIcons,
  useAlert,
  useCallbackConnected,
  userContext,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import DraggableFlatList, { DragEndParams, RenderItemParams } from "react-native-draggable-flatlist"
import FastImage from "react-native-fast-image"
import { TouchableOpacity } from "react-native-gesture-handler"
import { ActivityIndicator, List } from "react-native-paper"

import { HeaderIcon } from "../../components/HeaderIcon"
import { ICON_MEDIUM_SIZE } from "../../config/Constants"
import { FirCardWithId } from "../../features/models/Card"
import { getOrderedCardsQuery } from "../../features/models/UserDataFunctions"
import { IHomeParamList } from "../MainScreen/MainTabs"
import { ns } from "./i18n/fr"

type SortCardScreenRouteProp = RouteProp<IHomeParamList, "Home_Sort">

const keyExtractor = (item: FirCardWithId) => item.id

const SortCardScreen: FC = () => {
  const { userDocRef } = useContext(userContext)
  const { t } = useTranslation(ns)
  const {
    screenStyle,
    dimensions: { spacing },
    colors: {
      primary,
      black: { inactive, shadow },
      white: { highEmphasis },
    },
  } = useTheme()
  const { showSnack } = useAlert()
  const navigation = useNavigation()
  const { params } = useRoute<SortCardScreenRouteProp>()

  const cardPath = params?.freeSpace ? "c" : `h${params?.path}`

  const [cards] = useCollectionData<FirCardWithId>(
    getOrderedCardsQuery(userDocRef?.collection(cardPath)),
    { idField: "id" },
  )

  const [sortedCards, setSortedCards] = useState<FirCardWithId[]>()
  const [loading, setLoading] = useState(false)

  const s = useMemo(
    () => ({
      listTitle: {
        paddingLeft: spacing,
      },
      mainView: [screenStyle, styles.mainView],
      icon: {
        paddingRight: spacing,
      },
      listItem: [
        styles.listItem,
        {
          borderColor: shadow,
          paddingVertical: spacing,
          marginHorizontal: spacing,
        },
      ],
    }),
    [screenStyle, shadow, spacing],
  )

  const updateCardsOrder = useCallback(
    async (orderedCards: FirCardWithId[]) => {
      const batch = firestore().batch()
      try {
        _.forEach(orderedCards, item => {
          if (!userDocRef) {
            return
          }
          batch.set(userDocRef?.collection(cardPath).doc(item.id), _.pick(item, ["o"]), {
            merge: true,
          })
        })
        return batch.commit()
      } catch (e) {
        throw new Error(e)
      }
    },
    [cardPath, userDocRef],
  )

  const onIconPress = useCallbackConnected(async () => {
    setLoading(true)
    try {
      await Promise.all(_.map(_.chunk(sortedCards, 500), updateCardsOrder))
      navigation.goBack()
    } catch (e) {
      logger(e)
      showSnack({ message: "" })
    } finally {
      setLoading(false)
    }
  }, [navigation, showSnack, sortedCards, updateCardsOrder])
  const commonIconProp = useMemo(
    () => ({
      size: ICON_MEDIUM_SIZE,
      color: highEmphasis,
      style: s.icon,
    }),
    [highEmphasis, s],
  )

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <>
          {loading ? (
            // @ts-ignore
            <ActivityIndicator {...commonIconProp} />
          ) : (
            <ActionBar
              icons={[
                {
                  id: 1,
                  icon: <HeaderIcon iconName="check" label={t("check")} onPress={onIconPress} />,
                },
              ]}
            />
          )}
        </>
      ),
    })
  }, [
    s,
    loading,
    spacing,
    navigation,
    userDocRef,
    sortedCards,
    highEmphasis,
    commonIconProp,
    onIconPress,
    t,
  ])

  useEffect(() => {
    if (cards && !sortedCards) {
      setSortedCards(cards)
    }
  }, [cards, loading, sortedCards])

  const renderItem = useCallback(
    ({ item, drag }: RenderItemParams<FirCardWithId>) => {
      const left = () => (
        // @ts-ignore
        <View style={styles.leftView}>
          {/* @ts-ignore */}
          <FastImage style={styles.leftImage} source={{ uri: item.u }} />
          <H6 numberOfLines={2} style={s.listTitle}>
            {item.t}
          </H6>
        </View>
      )

      const right = () => (
        // @ts-ignore
        <TouchableOpacity onPressIn={drag} style={styles.rightView}>
          <MaterialIcons size={32} name="drag-indicator" style={styles.icon} color={inactive} />
        </TouchableOpacity>
      )
      /* @ts-ignore */
      return <List.Item title="" style={s.listItem} {...{ left, right }} />
    },
    [s, inactive],
  )

  const onDragEnd = ({ data }: DragEndParams<FirCardWithId>) => {
    setSortedCards(_.map(data, (item, index) => ({ ...item, o: index })))
  }

  return (
    // @ts-ignore
    <View style={s.mainView}>
      {sortedCards ? (
        <DraggableFlatList
          data={sortedCards}
          scrollIndicatorInsets={{ right: 1 }}
          {...{ onDragEnd, renderItem, keyExtractor }}
        />
      ) : (
        // @ts-ignore
        <ActivityIndicator style={styles.indicator} size="large" color={primary} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  indicator: {
    flex: 1,
    alignContent: "center",
    justifyContent: "center",
  },
  rightView: {
    flex: 1,
    minWidth: 50,
    justifyContent: "center",
  },
  icon: {
    alignSelf: "center",
  },
  mainView: {
    flex: 1,
  },
  leftView: {
    maxWidth: "70%",
    flexDirection: "row",
    alignItems: "center",
  },
  listItem: {
    borderBottomWidth: 1,
  },
  leftImage: {
    width: 56,
    height: 56,
  },
})

export default SortCardScreen
