import { generateShadow, Touchable } from "@siruplab/capsule"
import _ from "lodash"
import React, { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import { drawColors, textColors } from "../config/Constants"
import { ns } from "../screens/DrawScreen/i18n/fr"
import { useTheme } from "../utils/defaultStyle"

interface IProps {
  color: string
  isSelected: boolean
  onPress: (color: string) => void
}

const getColorName = (colorCode: string) =>
  _.findKey(drawColors, item => item === colorCode) ??
  _.findKey(textColors, item => item === colorCode)

const ColorButton: FC<IProps> = ({ color, onPress, isSelected }) => {
  const {
    colors: {
      accent3,
      white: { highEmphasis: white },
    },
    dimensions: { spacing, buttonSize, borderRadius },
  } = useTheme()

  const { t } = useTranslation(ns)

  const s = useMemo(
    () => ({
      main: [
        styles.main,
        {
          borderRadius,
          height: buttonSize,
          width: buttonSize,
          margin: spacing / 8,
          marginBottom: spacing / 3,
          backgroundColor: isSelected ? accent3 : white,
        },
      ],
      colorView: [
        styles.colorView,
        {
          backgroundColor: color,
        },
      ],
    }),
    [spacing, isSelected, accent3, white, color, buttonSize, borderRadius],
  )

  const onColorChange = useCallback(() => {
    onPress(color)
  }, [color, onPress])

  return (
    <Touchable
      style={s.main}
      onPress={onColorChange}
      accessibilityLabel={t(`colors.${getColorName(color)}`)}
    >
      <View style={s.colorView} />
    </Touchable>
  )
}

const styles = StyleSheet.create({
  colorView: {
    width: 32,
    height: 32,
    borderRadius: 50,
    ...generateShadow(2),
  },
  main: {
    alignItems: "center",
    justifyContent: "center",
    ...generateShadow(4),
  },
})

export default ColorButton
