import { BottomTabBarOptions } from "@react-navigation/bottom-tabs"
import { BottomTabBarProps } from "@react-navigation/bottom-tabs/src/types"
import { useTheme } from "@siruplab/capsule"
import React, { useMemo } from "react"
import { Pressable, StyleSheet, View } from "react-native"

import { ICON_SIZE } from "../config/Constants"

const BottomTabBar = ({
  state,
  descriptors,
  navigation,
}: BottomTabBarProps<BottomTabBarOptions>) => {
  const {
    colors: {
      black: { highEmphasis: black },
      white: { highEmphasis: white },
    },
  } = useTheme()

  const s = useMemo(
    () => ({
      mainView: [
        styles.mainView,
        {
          backgroundColor: white,
        },
      ],
    }),
    [white],
  )

  return (
    <View style={s.mainView}>
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key]

        const isFocused = state.index === index

        const onPress = () => {
          const event = navigation.emit({
            type: "tabPress",
            target: route.key,
            canPreventDefault: true,
          })

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name)
          }
        }

        return (
          <Pressable
            key={index}
            onPress={onPress}
            style={styles.tabItem}
            accessibilityRole="button"
            testID={options.tabBarTestID}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            accessibilityState={isFocused ? { selected: true } : {}}
          >
            {options.tabBarIcon?.({ color: black, size: ICON_SIZE, focused: isFocused })}
          </Pressable>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  tabItem: {
    height: 66,
    width: 80,
    alignItems: "center",
    justifyContent: "center",
  },
  mainView: {
    left: 0,
    height: "100%",
    alignItems: "center",
    position: "absolute",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
})

export default BottomTabBar
