import { DrawRef } from "@benjeau/react-native-draw"
import { generateShadow, MaterialIcons, Touchable } from "@siruplab/capsule"
import React, { Dispatch, FC, RefObject, useCallback, useMemo } from "react"
import { StyleSheet } from "react-native"
import { Image } from "react-native-image-crop-picker"
import ReactPainter from "react-painter"

import { ICON_MEDIUM_SIZE, imageOptions } from "../config/Constants"
import { Source } from "../screens/DrawScreen/DrawScreen.web"
import { useTheme } from "../utils/defaultStyle"
import isWeb from "../utils/isWeb"
import { useShareDrawScreenLogic } from "../utils/useShareDrawScreenLogic"
import ImagesUploader from "./ImagesUploader"

interface CameraButtonProps {
  source: Source
  setSource?: Dispatch<React.SetStateAction<Image | undefined>>
  isSelected: boolean
  isFromTakePicScreen?: boolean
  drawRef: RefObject<ReactPainter | DrawRef>
  onClearWeb?: () => void
}

const CameraButton: FC<CameraButtonProps> = ({
  isFromTakePicScreen,
  isSelected,
  source,
  setSource,
  drawRef,
  onClearWeb,
}) => {
  const {
    colors: {
      accent3,

      black: { inactive },
      white: { highEmphasis: white },
    },
    dimensions: { spacing, buttonSize, borderRadius },
  } = useTheme()

  const s = useMemo(
    () => ({
      main: [
        styles.main,
        {
          borderRadius,
          height: buttonSize,
          width: buttonSize,
          margin: spacing / 8,
          marginBottom: spacing / 3,
          backgroundColor: isSelected ? accent3 : white,
        },
      ],
    }),
    [spacing, accent3, white, isSelected, buttonSize, borderRadius],
  )

  const isCameraImageLoaded = source != null

  const handlePressCamera = useCallback(() => {
    if (isCameraImageLoaded) {
      setSource?.(undefined)
    }
  }, [isCameraImageLoaded, setSource])

  const { onClear } = useShareDrawScreenLogic()
  const handleClear = useCallback(() => {
    if (isWeb && onClearWeb) {
      onClearWeb()
    } else {
      onClear(drawRef)
    }
  }, [onClearWeb, onClear, drawRef])

  return (
    <Touchable style={s.main} onPress={isFromTakePicScreen ? handleClear : handlePressCamera}>
      {isFromTakePicScreen ? (
        <MaterialIcons name="format-color-reset" size={ICON_MEDIUM_SIZE} color={inactive} />
      ) : isCameraImageLoaded ? (
        <MaterialIcons name="no-photography" size={ICON_MEDIUM_SIZE} color={inactive} />
      ) : (
        <ImagesUploader
          // @ts-ignore
          updatePics={setSource}
          imageOptions={imageOptions}
        >
          <MaterialIcons name="photo-camera" size={ICON_MEDIUM_SIZE} color={inactive} />
        </ImagesUploader>
      )}
    </Touchable>
  )
}

export default CameraButton

const styles = StyleSheet.create({
  main: {
    alignItems: "center",
    justifyContent: "center",
    ...generateShadow(4),
  },
})
