import { mainRoutes } from "../../../features/Navigation/Constants"

export const ns = mainRoutes.SENTENCE

export default {
  [ns]: {
    read: "Lire la phrase",
    play: "Reprendre le retour automatique",
    pause: "Suspendre le retour automatique",
    placeholder: "Ajouter un texte…",
    favorite: "Favoris",
    validate: "Valider la séquence",
    recap: "Récapitulatif",
  },
}
