import { Image } from "react-native-image-crop-picker"

export const extractFilename = (source: Image | File): string => {
  const sourceName = (source as Image)?.filename || (source as File)?.name
  // Android : we get the filename from the path, we exclude the file path form.
  const regex = sourceName ? /^([^.]+)\.[^.]+$/ : /\/([^\/]+)\.[^\/]+$/

  const match = regex.exec(sourceName ?? (source as Image).path)
  /*  ((source as Image)?.filename || (source as File)?.name) ?? (source as Image).path, */

  return match?.[1] ?? "image"
}
