import "cropperjs/dist/cropper.css"

import Slider from "@react-native-community/slider"
import { Button, DataRes, PermissionCameraWrapper, WebGalleryPicker } from "@siruplab/capsule"
import React, { ComponentProps, PropsWithChildren, useCallback, useEffect, useState } from "react"
import Cropper from "react-cropper"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import { Image as PickerImage } from "react-native-image-crop-picker"
import { ActivityIndicator, Modal, Portal } from "react-native-paper"

import { maximumImageSize } from "../config/Constants"
import { maxWidth, maxWidthCardEditor } from "../features/config/useTabland"
import { ns } from "../screens/TakePicScreen/i18n/fr"
import { useTheme } from "../utils/defaultStyle"
import { useCropper } from "../utils/useCropper"
import { BottomDoubleButtonsMaterial } from "./"

interface IProps {
  updatePics: (newPics: { path: string }) => Promise<void> | void
  allowPdf?: boolean
}

export type ImageOrDocument = PickerImage & { fileCopyUri?: string }

type PermissionCameraWrapperProps = ComponentProps<typeof PermissionCameraWrapper>

const ImagesUploader = ({
  autoLaunch = false,
  children,
  updatePics,
  allowPdf = false,
  ...props
}: PropsWithChildren<IProps & PermissionCameraWrapperProps>) => {
  const [loading, setLoading] = useState(false)
  const {
    dimensions: { buttonSize },
    colors: { primary },
  } = useTheme()

  const { t } = useTranslation(ns)
  const {
    visibleCropper,
    setVisibleCropper,
    cropperRef,
    scale,
    onScaleChange,
    onResetCrop,
    imgUri,
    setImgUri,
    cropperStyle: s,
  } = useCropper()

  useEffect(() => {
    if (!visibleCropper) {
      setLoading(false)
    }
  }, [visibleCropper])

  const onComplete = (data: DataRes[]) => {
    setLoading(true)
    setVisibleCropper(true)
    setImgUri(data[0].data as string)
  }

  const onValidateCrop = useCallback(() => {
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas({
        width: maximumImageSize,
        height: maximumImageSize,
      })

      const formatMatch = imgUri?.match(/^data:image\/([a-zA-Z+]+);base64,/)
      const format = formatMatch ? formatMatch[1] : "png"
      const resizedImgUri = croppedCanvas.toDataURL(`image/${format}`)
      updatePics({ path: resizedImgUri })
      setVisibleCropper(false)
    }
  }, [cropperRef, imgUri, setVisibleCropper, updatePics])

  return (
    <View style={props.style}>
      <Portal>
        <Modal
          visible={visibleCropper}
          onDismiss={() => setVisibleCropper(false)}
          style={styles.modalContainer}
          contentContainerStyle={s.modalContainer}
        >
          {!imgUri ? (
            <ActivityIndicator style={s.indicator} color={primary} size={32} />
          ) : (
            <Cropper
              src={imgUri}
              style={{ height: maxWidth / 2, width: "100%" }}
              aspectRatio={1}
              guides={false}
              viewMode={1}
              ref={cropperRef}
              cropBoxResizable={false}
            />
          )}

          <View style={s.cropOptions}>
            <Button icon="autorenew" onPress={onResetCrop}>
              {t("reset")}
            </Button>
            <Slider
              style={{ width: maxWidth / 4, height: buttonSize }}
              minimumValue={0.1}
              maximumValue={4}
              value={scale}
              onValueChange={onScaleChange}
              minimumTrackTintColor={primary}
              maximumTrackTintColor={primary}
              thumbTintColor={primary}
            />
          </View>
          <BottomDoubleButtonsMaterial
            clearIcon="close-circle"
            onClear={() => setVisibleCropper(false)}
            onSave={onValidateCrop}
            saveIcon="content-save"
            clearText={t("common:button.cancel")}
            saveText={t("save")}
          />
        </Modal>
      </Portal>

      <WebGalleryPicker
        onComplete={onComplete}
        multiple
        style={{
          width: "100%",
          height: "100%",
          maxWidth: maxWidthCardEditor,
          alignSelf: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
        autoLaunch={autoLaunch}
        accept={allowPdf ? "image/*,.pdf" : undefined}
      >
        {loading ? <ActivityIndicator style={s.indicator} color={primary} size={32} /> : children}
      </WebGalleryPicker>
    </View>
  )
}

export default ImagesUploader

const styles = StyleSheet.create({
  modalContainer: {
    alignItems: "center",
    flex: 1,
  },
})
