import {
  AlertButton,
  ButtonType,
  generateShadow,
  H6,
  IDialogInternal,
  statusBarHeight,
  TextInput,
  TextInputDialog,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { Dispatch, FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import { ActivityIndicator, HelperText } from "react-native-paper"

import { maxWidthModal } from "../features/config/useTabland"
import { AlertAction, AlertState } from "../screens/AddCardsScreen/Constants"
import { ns } from "../screens/HomeScreen/i18n/fr"
import { useTheme } from "../utils/defaultStyle"

interface IProps {
  isHome?: boolean
  alertState: AlertState
  onCancelPress: () => void
  dispatch: Dispatch<AlertAction>
  onValidPress: () => Promise<void>
}

export const ShareAlertDialog: FC<IProps> = ({
  isHome,
  onValidPress,
  onCancelPress,
  alertState,
  dispatch,
}) => {
  const { shareData, shareVisible, error, loading } = alertState
  const { t } = useTranslation(ns)
  const {
    typography: { h6 },
    dimensions: { spacing, buttonSize, borderRadius },
    colors: {
      black,
      primary,
      overrides,
      error: textError,
      white: { highEmphasis: white },
    },
  } = useTheme()
  const s = useMemo(
    () => ({
      buttonStyle: [
        styles.button,
        {
          height: buttonSize,
          backgroundColor: white,
        },
      ],
      alertContent: [
        styles.alertContent,
        {
          marginTop: spacing + statusBarHeight,
        },
      ],
      alertView: [
        styles.alertView,
        {
          paddingHorizontal: spacing,
        },
      ],
      textView: {
        borderRadius,
        padding: spacing,
        marginTop: spacing,
        marginHorizontal: spacing,
        backgroundColor: overrides?.textInput,
      },

      text: {
        color: isHome ? black.highEmphasis : black.disabled,
      },
      textInput: [
        styles.textInput,
        h6,
        {
          borderRadius,
          marginTop: spacing,
          marginHorizontal: spacing,
        },
      ],
      helperText: error
        ? {
            marginLeft: spacing / 4,
            marginTop: spacing / 2,
          }
        : undefined,
    }),
    [black, error, h6, isHome, overrides, spacing, white, buttonSize, borderRadius],
  )

  const onValid = useCallback(async () => {
    dispatch({ type: "loading_code" })
    await onValidPress()
    dispatch({ type: "completed" })
  }, [dispatch, onValidPress])

  const renderShareText = useCallback(
    () => (
      <View style={s.textView}>
        <H6 style={s.text}>{shareData?.code ?? t("loading")}</H6>
        {!shareData?.code ? (
          <ActivityIndicator style={styles.indicator} color={primary} size={24} />
        ) : null}
      </View>
    ),
    [primary, s, shareData, t],
  )

  const onChangeText = useCallback(
    (text: string) => {
      dispatch?.({
        type: "text",
        data: {
          path: shareData?.path ?? "",
          code: text,
        },
      })
    },
    [shareData, dispatch],
  )

  const renderShareInput = useCallback(
    () => (
      <>
        <TextInput
          error={!_.isEmpty(error)}
          style={s.textInput}
          autoCapitalize="none"
          value={shareData?.code}
          onChangeText={onChangeText}
          inputColor={black.mediumEmphasis}
          placeholder={t("dialog.placeholder")}
          onTouchStart={() => dispatch?.({ type: "error", error: undefined })}
        />
        {error ? (
          <HelperText
            style={s.helperText}
            visible={true}
            type="error"
            onPressIn={() => {}}
            onPressOut={() => {}}
            android_hyphenationFrequency="none"
          >
            {error}
          </HelperText>
        ) : null}
      </>
    ),
    [black.mediumEmphasis, error, onChangeText, s, dispatch, shareData, t],
  )

  const renderAlertButton = useCallback(
    (positive: boolean): AlertButton => ({
      mode: "outlined",
      buttonStyle: s.buttonStyle,
      textStyle: { paddingLeft: spacing / 2 },
      loading: positive && !isHome ? loading : undefined,
      onPress: loading ? undefined : positive ? onValid : onCancelPress,
      type: positive ? ButtonType.POSITIVE : ButtonType.NEGATIVE,
      label: t(positive ? (isHome ? "copy" : "common:button.validate") : "common:button.cancel"),
      icon: {
        color: loading ? black.disabled : positive ? primary : textError,
        category: "MaterialCommunityIcons",
        name: positive ? "check-circle" : "close-circle",
      },
    }),
    [
      s.buttonStyle,
      spacing,
      loading,
      onValid,
      onCancelPress,
      t,
      isHome,
      black.disabled,
      primary,
      textError,
    ],
  )

  const dialogProps = useMemo(
    () => ({
      dialog: {
        visible: shareVisible,
        type: "text",
        style: s.alertView,
        dismissable: !loading,
        contentContainerStyle: s.alertContent,
        positive: renderAlertButton(true),
        negative: renderAlertButton(false),
        onDismiss: loading ? undefined : onCancelPress,
        children: isHome ? renderShareText() : renderShareInput(),
      } as IDialogInternal,
    }),
    [
      shareVisible,
      s,
      loading,
      renderAlertButton,
      onCancelPress,
      isHome,
      renderShareText,
      renderShareInput,
    ],
  )
  // For android, shadow stay a little after the dialog is closed
  return dialogProps.dialog.visible ? (
    <TextInputDialog
      dialog={dialogProps.dialog}
      isValid={!_.isEmpty(shareData?.code) && !loading}
    />
  ) : null
}

const styles = StyleSheet.create({
  button: {
    borderWidth: 0,
    justifyContent: "center",
    ...generateShadow(4),
  },
  alertContent: {
    maxWidth: maxWidthModal,
    width: "100%",
  },
  alertView: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  alertTitle: {
    textAlign: "center",
  },
  indicator: {
    top: 16,
    right: 16,
    position: "absolute",
    alignSelf: "flex-end",
  },
  textInput: {
    maxHeight: 56,
    borderTopEndRadius: 8,
    borderTopStartRadius: 8,
  },
})
