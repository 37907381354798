import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import { Button, generateShadow, MaterialCommunityIcons, useTheme } from "@siruplab/capsule"
import _ from "lodash"
import React, { FC, useCallback, useMemo, useState } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

// Resolve require cycle
import { SentenceCard } from "../components/SentenceCard"
import { ICON_MEDIUM_SIZE } from "../config/Constants"
import { FirCardWithId } from "../features/models/Card"
import { FirCardToCard } from "../features/models/CardFunctions"
import { deleteUserFavorites } from "../features/models/UserDataFunctions"
import { mainRoutes } from "../features/Navigation/Constants"
import { ns } from "../screens/FavoritesScreen/i18n/fr"
import { IFreeSpaceFavParamList } from "../screens/MainScreen/MainTabs"
import { Mode } from "./ConfirmationDialogContent"
import ConfirmationModal from "./ConfirmationModal"

type FavoritesScreenNavigationProp = StackNavigationProp<
  IFreeSpaceFavParamList,
  "FreeSpaceFav_Main"
>

interface IProps {
  url: string
  userDocRef: FirebaseFirestoreTypes.DocumentReference | null
}
const defaultDeleteState = { loading: false, visible: false }

export const FavoritesCard: FC<IProps> = ({ url, userDocRef }) => {
  const [isDelete, setIsDelete] = useState(defaultDeleteState)
  const { t } = useTranslation(ns)
  const navigation = useNavigation<FavoritesScreenNavigationProp>()
  const {
    colors: {
      primary,
      white,
      black: { inactive },
    },
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      favoritesContainer: [
        styles.favoritesContainer,
        {
          marginTop: spacing / 2,
          marginRight: spacing / 2,
        },
      ],
      button: [
        styles.button,
        {
          backgroundColor: white.highEmphasis,
          marginBottom: spacing,
          marginLeft: spacing / 2,
        },
      ],
    }),
    [spacing, white],
  )
  const l3cardRef = url ? userDocRef?.collection("h").doc(url) : undefined
  const l2cardRef = _.replace(l3cardRef?.parent?.parent?.path ?? "", `${userDocRef?.path}/h/`, "")

  const [l2card] = useDocumentData<FirCardWithId>(userDocRef?.collection("h").doc(l2cardRef), {
    idField: "id",
  })
  const [l3card] = useDocumentData<FirCardWithId>(userDocRef?.collection("h").doc(url), {
    idField: "id",
  })
  const onNavigate = useCallback(() => {
    navigation.navigate(mainRoutes.SENTENCE, { l3card: url, fromFav: true })
  }, [navigation, url])

  const onDeleteButtonPress = useCallback(async () => {
    setIsDelete(prev => ({ ...prev, loading: true }))
    // noinspection JSIgnoredPromiseFromCall
    deleteUserFavorites(url, userDocRef)
    await deleteUserFavorites(url, userDocRef)
    setIsDelete(defaultDeleteState)
  }, [userDocRef, url])

  const commonButtonProps = useMemo(
    () => ({
      style: s.button,
      contentStyle: styles.icon,
      labelStyle: styles.iconLabel,
    }),
    [s],
  )

  return (
    <View style={s.favoritesContainer}>
      <View style={styles.cardsContainer}>
        {[l2card, l3card].map(item =>
          item ? (
            <View style={styles.card} key={item.id}>
              <SentenceCard card={FirCardToCard(item)} onCardClick={onNavigate} />
            </View>
          ) : null,
        )}
      </View>
      <View style={styles.iconsContainer}>
        <Button {...commonButtonProps} onPress={onNavigate} accessibilityLabel={t("open")}>
          <MaterialCommunityIcons name="eye" color={primary} size={32} />
        </Button>
        <Button
          {...commonButtonProps}
          onPress={() => setIsDelete({ loading: false, visible: true })}
          accessibilityLabel={t("delete")}
        >
          <MaterialCommunityIcons name="delete" color={inactive} size={ICON_MEDIUM_SIZE} />
        </Button>
      </View>
      {l3card ? (
        <ConfirmationModal
          visible={isDelete.visible}
          dialogProps={{
            loading: isDelete.loading,
            title: t("delete"),
            card: FirCardToCard(l3card ?? l2card),
            onConfirm: onDeleteButtonPress,
            mode: Mode.delete,
            onClear: () => setIsDelete(defaultDeleteState),
          }}
        />
      ) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  favoritesContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
  iconsContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    borderWidth: 0,
    ...generateShadow(4),
  },
  icon: {
    width: 68,
    height: 68,
    alignSelf: "center",
    justifyContent: "center",
  },
  iconLabel: {
    lineHeight: 32,
  },
  cardsContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
  card: {
    width: 120,
  },
})
