import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react"

interface PicProviderContext {
  picture?: string
  setPicture: Dispatch<SetStateAction<string>>
}

const picProviderContext = createContext<PicProviderContext>({
  picture: undefined,
} as PicProviderContext)

const PictureProvider: FC = ({ children }) => {
  const [picture, setPicture] = useState<string>()

  const contextValue = useMemo(() => ({ picture, setPicture }), [picture, setPicture])

  return <picProviderContext.Provider value={contextValue}>{children}</picProviderContext.Provider>
}

export const usePicture = () => {
  const context = useContext<PicProviderContext>(picProviderContext)
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider")
  }
  return context
}

export default PictureProvider
