import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import { firestore, IUserContext, userContext, useStyles } from "@siruplab/capsule"
import _ from "lodash"
import React, { FC, useContext, useMemo } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { StyleSheet, View } from "react-native"
import FastImage from "react-native-fast-image"
import { Caption } from "react-native-paper"
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons"

import { FirCardWithId } from "../features/models/Card"
import { UserData } from "../features/models/UserData"
import { getUserColumns } from "../features/models/UserDataFunctions"
import getColumnWidth from "../utils/getColumnWidth"
import isWeb from "../utils/isWeb"

const Breadcrumbs: FC<{ collectionRef: FirebaseFirestoreTypes.CollectionReference }> = ({
  collectionRef,
}) => {
  const { userData } = useContext<IUserContext<UserData>>(userContext)

  const images: [string, string | undefined] = useMemo(() => {
    const pathElems = collectionRef.path?.split("/")
    return [
      _.take(pathElems, 4).join("/"),
      pathElems?.length > 5 ? _.take(pathElems, 6).join("/") : undefined,
    ]
  }, [collectionRef])

  const [firstCard, firstCardLoading] = useDocumentData<FirCardWithId>(firestore().doc(images[0]))
  const [secondCard, secondCardLoading] = useDocumentData<FirCardWithId>(
    images[1] ? firestore().doc(images[1]) : null,
  )

  const numberOfColumn = useMemo(() => {
    if (firstCard?.v) {
      return 1
    } else if (isWeb && firstCard?.col) {
      return parseInt(firstCard.col)
    } else {
      return getUserColumns(userData, false)
    }
  }, [firstCard?.col, userData, firstCard?.v])

  const s = useStyles(
    ({
      dimensions: { spacing },
      colors: {
        black: { inactive },
      },
    }) => ({
      webContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      },
      container: {
        width: isWeb ? getColumnWidth(numberOfColumn, spacing) : "100%",
        marginTop: spacing,
        marginHorizontal: spacing,
      },
      chevron: {
        marginHorizontal: spacing,
        color: inactive,
      },
      card: {
        paddingVertical: spacing / 2,
      },
    }),
    [numberOfColumn],
  )

  if (firstCardLoading || secondCardLoading) {
    return <View style={[styles.container, s.container]} />
  }

  return (
    <View style={isWeb ? s.webContainer : {}}>
      <View style={[styles.container, s.container]}>
        <View style={styles.card}>
          <FastImage style={styles.image} source={{ uri: firstCard?.u }} />
          <Caption ellipsizeMode="tail" numberOfLines={1} style={styles.title}>
            {firstCard?.t}
          </Caption>
        </View>
        {secondCard ? (
          <>
            <MaterialCommunityIcons name="chevron-right" style={[styles.chevron, s.chevron]} />
            <View style={styles.card}>
              <FastImage style={styles.image} source={{ uri: secondCard?.u }} />
              <Caption ellipsizeMode="tail" numberOfLines={1} style={styles.title}>
                {secondCard?.t}
              </Caption>
            </View>
          </>
        ) : null}
      </View>
    </View>
  )
}

export default Breadcrumbs

const styles = StyleSheet.create({
  image: {
    width: 64,
    aspectRatio: 1,
  },
  title: {
    textAlign: "center",
  },
  container: {
    flexDirection: "row",
    height: 96,
    alignItems: "center",
  },
  card: {
    alignItems: "center",
    maxWidth: 128,
  },
  chevron: {
    fontSize: 24,
  },
})
