import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs"
import { ParamListBase, useNavigation } from "@react-navigation/native"
import { generateShadow, MaterialCommunityIcons, MaterialIcons, useTheme } from "@siruplab/capsule"
import React, { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import { iconSize } from "../../config/Constants"
import { paramsTabsRoutes } from "../../features/Navigation/Constants"
import { ns } from "../CreateNoteScreen/i18n/fr"
import DrawScreen from "../DrawScreen/DrawScreen"
import TakePicScreen from "../TakePicScreen/TakePicScreen"
import TextAreaScreen from "../TextAreaScreen/TextAreaScreen"
export interface ITabParamList extends ParamListBase {
  Tab_Write: undefined
  Tab_Draw: undefined
  Tab_TakePic: undefined
}

const commonIconProps = {
  size: iconSize.common,
}

const Tab = createMaterialTopTabNavigator<ITabParamList>()

const CreateNoteTabNavigatorScreen: FC = () => {
  const {
    colors: {
      primary,
      surface: { header },
      white: { highEmphasis: white },
      black: { inactive },
    },
  } = useTheme()
  const { t } = useTranslation(ns)

  const s = useMemo(
    () => ({
      indicator: [
        styles.indicator,
        {
          borderColor: primary,
          backgroundColor: header,
        },
      ],
      mainTab: {
        backgroundColor: white,
      },
    }),
    [header, primary, white],
  )
  const { setOptions } = useNavigation()

  const listeners = useCallback(
    (title: string) => ({
      focus: () => {
        setOptions({ title })
      },
    }),
    [setOptions],
  )

  return (
    <Tab.Navigator
      style={s.mainTab}
      // I had to apply this weird style here because of an issue from react-native-tab-view: https://github.com/satya164/react-native-tab-view/issues/804#issuecomment-685018485
      swipeEnabled={false}
      tabBarOptions={{
        style: styles.tab,
        indicatorStyle: s.indicator,
        activeTintColor: primary,
        inactiveTintColor: inactive,
      }}
    >
      <Tab.Screen
        name={paramsTabsRoutes.TAB_WRITE}
        component={TextAreaScreen}
        listeners={listeners(t("write"))}
        options={{
          title: t("write"),
          tabBarAccessibilityLabel: t("write"),
          tabBarLabel: ({ color }) => (
            <MaterialCommunityIcons name="format-letter-case" color={color} {...commonIconProps} />
          ),
        }}
      />
      <Tab.Screen
        name={paramsTabsRoutes.TAB_DRAW}
        component={DrawScreen}
        listeners={listeners(t("draw"))}
        options={{
          title: t("draw"),
          tabBarAccessibilityLabel: t("draw"),
          tabBarLabel: ({ color }) => (
            <MaterialIcons name="brush" color={color} {...commonIconProps} />
          ),
        }}
      />
      <Tab.Screen
        name={paramsTabsRoutes.TAB_TAKEPIC}
        component={TakePicScreen}
        listeners={listeners(t("takePic"))}
        options={{
          title: t("takePic"),
          tabBarAccessibilityLabel: t("takePic"),
          tabBarLabel: ({ color }) => (
            <MaterialIcons name="photo-camera" color={color} {...commonIconProps} />
          ),
        }}
      />
    </Tab.Navigator>
  )
}

const styles = StyleSheet.create({
  tab: {
    width: 360,
    alignSelf: "center",
    ...generateShadow(1),
  },
  indicator: {
    height: "100%",
    borderBottomWidth: 1.5,
  },
})

export default CreateNoteTabNavigatorScreen
