import {
  getPathFromState,
  LinkingOptions,
  NavigationContainer,
  PathConfig,
} from "@react-navigation/native"
import { NavigationState } from "@react-navigation/routers"
import {
  AlertProvider,
  dynamicLinks,
  FirebaseDynamicLinksTypes,
  i18n,
  IntlProvider,
} from "@siruplab/capsule"
import { TFunction } from "i18next"
import _ from "lodash"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { Linking, StatusBar } from "react-native"
import RNBootSplash from "react-native-bootsplash"
import { URL } from "react-native-url-polyfill"

import useAutoResetState from "./common/useAutoResetState"
import { ignoreWarnings } from "./features/config/YellowBox"
import translation from "./features/i18n/i18n"
import {
  createRoutes,
  favoritesRoutes,
  freeSpaceFavRoutes,
  galleryRoutes,
  homeRoutes,
  mainRoutes,
  mainTabsRoutes,
  paramsTabsRoutes,
  RootRoutesNames,
  settingsRoutes,
  tabsRoutes,
} from "./features/Navigation/Constants"
import { navigationRef } from "./features/Navigation/NavigationService"
import RootNavigator from "./features/Navigation/RootNavigator"
import RootProvider from "./features/Providers/RootProvider"
import { ns as nsCreate } from "./screens/CreateNoteScreen/i18n/fr"
import { ns as freeNs } from "./screens/FreeCommSpaceTabNavigator/i18n/fr"
import { ns } from "./screens/MainScreen/i18n/fr"
import ScreenTracking from "./services/Analytics/analytics"
import ThemeApp from "./ThemeApp"
import isWeb from "./utils/isWeb"
import { handleTokens } from "./utils/jwt"
import LoadFonts from "./utils/loadFonts"
// Use the following when testing with a local instance of firebase and firestore
if (__DEV__) {
  // functions().useFunctionsEmulator("http://localhost:5001")
  /* firestore().settings({
    host: "localhost:8080",
    ssl: false,
    persistence: false,
    cacheSizeBytes: firestore.CACHE_SIZE_UNLIMITED,
  })*/
}

ignoreWarnings()

const transToPath = (text: string) => _(text).deburr().replace(/ /g, "_").toLowerCase()

export const screens = (t: TFunction): Record<RootRoutesNames, string | PathConfig> => ({
  [mainRoutes.ADD_CARD]: transToPath(t(`${mainRoutes.ADD_CARD}:title`)),
  [mainRoutes.ADD_PROCESS]: {
    path: transToPath(t(`${mainRoutes.ADD_PROCESS}:title`)),
    initialRouteName: mainRoutes.MAIN,
  },
  [mainRoutes.DISPLAY_NO_SETTINGS]: transToPath(t(`${mainRoutes.DISPLAY_NO_SETTINGS}:title`)),
  [mainRoutes.DISPLAY]: {
    path: transToPath(t(`${mainRoutes.DISPLAY}:title`)),
    initialRouteName: mainRoutes.MAIN,
  },
  [mainRoutes.DISPLAY_HIERARCHY]: {
    path: transToPath(t(`${mainRoutes.DISPLAY_HIERARCHY}:title`)),
    initialRouteName: mainRoutes.MAIN,
  },
  [mainRoutes.HOME_SORT]: transToPath(t(`${ns}:headers.sortCards`)),
  [mainRoutes.LOGIN]: transToPath(t(`${mainRoutes.LOGIN}:title`)),
  [mainRoutes.SIGNUP]: transToPath(t(`common:landing.signIn`)),
  [mainRoutes.WELCOME]: "",
  [mainRoutes.MAIN]: {
    screens: {
      [tabsRoutes.HOME]: {
        initialRouteName: homeRoutes.HOME_MAIN,
        screens: {
          [homeRoutes.HOME_MAIN]: {
            path: `${transToPath(t(`${ns}:headers.home`))}/:cardPath?`,
            parse: {
              cardPath: (cardPath: string) => `${decodeURIComponent(cardPath)}`,
            },
          },
        },
      },
      [tabsRoutes.GALLERY]: {
        initialRouteName: galleryRoutes.GALLERY_MAIN,
        screens: {
          [galleryRoutes.GALLERY_MAIN]: {
            path: transToPath(t(`${ns}:headers.gallery`)),
          },
          [galleryRoutes.GALLERY_EDIT]: {
            path: `${transToPath(t(`${ns}:headers.editCard`))}/:params?`,
            parse: {
              cardParams: (cardParams: string) => decodeURIComponent(cardParams),
            },
          },
          [galleryRoutes.GALLERY_CREATE]: {
            path: "galerie/ajouter",
          },
        },
      },
      [tabsRoutes.CREATE]: {
        initialRouteName: createRoutes.CREATE_MAIN,
        screens: {
          [createRoutes.CREATE_MAIN]: {
            path: transToPath(t(`${ns}:headers.create`)),
          },
          [mainTabsRoutes.TAB_MAIN]: {
            screens: {
              [paramsTabsRoutes.TAB_WRITE]: transToPath(t(`${nsCreate}:write`)),
              [paramsTabsRoutes.TAB_DRAW]: transToPath(t(`${nsCreate}:draw`)),
              [paramsTabsRoutes.TAB_TAKEPIC]: transToPath(t(`${nsCreate}:takePic`)),
            },
          },
          [createRoutes.CREATECARD_MAIN]: {
            path: `${transToPath(t(`${ns}:headers.create`))}/${transToPath(
              t(`${ns}:headers.createCard`),
            )}`,
          },
        },
      },
      [tabsRoutes.FAVORITES]: {
        screens: {
          [freeSpaceFavRoutes.FREE_SPACE_FAV_MAIN]: {
            screens: {
              [favoritesRoutes.TAB_FREECOMMSPACE]: transToPath(t(`${freeNs}:freeCommSpace`)),
              [favoritesRoutes.TAB_FAVORITES]: "favoris",
            },
          },
          [freeSpaceFavRoutes.FREE_SPACE_FAV_GALLERY_EDIT]: transToPath(
            `${t(`${freeNs}:freeCommSpace`)}/${t(`${ns}:headers.editCard`)}/:params?`,
          ),
          [freeSpaceFavRoutes.FREE_SPACE_FAV_CREATE]: transToPath(
            `${t(`${freeNs}:freeCommSpace`)}/${t(`${ns}:headers.createCard`)}/:params?`,
          ),
          [freeSpaceFavRoutes.FREE_SPACE_FAV_SORT]: transToPath(
            `${t(`${freeNs}:freeCommSpace`)}/${t(`${ns}:headers.sortCards`)}/:params?`,
          ),
        },
      },
      [tabsRoutes.SETTINGS]: {
        screens: {
          [settingsRoutes.SETTINGS_MAIN]: transToPath(t(`${ns}:headers.settings`)),
        },
      },
    },
  },
  [mainRoutes.SENTENCE]: {
    path: `${transToPath(t(`${ns}:headers.sentence`))}/:l3card?`,
    parse: {
      l3card: (l3card: string) => decodeURIComponent(l3card),
    },
  },
  [mainRoutes.NAVIGATION]: "navigation",
})

const linkingConstants: LinkingOptions = {
  prefixes: ["http://localhost:3000", "https://paips.page.link"],
  // only == and not === to match the verification made by the package and avoid an error
  getPathFromState: (state, options) => (state == null ? "/" : getPathFromState(state, options)),
}

const fonts = {
  Roboto: [
    { file: "Roboto-Regular", weight: 400 },
    { file: "Roboto-Bold", weight: 700 },
  ],
  OpenDyslexic: [
    { file: "OpenDyslexic-Regular", weight: 400 },
    { file: "OpenDyslexic-Bold", weight: 700 },
  ],
  ComicSansMS: [
    { file: "ComicSansMS-Regular", weight: 400 },
    { file: "ComicSansMS-Bold", weight: 700 },
  ],
} as { [key: string]: Array<{ file: string; weight: number }> }

const App = () => {
  const [shouldDisplayLogoutToast, setShouldDisplayLogoutToast] = useAutoResetState<boolean>(
    false,
    10000,
  )

  const first = useRef(true)
  const routeNameRef = useRef<string | Partial<NavigationState> | undefined>()
  const { t } = useTranslation(ns)

  const screenTracking = useCallback((state: NavigationState | undefined) => {
    const previousRouteName = routeNameRef.current
    const currentRouteName = ScreenTracking.getActiveRouteName(state)
    if (first.current || previousRouteName !== currentRouteName) {
      first.current = false
      ScreenTracking.logScreenTracking(currentRouteName)
    }
    // Save the current route name for later comparison
    routeNameRef.current = currentRouteName
  }, [])

  useEffect(() => {
    // @ts-ignore
    const state = navigationRef?.current?.getRootState()
    // Save the initial route name
    routeNameRef.current = ScreenTracking.getActiveRouteName(state)
  }, [])

  useEffect(() => {
    if (isWeb) {
      setTimeout(() => RNBootSplash.hide({ fade: true }), 3000)
    }
  }, [])

  const linking: LinkingOptions = useMemo(
    () => ({
      ...linkingConstants,
      async getInitialURL() {
        const dynamicLink = await dynamicLinks().getInitialLink()

        if (dynamicLink) {
          try {
            const url = new URL(dynamicLink.url)
            const urlTokens = await handleTokens(url)
            return urlTokens
          } catch (error) {
            setShouldDisplayLogoutToast(true)
            return null
          }
        }
        return dynamicLink
      },
      // Custom function to subscribe to incoming links
      subscribe(listener: (deeplink: string) => void) {
        // First, you may want to do the default deep link handling
        const onReceiveURL = ({ url }: { url: string }) => listener(url)
        // Listen to incoming links from deep linking
        Linking.addEventListener("url", onReceiveURL)
        const handleDynamicLink = async (dynamicLink: FirebaseDynamicLinksTypes.DynamicLink) => {
          try {
            const url = new URL(dynamicLink.url)

            const newUrl = await handleTokens(url)

            return listener(newUrl)
          } catch (error) {
            setShouldDisplayLogoutToast(true)
          }
        }
        const unsubscribeToDynamicLinks = dynamicLinks().onLink(handleDynamicLink)
        return () => {
          unsubscribeToDynamicLinks()
          Linking.removeAllListeners("url")
        }
      },
      config: { screens: screens(t) },
    }),
    [t, setShouldDisplayLogoutToast],
  )

  return (
    <NavigationContainer
      // @ts-ignore
      ref={navigationRef}
      linking={linking}
      onStateChange={screenTracking}
    >
      <RootProvider>
        <LoadFonts fonts={fonts} />
        <ThemeApp>
          <AlertProvider>
            <StatusBar backgroundColor="#5F48B9" barStyle="light-content" hidden={false} />
            <RootNavigator shouldDisplayLogoutToast={shouldDisplayLogoutToast} />
          </AlertProvider>
        </ThemeApp>
      </RootProvider>
    </NavigationContainer>
  )
}

const TransAppHOC = () => (
  <IntlProvider i18n={i18n({ translation })}>
    <App />
  </IntlProvider>
)

export default TransAppHOC
