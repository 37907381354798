import { accessibilityAction, generateShadow, H6, userContext } from "@siruplab/capsule"
import React, { Dispatch, FC, SetStateAction, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet, TextInput, View } from "react-native"
import { IconButton } from "react-native-paper"

import { ICON_MEDIUM_SIZE } from "../../config/Constants"
import { isEditable } from "../../features/models/UserDataFunctions"
import { useTheme } from "../../utils/defaultStyle"
import isWeb from "../../utils/isWeb"
import { ns } from "./i18n/fr"

interface IProps {
  sentence: string
  onPress: () => void
  isTtsEnded: boolean
  setSentence: Dispatch<SetStateAction<string | undefined>>
  isSingle?: boolean
}

export const SentenceHeader: FC<IProps> = ({
  onPress,
  sentence,
  setSentence,
  isTtsEnded,
  isSingle,
}) => {
  const {
    typography: { h6 },
    colors: { primary, white, black, overrides },
    dimensions: { spacing, borderRadius },
  } = useTheme()
  const { t } = useTranslation(ns)
  const { userData } = useContext(userContext)
  const editable = isEditable(userData)
  const soundIcon = isTtsEnded ? "volume-high" : "stop"
  const s = useMemo(
    () => ({
      icon: {
        backgroundColor: white.highEmphasis,
        ...generateShadow(4),
      },
      text: [
        styles.textInput,
        styles.container,
        {
          borderRadius,
          paddingHorizontal: spacing,
          ...h6,
          backgroundColor: isEditable(userData) ? overrides?.textInput : black.transparent,
          marginTop: spacing / 2,
        },
        // issue with textInput.iOS, if one line, the text is not center instead of Android that need to remove padding to see two lines
        Platform.OS === "ios" || Platform.OS === "web"
          ? {
              paddingTop: 16,
            }
          : {
              paddingVertical: spacing / 8,
            },
      ],
      h6: [
        styles.container,
        {
          paddingLeft: spacing,
        },
      ],
      container: isWeb &&
        isSingle && [
          styles.row,
          {
            justifyContent: "center" as const,
            width: 800,
          },
        ],
      row: [
        styles.row,
        {
          width: isWeb && isSingle ? 400 : "100%",
          marginHorizontal: spacing,
        },
      ],
    }),
    [
      white.highEmphasis,
      h6,
      userData,
      overrides,
      black.transparent,
      spacing,
      borderRadius,
      isSingle,
    ],
  )

  const iconProps = useMemo(
    () => ({
      size: ICON_MEDIUM_SIZE,
      style: s.icon,
      color: primary,
    }),
    [primary, s.icon],
  )

  return (
    <View style={s.container}>
      <View style={s.row}>
        {!editable ? (
          <IconButton
            {...iconProps}
            icon={soundIcon}
            onPress={onPress}
            accessibilityLabel={t("read")}
            accessibilityActions={[{ name: accessibilityAction, label: t("read") }]}
            onAccessibilityAction={event => {
              if (event.nativeEvent.actionName === accessibilityAction) {
                onPress()
              }
            }}
          />
        ) : null}
        {editable ? (
          <TextInput
            style={s.text}
            value={sentence}
            multiline={true}
            blurOnSubmit={true}
            returnKeyType="done"
            onChangeText={setSentence}
            placeholder={t("placeholder")}
            placeholderTextColor={black.inactive}
          />
        ) : (
          <H6 style={s.h6}>{sentence}</H6>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  textInput: {
    height: 55,
    overflow: "hidden",
    textAlignVertical: "center",
  },
})

export default SentenceHeader
