import { Theme, useTheme as capsuleUseTheme } from "@siruplab/capsule"

import { ExtendedTheme } from "../ThemeApp"
export interface IStyle {
  ID?: string
  Name?: string
  Title_font?: string
  Text_font?: string
  Dark?: boolean
  Primary?: string
  Secondary?: string
  Surface_card?: string
  Surface_app_ui?: string
  Grid_columns?: number
  Grid_gutter?: number
  Grid_margin?: number
  Grid_rows?: number
}

export const fonts = {
  default: { family: "Roboto", iosFamily: "Roboto" },
}

export const useTheme = () => capsuleUseTheme<ExtendedTheme & Theme>()
