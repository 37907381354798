import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import { RouteProp } from "@react-navigation/core"
import { useNavigation, useRoute } from "@react-navigation/native"
import { HeaderBackButton, StackNavigationProp } from "@react-navigation/stack"
import {
  ActionBar,
  ButtonType,
  firestore,
  functions,
  generateShadow,
  IUserContext,
  logger,
  openUrl,
  statusBarHeight,
  useAlert,
  userContext,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"
import RNExitApp from "react-native-exit-app"

import { Auth, LicenseType } from "../../common/Trial"
import { Level } from "../../common/types"
import {
  CardButton,
  CardsView,
  ConfirmationModal,
  ICardItemExt,
  Mode,
  ShareAlertDialog,
} from "../../components"
import { HeaderIcon } from "../../components/HeaderIcon"
import { ICON_MEDIUM_SIZE, urls } from "../../config/Constants"
import { Card, FirCard, FirCardWithId } from "../../features/models/Card"
import { FirCardToCard } from "../../features/models/CardFunctions"
import { UserData } from "../../features/models/UserData"
import {
  getOrderedCardsQuery,
  isAdminable,
  isEditable,
} from "../../features/models/UserDataFunctions"
import { homeRoutes, mainRoutes, tabsRoutes } from "../../features/Navigation/Constants"
import { useValidatedCards } from "../../features/Providers/ValidatedCardsProvider"
import { useTheme } from "../../utils/defaultStyle"
import isWeb from "../../utils/isWeb"
import { useAlertdialog } from "../../utils/useAlertDialog"
import { ns as galleryNs } from "../GalleryScreen/i18n/fr"
import { IHomeParamList } from "../MainScreen/MainTabs"
import { ns } from "./i18n/fr"

type HomeScreenNavigationProp = StackNavigationProp<IHomeParamList, "Home_Main">
type HomeScreenScreenRouteProp = RouteProp<IHomeParamList, "Home_Main">
export type ValidatedCards = Record<string, boolean>

const HomeScreen: FC = () => {
  const { validatedCards, setValidatedCards } = useValidatedCards()
  const {
    colors: {
      primary,
      white: { highEmphasis: white },
    },
    screenStyle,
    dimensions: { spacing, buttonSize },
  } = useTheme()
  const { t } = useTranslation(ns)
  const { onShareValidPress, onCancelPress, alertState, dispatch } = useAlertdialog()
  const { showDialog, showSnack } = useAlert()
  const { params } = useRoute<HomeScreenScreenRouteProp>()
  const navigation = useNavigation<HomeScreenNavigationProp>()
  const { user, userDocRef, userData, logout } = useContext<IUserContext<UserData>>(userContext)
  const [userAuth] = useDocumentData<Auth>(
    user ? firestore().collection("auth").doc(user?.uid) : null,
  )
  const [deletedCard, setDeletedCard] = useState<Card & { path: string }>()
  const currentLevel = useMemo(
    () =>
      Level[
        _.isUndefined(params?.cardPath)
          ? "first"
          : (_.countBy(params?.cardPath)["/"] || 0) === 0
          ? "second"
          : "third"
      ],
    [params],
  )

  const collectionRef = useMemo(
    (): FirebaseFirestoreTypes.CollectionReference | undefined =>
      userDocRef
        ? params?.cardPath
          ? userDocRef?.collection("h").doc(`${params?.cardPath}`).collection("a")
          : userDocRef?.collection("h")
        : undefined,
    [params, userDocRef],
  )

  const rootCardPath = currentLevel > Level.first ? collectionRef?.path.split("/")?.[3] : undefined
  const [hierarchySettings, hierarchySettingsLoading] = useDocumentData<FirCardWithId>(
    currentLevel > Level.first ? userDocRef?.collection("h").doc(rootCardPath) : undefined,
  )
  const isValidation = hierarchySettings?.v
  const specificColumnNumber = hierarchySettings?.col
    ? parseInt(hierarchySettings?.col ?? "", 10)
    : undefined
  useEffect(() => {
    if (currentLevel === Level.second) {
      setValidatedCards({})
    }
  }, [currentLevel, setValidatedCards])

  const navigateToSort = useCallback(() => {
    navigation.navigate(homeRoutes.HOME_SORT, {
      path: `${_.replace(collectionRef?.path ?? "", `${userDocRef?.path}/h`, "")}`,
    })
  }, [collectionRef, userDocRef, navigation])

  const navigateToAdd = useCallback(
    () =>
      currentLevel === Level.first
        ? navigation.navigate(mainRoutes.ADD_PROCESS)
        : navigation.navigate(mainRoutes.ADD_CARD, { collectionPath: collectionRef?.path }),
    [currentLevel, collectionRef, navigation],
  )

  useEffect(() => {
    navigation.setOptions({
      ...(params?.cardPath && { title: "" }),
      ...(isWeb
        ? {
            title: currentLevel === Level.first ? t("home") : "",
            headerLeft: props =>
              currentLevel === Level.first ? null : (
                <HeaderBackButton
                  {...props}
                  onPress={() => {
                    navigation.replace(mainRoutes.MAIN, {
                      screen: tabsRoutes.HOME,
                      params: {
                        screen: homeRoutes.HOME_MAIN,
                        params:
                          currentLevel === Level.second
                            ? undefined
                            : {
                                cardPath: params?.cardPath?.split("/")?.[0] ?? undefined,
                              },
                      },
                    })
                  }}
                />
              ),
          }
        : {}),
      headerRight:
        userData && isAdminable(userData) && isEditable(userData)
          ? () => (
              <ActionBar
                icons={[
                  {
                    id: 1,
                    icon: (
                      <HeaderIcon
                        iconName="swap-vertical"
                        label={t("sortCards")}
                        onPress={navigateToSort}
                      />
                    ),
                  },
                  {
                    id: 2,
                    icon: (
                      <HeaderIcon
                        iconName="plus-box"
                        label={t("addProcess")}
                        onPress={navigateToAdd}
                      />
                    ),
                  },
                ]}
              />
            )
          : undefined,
    })
  }, [currentLevel, navigation, params, t, userData, navigateToSort, navigateToAdd])

  const onLicensePress = useCallback(async () => {
    if (userAuth?.category !== LicenseType.b2b) {
      await openUrl(urls.ADMIN)
    }
    RNExitApp.exitApp()
  }, [userAuth])

  const s = useMemo(
    () => ({
      button: [
        styles.button,
        {
          height: buttonSize,
          backgroundColor: white,
        },
      ],
      alertContent: [
        screenStyle,
        {
          marginTop: spacing + statusBarHeight,
        },
      ],
      alertView: [
        styles.alertView,
        {
          paddingHorizontal: spacing,
        },
      ],
      iconStyle: {
        marginRight: spacing,
      },
    }),
    [screenStyle, spacing, white, buttonSize],
  )

  useEffect(() => {
    if (userAuth?.license === "expired") {
      const isb2b = userAuth?.category === LicenseType.b2b
      // noinspection SpellCheckingInspection
      showDialog({
        type: "button",
        style: s.alertView,
        contentContainerStyle: s.alertContent,
        dismissable: false,
        title: t("expiredTitle"),
        titleStyle: styles.alertTitle,
        messageStyle: styles.modalSubtitle,
        message: t(isb2b ? "expiredB2b" : "expiredB2c"),
        positive: {
          onPress: onLicensePress,
          buttonStyle: s.button,
          iconStyle: s.iconStyle,
          type: ButtonType.NEUTRAL,
          icon: {
            size: ICON_MEDIUM_SIZE,
            color: primary,
            name: "check-circle",
            category: "MaterialCommunityIcons",
          },
          label: t(isb2b ? "closeApp" : "subscribe"),
        },
        negative: {
          onPress: logout,
          type: ButtonType.NEGATIVE,
          label: t("parameters.logout"),
        },
      })
    }
  }, [logout, onLicensePress, primary, s, showDialog, spacing, t, userAuth, white])

  // logout users who have not yet migrated to the new authentication
  useEffect(() => {
    if (userData && !userData.wpId) {
      // noinspection JSIgnoredPromiseFromCall
      logout()
    }
  }, [logout, userData])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onCardPress = useCallback(
    _.debounce(
      (card: ICardItemExt) => {
        const relativeCardPath = _.replace(card.ref?.path ?? "", `${userDocRef?.path}/h/`, "")
        if (currentLevel === Level.third) {
          navigation.navigate(mainRoutes.SENTENCE, {
            l3card: relativeCardPath,
            isValidation,
          })
        } else {
          navigation.push(homeRoutes.HOME_MAIN, { cardPath: relativeCardPath })
        }
      },
      600,
      { trailing: false, leading: true },
    ),
    [currentLevel, navigation, userDocRef, isValidation],
  )

  const onDeleteValidPress = useCallback(async () => {
    try {
      dispatch({ type: "loading_share" })
      await functions().httpsCallable("removeCard")({
        cardPath: _.replace(deletedCard?.path ?? "", `${userDocRef?.path}/h/`, ""),
      })
    } catch (e) {
      logger("card delete error", e)
      showSnack({
        message: t(`error.delete_card_failed`, {}),
      })
    } finally {
      dispatch({ type: "close" })
      setDeletedCard(undefined)
    }
  }, [dispatch, deletedCard?.path, userDocRef?.path, showSnack, t])

  const onDeleteButtonPress = useCallback(
    async (card: ICardItemExt) => {
      dispatch({ type: "delete" })
      if (!card.ref) {
        return
      }

      const c = (await card.ref.get())?.data() as FirCard
      setDeletedCard({ ...FirCardToCard(c, card.id), path: card.ref.path })
    },
    [dispatch],
  )

  const onCardButtonPress = useCallback(
    (card: ICardItemExt, button: CardButton) => {
      switch (button) {
        case "parameters":
          return navigation.navigate(mainRoutes.DISPLAY_HIERARCHY, {
            path: card.ref?.path,
          })
        case "edit":
          return navigation.navigate(homeRoutes.HOME_EDIT, { path: card.ref?.path, edit: true })
        case "delete":
          return onDeleteButtonPress(card)
        case "validate":
          return setValidatedCards(prevState => ({
            ...prevState,
            [card.id]: !prevState[card.id],
          }))
      }
    },
    [navigation, onDeleteButtonPress, setValidatedCards],
  )

  return (
    <>
      <CardsView
        collectionQuery={getOrderedCardsQuery(collectionRef)}
        {...{
          collectionRef,
          userDocRef,
          currentLevel,
          onCardButtonPress,
          onCardPress,
          validatedCards,
          isValidation,
          specificColumnNumber,
          hierarchySettingsLoading,
        }}
      />
      <ShareAlertDialog
        isHome={true}
        onValidPress={onShareValidPress}
        {...{ onCancelPress, alertState, dispatch }}
      />
      <ConfirmationModal
        visible={alertState.confirmVisible}
        dialogProps={{
          title: t("delete", { ns: currentLevel !== Level.third ? ns : galleryNs }),
          loading: alertState.loading,
          mode: Mode.delete,
          card: deletedCard,
          onClear: onCancelPress,
          onConfirm: onDeleteValidPress,
        }}
      />
    </>
  )
}

const styles = StyleSheet.create({
  button: {
    justifyContent: "center",
    borderWidth: 0,
    ...generateShadow(4),
  },
  alertView: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  alertTitle: {
    textAlign: "center",
  },
  modalSubtitle: {
    textAlign: "center",
  },
})

export default HomeScreen
