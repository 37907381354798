import { mainRoutes } from "../../../features/Navigation/Constants"

export const ns = mainRoutes.DISPLAY

export default {
  [ns]: {
    columns: "Nombre de colonnes par défaut dans les parcours et la galerie",
    columns_routeParameters: "Nombre de colonnes",
    columns_informations:
      "Le nombre de colonnes ne peut pas être modifié lorsque le bouton pour valider les étapes d'un parcours est activé",
    columns_freeSpace: `Nombre de colonnes dans "Communication Libre"`,
    validation: "Valider une séquence",
    validation_informations:
      "Cette option permet d'activer le bouton de validation des étapes d'un parcours",
    ariane: "Suivi des étapes",
    ariane_informations:
      "Activer cette option permet de visualiser les choix des cartes sélectionnées dans un parcours",
    fonts: "Police de caractère",
    standard: "Standard",
    dyslexic: "Dyslexique",
    comic: "Comic sans MS",
    share: "Partager le code du parcours",
  },
}
