import _ from "lodash"

import addCards from "../../screens/AddCardsScreen/i18n/fr"
import createCardMenu from "../../screens/CreateCardMenu/i18n/fr"
import createCard from "../../screens/CreateCardScreen/i18n/fr"
import createNote from "../../screens/CreateNoteScreen/i18n/fr"
import displayHierarchy from "../../screens/DisplayHierarchyScreen.tsx/i18n/fr"
import display from "../../screens/DisplayScreen/i18n/fr"
import draw from "../../screens/DrawScreen/i18n/fr"
import favorites from "../../screens/FavoritesScreen/i18n/fr"
import freeCommSpaceNav from "../../screens/FreeCommSpaceTabNavigator/i18n/fr"
import gallery from "../../screens/GalleryScreen/i18n/fr"
import home from "../../screens/HomeScreen/i18n/fr"
import login from "../../screens/LoginScreen/i18n/fr"
import main from "../../screens/MainScreen/i18n/fr"
import sentence from "../../screens/SentenceScreen/i18n/fr"
import settings from "../../screens/SettingsScreen/i18n/fr"
import sortCards from "../../screens/SortCardsScreen/i18n/fr"
import takePic from "../../screens/TakePicScreen/i18n/fr"
import textArea from "../../screens/TextAreaScreen/i18n/fr"
import navigation from "../Navigation/i18n/fr"

export default {
  fr: _.merge(
    {},
    createNote,
    main,
    draw,
    textArea,
    home,
    freeCommSpaceNav,
    takePic,
    navigation,
    settings,
    sentence,
    display,
    displayHierarchy,
    createCard,
    gallery,
    addCards,
    favorites,
    sortCards,
    login,
    createCardMenu,
    {
      common: { lang: "fr" },
    },
  ),
  en: _.merge(
    {},
    createNote,
    main,
    draw,
    textArea,
    home,
    freeCommSpaceNav,
    takePic,
    navigation,
    settings,
    sentence,
    display,
    displayHierarchy,
    createCard,
    gallery,
    addCards,
    favorites,
    sortCards,
    login,
    createCardMenu,
    {
      common: { lang: "fr" },
    },
  ),
}
