import {
  colors,
  generateShadow,
  IRowItemProps,
  ISectionHeader,
  LogoutItem,
  VectorIconProps,
} from "@siruplab/capsule"
import React from "react"
import { SectionListData, StyleSheet } from "react-native"

import { forcePerso, handleLoginOnPaips } from "../../features/models/UserDataFunctions"
import { mainRoutes } from "../../features/Navigation/Constants"
import AccountItem from "./AccountItem"
import { leftChild, rightChild } from "./ChildComponents"
import SettingsLinkItems from "./SettingsLinkItems"
import SettingsSwitchItem from "./SettingsSwitchItem"

const style: Partial<IRowItemProps> = {
  textStyle: { fontSize: 20 },
  fmOptions: { weight: "Bold" },
}

export const getPaipsUrl = (jwt: string): string =>
  `https://paips.fr/mon-compte?mo_jwt_token=${jwt}`

export const settingsItems: () => Array<SectionListData<IRowItemProps | ISectionHeader>> = () => [
  {
    disableShadow: true,
    data: [
      ...(forcePerso.current == null
        ? [
            {
              name: "mode",
              render: () => <SettingsSwitchItem />,
              backgroundColor: colors.black.transparent,
            },
            {
              ...style,
              rightChild: rightChild as VectorIconProps,
              name: "display",
              route: mainRoutes.DISPLAY,
              color: colors.black.highEmphasis,
              leftChild: { ...leftChild, name: "palette" } as VectorIconProps,
            },
            {
              ...style,
              rightChild: rightChild as VectorIconProps,
              onPress: handleLoginOnPaips,
              text: AccountItem,
              leftChild: { ...leftChild, name: "cog" } as VectorIconProps,
            },
          ]
        : []),
      {
        name: "",
        render: () => (
          <LogoutItem
            {...style}
            key="logout"
            rightChild={rightChild as VectorIconProps}
            buttonStyle={styles.button}
            backgroundColor={colors.surface.appUi}
            leftChild={{ ...leftChild, name: "power-standby" } as VectorIconProps}
          />
        ),
      },
      {
        name: "link",
        render: () => <SettingsLinkItems />,
      },
    ],
  },
]

const styles = StyleSheet.create({
  button: {
    borderWidth: 0,
    backgroundColor: colors.white.highEmphasis,
    ...generateShadow(4),
  },
})
