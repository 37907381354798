import { generateShadow, MaterialIcons } from "@siruplab/capsule"
import React, { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ImageBackground, ScrollView, StyleSheet, View } from "react-native"
import { Image as PickerImage } from "react-native-image-crop-picker"
import ViewShot from "react-native-view-shot"
import { ReactPainter } from "react-painter"

import { BottomDoubleButtons, ColorsList } from "../../components"
import { ICON_MEDIUM_SIZE } from "../../config/Constants"
import { maxWidthCardEditor } from "../../features/config/useTabland"
import { useTheme } from "../../utils/defaultStyle"
import { useShareDrawScreenLogic } from "../../utils/useShareDrawScreenLogic"
import { ns } from "./i18n/fr"

export type Source = PickerImage | { path: string } | undefined

const DrawScreen: FC = () => {
  const {
    setDisabled,
    viewShotRef,
    source,
    setSource,
    onSave,
    disabled,
    selectedColor,
    drawRefWeb,
    updated,
    onClearWeb: onClear,
    changeColorWeb: changeColor,
    maxSizeWeb: maxSize,
  } = useShareDrawScreenLogic()

  const [t] = useTranslation(ns)
  const {
    colors: {
      white: { highEmphasis: white },
      error,
      primary,
      black: { disabled: disabledBlack },
    },
    dimensions: { spacing, borderRadius },
    screenStyle,
  } = useTheme()

  const s = useMemo(
    () => ({
      contentContainer: [
        screenStyle,
        styles.contentContainer,
        {
          padding: spacing,
        },
      ],
      viewShot: {
        flex: 1,
        width: maxSize,
        height: maxSize,
        aspectRatio: 1,
      },
      canvas: [
        styles.canvas,
        {
          borderRadius,
          backgroundColor: white,
          maxWidth: maxWidthCardEditor,
        },
      ],
    }),
    [screenStyle, spacing, maxSize, borderRadius, white],
  )

  const saveIcon = useCallback(
    () => (
      <MaterialIcons
        name="add-circle-outline"
        size={ICON_MEDIUM_SIZE}
        color={!disabled ? primary : disabledBlack}
      />
    ),
    [disabledBlack, primary, disabled],
  )

  const clearIcon = useCallback(
    () => (
      <MaterialIcons
        name="format-color-reset"
        size={ICON_MEDIUM_SIZE}
        color={disabled ? disabledBlack : error}
      />
    ),
    [disabledBlack, error, disabled],
  )

  return (
    <ScrollView contentContainerStyle={s.contentContainer}>
      <View>
        <ViewShot style={s.viewShot} ref={viewShotRef}>
          <View style={s.canvas}>
            <ReactPainter
              render={({ getCanvasProps }) => (
                <ImageBackground source={{ uri: source?.path }} style={styles.image}>
                  <canvas {...getCanvasProps({})} onClick={() => setDisabled(false)} />
                </ImageBackground>
              )}
              key={updated}
              ref={drawRefWeb}
              width={maxSize}
              height={maxSize}
            />
          </View>
        </ViewShot>
      </View>
      <ColorsList
        drawRef={drawRefWeb}
        source={source}
        setSource={setSource}
        selectedColor={selectedColor}
        onSelectColor={changeColor}
      />
      <BottomDoubleButtons
        clearIcon={clearIcon}
        saveText={t("save")}
        clearText={t("erase")}
        saveIcon={saveIcon}
        {...{ onSave, onClear, disabled }}
      />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  viewShot: {
    flex: 1,
    aspectRatio: 1,
  },
  canvas: {
    ...generateShadow(4),
  },
  image: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
  },
})

export default DrawScreen
