import { ParamListBase } from "@react-navigation/routers"
import { createStackNavigator } from "@react-navigation/stack"
import { StackHeaderOptions } from "@react-navigation/stack/lib/typescript/src/types"
import {
  AlertProvider,
  auth,
  Body2,
  H6,
  IUserContext,
  logger,
  ThemeProvider,
  useAlert,
  userContext,
  useTheme,
  WelcomeButton,
  WelcomeScreen,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Linking, View } from "react-native"
import RNBootSplash from "react-native-bootsplash"
import { ActivityIndicator } from "react-native-paper"

import images from "../../assets/images"
import { ICON_MEDIUM_SIZE } from "../../config/Constants"
import AddCardsScreen from "../../screens/AddCardsScreen/AddCardsScreen"
import DisplayHierarchyScreen from "../../screens/DisplayHierarchyScreen.tsx/DisplayHierarchyScreen"
import DisplayScreen from "../../screens/DisplayScreen/DisplayScreen"
import GalleryScreen from "../../screens/GalleryScreen/GalleryScreen"
import LoginScreen from "../../screens/LoginScreen/LoginScreen"
import { ns } from "../../screens/MainScreen/i18n/fr"
import MainNavigator from "../../screens/MainScreen/MainNavigator"
import SentenceScreen from "../../screens/SentenceScreen/SentenceScreen"
import SignupScreen from "../../screens/SignupScreen/SignupScreen"
import SortCardScreen from "../../screens/SortCardsScreen/SortCardScreen"
import isWeb from "../../utils/isWeb"
import { handleTokens } from "../../utils/jwt"
import { OptimisticProvider } from "../../utils/optimisticProvider"
import { UserData } from "../models/UserData"
import { mainRoutes } from "./Constants"

export interface IRootParamList extends ParamListBase {
  [mainRoutes.DISPLAY_NO_SETTINGS]: undefined
  [mainRoutes.DISPLAY]: { edit?: boolean; path: string }
  [mainRoutes.DISPLAY_HIERARCHY]: { path: string }
  [mainRoutes.SENTENCE]: {
    l3card?: string
    isValidation: boolean
    singleCardId: undefined
    fromFav: false
  }
  [mainRoutes.ADD_CARD]: { collectionPath?: string }
}

const BOTTOM_SIZE = 56

const rootNavigator = createStackNavigator<IRootParamList>()

const RootNavigator: FC<{ shouldDisplayLogoutToast: boolean }> = ({ shouldDisplayLogoutToast }) => {
  const { showSnack } = useAlert()
  const {
    colors: {
      black,
      primary,
      white,
      surface: { appUi },
    },
    typography: { h6 },
    dimensions: { spacing },
  } = useTheme()
  const { user, userData, initializing, loading, userDataLoading } = useContext<
    IUserContext<UserData>
  >(userContext)
  const { t } = useTranslation(ns)

  const [isWebLogin, setIsWebLogin] = useState(false)

  const commonHeaderProps: StackHeaderOptions = useMemo(
    () => ({
      headerBackTitleStyle: {
        color: white.highEmphasis,
      },
      headerBackTitleVisible: false,
      headerTintColor: white.highEmphasis,
      headerStyle: { backgroundColor: primary },
      headerBackAccessibilityLabel: t("headers.back"),
      headerTitleStyle: { ...h6, color: white.highEmphasis },
    }),
    [white, primary, t, h6],
  )

  const onSubmitWelcome = useCallback(
    async (button: WelcomeButton, navAction: (key: string) => void) => {
      if (button === "signUp") {
        navAction(mainRoutes.LOGIN)
      } else {
        // noinspection ES6MissingAwait
        navAction(mainRoutes.SIGNUP)
      }
    },
    [],
  )

  const s = useMemo(
    () => ({
      image: {
        marginTop: spacing * 3,
        marginBottom: spacing,
      },
    }),
    [spacing],
  )

  useEffect(() => {
    if (!initializing && !userDataLoading) {
      setTimeout(() => {
        RNBootSplash.hide({ fade: true })
      }, 100)
    }
  }, [initializing, userDataLoading])

  useEffect(() => {
    if (shouldDisplayLogoutToast) {
      showSnack({
        message: t("userMustLogout"),
        duration: 5000,
      })
    }
  }, [showSnack, shouldDisplayLogoutToast, t])

  useEffect(() => {
    if (isWeb) {
      ;(async () => {
        try {
          setIsWebLogin(true)
          // @ts-expect-error: IMPORTANT ! this is a web-specific function
          auth().setPersistence("none")

          const url = await Linking.getInitialURL()

          if (!url) {
            return null
          }

          const urlObj = new URL(url)

          return await handleTokens(urlObj)
        } catch (e) {
          logger("error", e)
        } finally {
          setIsWebLogin(false)
        }
      })()
    }
  }, [])

  const navSwitcher = useMemo(() => {
    if (!user || loading || userDataLoading) {
      return (
        <>
          <rootNavigator.Screen
            name={mainRoutes.WELCOME}
            options={{
              headerShown: false,
              title: t(`${mainRoutes.WELCOME}:title`),
            }}
          >
            {({ navigation }) => (
              <ThemeProvider
                customTheme={{
                  colors: {
                    overrides: { button: "", ripple: "" },
                    surface: { appUi },
                  },
                }}
              >
                <WelcomeScreen
                  buttonStyles={[
                    {
                      icon: {
                        name: "logout",
                        category: "MaterialCommunityIcons",
                        size: ICON_MEDIUM_SIZE,
                        color: white.highEmphasis,
                      },
                      color: white.highEmphasis,
                    },
                    { mode: "text", color: black.mediumEmphasis },
                  ]}
                  pages={_.map(_.range(0, 4), i => ({
                    image: images.welcome[i],
                    // @ts-ignore
                    ...t(`${mainRoutes.WELCOME}:welcome.${i}`, { returnObjects: true }),
                  }))}
                  // TODO: Find a better option to auto pass the first argument only
                  onSubmit={(button: WelcomeButton) => onSubmitWelcome(button, navigation.navigate)}
                  TitleComponent={H6}
                  DescriptionComponent={Body2}
                  imageStyle={s.image}
                  hideStatusBar={false}
                />
              </ThemeProvider>
            )}
          </rootNavigator.Screen>
          <rootNavigator.Screen
            name={mainRoutes.LOGIN}
            component={LoginScreen}
            options={{
              ...commonHeaderProps,
              title: t(`${mainRoutes.LOGIN}:title`),
            }}
          />
          <rootNavigator.Screen
            name={mainRoutes.SIGNUP}
            component={SignupScreen}
            options={{
              ...commonHeaderProps,
              title: t("common:landing.signIn"),
            }}
          />
        </>
      )
    }
    if (!userData?.theme) {
      return (
        <rootNavigator.Screen
          name={mainRoutes.DISPLAY_NO_SETTINGS}
          component={DisplayScreen}
          options={{
            ...commonHeaderProps,
            title: t(`${mainRoutes.DISPLAY}:title`),
          }}
        />
      )
    }
    return (
      <>
        <rootNavigator.Screen name={mainRoutes.MAIN} options={{ headerShown: false }}>
          {() => (
            <OptimisticProvider ms={200}>
              <AlertProvider hasBottomNav={true} bottomHeight={BOTTOM_SIZE}>
                <MainNavigator />
              </AlertProvider>
            </OptimisticProvider>
          )}
        </rootNavigator.Screen>
        <rootNavigator.Screen
          name={mainRoutes.HOME_SORT}
          component={SortCardScreen}
          options={{
            ...commonHeaderProps,
            title: t("headers.sortCards"),
          }}
        />
        <rootNavigator.Screen
          name={mainRoutes.DISPLAY}
          component={DisplayScreen}
          initialParams={{ edit: true }}
          options={{
            ...commonHeaderProps,
            title: t(`${mainRoutes.DISPLAY}:title`),
          }}
        />
        <rootNavigator.Screen
          name={mainRoutes.DISPLAY_HIERARCHY}
          component={DisplayHierarchyScreen}
          options={{
            ...commonHeaderProps,
            title: t(`${mainRoutes.DISPLAY_HIERARCHY}:title`),
          }}
        />
        <rootNavigator.Screen
          name={mainRoutes.SENTENCE}
          component={SentenceScreen}
          options={{ ...commonHeaderProps, title: "" }}
        />
        <rootNavigator.Screen
          name={mainRoutes.ADD_CARD}
          component={GalleryScreen}
          options={{
            ...commonHeaderProps,
            title: t(`${mainRoutes.ADD_CARD}:title`),
          }}
        />
        <rootNavigator.Screen
          name={mainRoutes.ADD_PROCESS}
          component={AddCardsScreen}
          options={{
            ...commonHeaderProps,
            title: t(`${mainRoutes.ADD_PROCESS}:title`),
          }}
        />
      </>
    )
  }, [
    s,
    t,
    user,
    appUi,
    white,
    black,
    userDataLoading,
    loading,
    userData,
    onSubmitWelcome,
    commonHeaderProps,
  ])

  return initializing || userDataLoading || isWebLogin ? (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <ActivityIndicator />
    </View>
  ) : (
    <rootNavigator.Navigator
      screenOptions={{
        headerTitleAlign: "center",
        headerStyle: {
          backgroundColor: primary,
        },
      }}
    >
      {navSwitcher}
    </rootNavigator.Navigator>
  )
}

export default RootNavigator
