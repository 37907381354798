export const ns = "HomeScreen"

export default {
  [ns]: {
    expiredTitle: "Votre licence est expirée",
    expiredB2c: "Abonnez-vous pour continuer à utiliser l'application.",
    expiredB2b: "Contactez votre référent pour la réactiver.",
    subscribe: "Souscrire",
    sortCards: "Trier les cartes",
    addProcess: "Ajouter un parcours",
    closeApp: "Fermer l’application",
    copy: "Copier",
    loading: "Chargement…",
    delete: "Cette image et celles rattachées seront supprimées",
    error: {
      copy_failed: "Impossible de copier le code",
      create: "Une erreur est survenue à la création",
      delete_card_failed: "Une erreur est survenue à la suppression",
    },
    home: "Accueil",
    dialog: {
      placeholder: "Entrez votre code",
    },
    parameters: {
      logout: "Déconnexion",
    },
  },
}
