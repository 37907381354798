import Clipboard from "@react-native-clipboard/clipboard"
import { RouteProp } from "@react-navigation/native"
import {
  Body2,
  Button,
  firebase,
  functions,
  H6,
  logger,
  OptimisticSwitch,
  SingleToggleGroup,
  useCheckConnected,
  VectorIcon,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { FC, useCallback, useEffect, useState } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { ScrollView, View } from "react-native"
import ShortUniqueId from "short-unique-id"

import { OnCheckExistingShareParams } from "../../common/types"
import { ShareAlertDialog } from "../../components"
import { columns, dictionary } from "../../config/Constants"
import { FirCardWithId } from "../../features/models/Card"
import { mainRoutes } from "../../features/Navigation/Constants"
import { IRootParamList } from "../../features/Navigation/RootNavigator"
import { getRelativeCardPath } from "../../utils/getRelativeCardPath"
import { useAlertdialog } from "../../utils/useAlertDialog"
import { useShareDisplayScreenLogic } from "../../utils/useShareDisplayScreenLogic"
import { ns } from "./i18n/fr"

type DisplayHierarchyScreenRouteProp = RouteProp<
  IRootParamList,
  typeof mainRoutes.DISPLAY_HIERARCHY
>

interface IProps {
  route: DisplayHierarchyScreenRouteProp
}

const DisplayHierarchyScreen: FC<IProps> = ({ route }) => {
  const { onShareValidPress, onCancelPress, alertState, dispatch } = useAlertdialog()
  const {
    s,
    navigation,
    styles,
    primary,
    secondary,
    accent3,
    userDocRef,
  } = useShareDisplayScreenLogic()
  const checkConnected = useCheckConnected()
  const relativePath = getRelativeCardPath(route, "h", userDocRef)
  const cardPath = route.params?.path
  const { t } = useTranslation(ns)

  const renderShareIcon = useCallback(
    () => <VectorIcon size={32} color={primary} name="share-square-o" category="FontAwesome" />,
    [primary],
  )
  const renderIcon = useCallback(
    () => (
      <VectorIcon size={26} color={primary} name="check-circle" category="MaterialCommunityIcons" />
    ),
    [primary],
  )

  const [validation, setValidation] = useState<boolean>(false)

  const columnsWithDefault = [...columns, { label: "Défaut", value: "default" }]
  const columnsDisabled = columnsWithDefault.map(column => ({ ...column, disabled: true }))
  const [currentHierarchyColumn, setCurrentHierarchyColumn] = useState<string>("default")
  const [currentCard] = useDocumentData<FirCardWithId>(
    userDocRef?.collection("h").doc(relativePath),
  )

  useEffect(() => {
    if (currentCard) {
      const currentCardColumn = currentCard.col
      const currentCardValidation = currentCard.v

      if (currentCardColumn) {
        setCurrentHierarchyColumn(currentCardColumn)
      }
      if (currentCardValidation) {
        setValidation(currentCardValidation)
      }
    }
  }, [userDocRef, relativePath, currentCard, currentCard?.v, currentCard?.col])

  const onShareButtonPress = useCallback(async () => {
    try {
      dispatch({
        type: "loading_copy",
      })
      const shareParams: OnCheckExistingShareParams = {
        path: cardPath,
        dev: __DEV__,
      }
      const existingCode: string = (
        await functions().httpsCallable("checkExistingShare")(shareParams)
      ).data
      const code = existingCode ?? new ShortUniqueId({ dictionary, length: 6 }).randomUUID()
      Clipboard.setString(code)
      dispatch({
        type: "text",
        data: { code, path: cardPath, exist: !_.isNil(existingCode) },
      })
    } catch (e) {
      logger("check share code", e)
    }
  }, [cardPath, dispatch])

  const onValidate = useCallback(() => {
    userDocRef
      ?.collection("h")
      .doc(relativePath)
      .set(
        {
          v: validation,
          col:
            currentHierarchyColumn === "default" || validation
              ? firebase.firestore.FieldValue.delete()
              : currentHierarchyColumn,
        },
        { merge: true },
      )

    navigation.goBack()
  }, [validation, currentHierarchyColumn, navigation, userDocRef, relativePath])

  return (
    <ScrollView style={s.container} contentContainerStyle={s.contentContainer}>
      <ShareAlertDialog
        isHome={true}
        onValidPress={onShareValidPress}
        {...{ onCancelPress, alertState, dispatch }}
      />
      <View style={styles.innerView}>
        <Button
          style={[s.button, styles.largeButton]}
          icon={renderShareIcon}
          onPress={() => checkConnected(() => onShareButtonPress())}
          contentStyle={s.contentButton}
        >
          {t("share")}
        </Button>
        <View style={styles.switchView}>
          <H6 style={s.title}>{t("validation")}</H6>
          <OptimisticSwitch
            {...s.switch}
            thumbColor={validation ? secondary : primary}
            value={validation}
            onValueChange={() => setValidation(prev => !prev)}
          />
        </View>
        <Body2>{t("validation_informations")}</Body2>
        <H6 style={s.title}>{t("columns_routeParameters")}</H6>
        {validation ? <Body2 style={s.toggleGroup}>{t("columns_informations")}</Body2> : null}
        <SingleToggleGroup
          ref={null}
          /* @ts-ignore - There is no icon for default choice */
          items={validation ? columnsDisabled : columnsWithDefault}
          numColumns={columnsWithDefault?.length}
          value={validation ? "" : currentHierarchyColumn}
          selectedColor={accent3}
          rowStyle={s.toggleGroup}
          contentStyle={styles.contentToggleGroup}
          onValueChange={setCurrentHierarchyColumn}
        />
      </View>
      <Button
        style={s.button}
        icon={renderIcon}
        onPress={onValidate}
        contentStyle={s.contentButton}
      >
        {t("common:button.validate")}
      </Button>
    </ScrollView>
  )
}

export default DisplayHierarchyScreen
