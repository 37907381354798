import { maximumImageSize } from "../config/Constants"

export const cropImage = async (
  base64String: string,
  validateIfSmaller: boolean,
  targetWidth = maximumImageSize,
  targetHeight = maximumImageSize,
): Promise<string> => {
  const img = new Image()
  img.src = base64String

  const canvas = document.createElement("canvas")
  canvas.width = targetWidth
  canvas.height = targetHeight
  const context = canvas.getContext("2d")

  if (!context) {
    return ""
  }

  return new Promise((resolve, reject) => {
    img.onload = () => {
      const { width: originalWidth, height: originalHeight } = img

      if (validateIfSmaller && originalWidth <= targetWidth && originalHeight <= targetHeight) {
        return resolve(img.src)
      }

      const scaleWidth = targetWidth / originalWidth
      const scaleHeight = targetHeight / originalHeight
      const scale = Math.max(scaleWidth, scaleHeight)

      const newWidth = originalWidth * scale
      const newHeight = originalHeight * scale

      const xOffset = (targetWidth - newWidth) / 2
      const yOffset = (targetHeight - newHeight) / 2

      context.drawImage(img, xOffset, yOffset, newWidth, newHeight)

      return resolve(canvas.toDataURL())
    }
    img.onerror = () => reject(new Error("error.image.crop"))
  })
}

export const blobToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.readAsDataURL(blob)
    reader.onerror = () => reject(new Error("error.image.load"))
  })
