import { FirebaseFirestoreTypes, storage } from "@siruplab/capsule"
import { Dispatch, RefObject, SetStateAction, useCallback } from "react"
import { ReactCropperElement } from "react-cropper"
import ShortUniqueId from "short-unique-id"

import { maximumImageSize, textColors } from "../config/Constants"
import { extractFilename } from "./extractFilename"
import { blobToBase64 } from "./images"
import useProcess from "./useProcess"

export const loadImage = async (image: ImageBase64) =>
  new Promise<Required<ImageBase64>>((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve({ ...image, width: img.width, height: img.height })
    img.onerror = reject
    img.src = image.uri
  })

export const needsCrop = (image: Required<ImageBase64>): boolean => {
  const isSquare = image.width === image.height
  const isTooLarge = image.width >= maximumImageSize || image.height >= maximumImageSize
  return !isSquare || isTooLarge
}

interface ImageBase64 {
  name: string
  type: string
  uri: string
  width?: number
  height?: number
}

interface UseCropWebLogicProps {
  setVisible: Dispatch<SetStateAction<boolean>>
  setLoading: Dispatch<SetStateAction<boolean>>
  userDocRef: FirebaseFirestoreTypes.DocumentReference<FirebaseFirestoreTypes.DocumentData> | null
  cropperRef: RefObject<ReactCropperElement>
  collection: string
}

const useCropWebLogic = ({
  setVisible,
  setLoading,
  userDocRef,
  cropperRef,
  collection,
}: UseCropWebLogicProps) => {
  const {
    process,
    onCompleteProcess,
    state,
    onCancelProcess,
    onErrorProcess,
  } = useProcess<string>()

  const onValidateCrop = useCallback(async () => {
    if (!cropperRef.current) {
      return
    }
    try {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas({
        width: maximumImageSize,
        height: maximumImageSize,
      })

      const formatMatch = state.payload?.match(/^data:image\/([a-zA-Z+]+);base64,/)
      const format = formatMatch?.[1] ?? "png"

      onCompleteProcess(croppedCanvas?.toDataURL(`image/${format}`))
    } catch (error) {
      onErrorProcess()
    }
  }, [cropperRef, onCompleteProcess, state.payload, onErrorProcess])

  const cropImage = useCallback(
    async (image: Required<ImageBase64>): Promise<string> => process(image.uri),
    [process],
  )

  const uploadImage = useCallback(
    async (imageWithBase64: Required<ImageBase64>, order: number): Promise<void> => {
      setVisible(true)
      setLoading(true)

      const uid = new ShortUniqueId()
      const imageRef = storage().ref(`${userDocRef?.id}/${uid()}`)
      await imageRef.putString(imageWithBase64.uri, "data_url")
      const getDownloadURL = await imageRef.getDownloadURL()

      await userDocRef?.collection(collection).add({
        t: imageWithBase64.name,
        c: textColors.white,
        u: getDownloadURL,
        o: order,
      })

      setLoading(false)
      setVisible(false)
    },
    [collection, userDocRef, setLoading, setVisible],
  )
  const processAndUploadEachImage = useCallback(
    async (file: File, order: number): Promise<void> => {
      const fileName = extractFilename(file)
      const image = await loadImage({
        name: fileName,
        type: file.type,
        uri: await blobToBase64(file),
      })

      if (needsCrop(image)) {
        image.uri = await cropImage(image)
      }

      await uploadImage(image, order)
    },
    [cropImage, uploadImage],
  )

  return {
    loadImage,
    needsCrop,
    cropImage,
    processAndUploadEachImage,
    onValidateCrop,
    state,
    onCancelProcess,
  }
}

export default useCropWebLogic
