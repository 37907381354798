import { functions, useAlert } from "@siruplab/capsule"
import { useCallback, useReducer } from "react"
import { useTranslation } from "react-i18next"

import { OnCreateShareParams } from "../common/types"
import { alertStateReducer, defaultValues } from "../screens/AddCardsScreen/Constants"
import { ns } from "../screens/HomeScreen/i18n/fr"

export const useAlertdialog = () => {
  const { showSnack } = useAlert()
  const { t } = useTranslation(ns)

  const [alertState, dispatch] = useReducer(alertStateReducer, defaultValues)

  const onShareValidPress = useCallback(async () => {
    try {
      if (alertState.shareData && !alertState.shareData?.exist) {
        const createShareParams: OnCreateShareParams = {
          dev: __DEV__,
          data: alertState.shareData,
        }
        await functions().httpsCallable("createShare")(createShareParams)
      }
    } catch (e) {
      showSnack({ message: t(`error.${e.message}`) })
    } finally {
      dispatch({ type: "close" })
    }
  }, [alertState.shareData, showSnack, t])

  const onCancelPress = async () => {
    dispatch({ type: "close" })
  }

  return {
    onShareValidPress,
    onCancelPress,
    alertState,
    dispatch,
  }
}
