import { delay } from "@siruplab/capsule"
import { useCallback, useRef } from "react"

export const useAutoCallback = (handler: (value?: any) => void, count = 1500) => {
  const onPressRef = useRef<(value?: any) => void>()

  const onPress = useCallback(() => onPressRef?.current?.(), [])

  const start = useCallback(async () => {
    const handlerPromise = new Promise(resolve => (onPressRef.current = resolve))
    await Promise.race([delay(count), handlerPromise])
    handler()
  }, [count, handler])

  return { onPress, start }
}
