import { defaultWeights, IChoice } from "@siruplab/capsule"
import { Options } from "react-native-image-crop-picker"

import { RadioFont } from "../features/models/RadioFont"
import { Font } from "../features/models/UserData"
import isWeb from "../utils/isWeb"

export const MAX_TIME = isWeb ? 15 : 10
export const ICON_SIZE = 24
export const ICON_MEDIUM_SIZE = 32
export const NOTCH_SIZE = 48
export const TAB_BAR_SIZE_TAB_LAND = 80

export const urls = {
  ADMIN: "https://paips.fr/mon-compte/",
  SIGN_UP: "https://paips.fr/comnous/tarifs-particuliers/",
}

export const radioFonts: RadioFont[] = [
  { id: Font.normal, label: "standard", fontFamily: "Roboto" },
  { id: Font.dys, label: "dyslexic", fontFamily: "OpenDyslexic" },
  { id: Font.comic, label: "comic", fontFamily: "ComicSansMS" },
]

export const ComicSansMS = {
  family: "ComicSansMS",
  weights: defaultWeights,
  iosFamily: "ComicSansMS",
}
export const OpenDyslexic = {
  family: "OpenDyslexic",
  weights: defaultWeights,
  iosFamily: "OpenDyslexic",
}
export const Roboto = {
  family: "Roboto",
  weights: defaultWeights,
  iosFamily: "Roboto",
}

export const columns: IChoice[] = [
  {
    value: "3",
    icon: require("../assets/images/three_columns.png"),
  },
  {
    value: "2",
    icon: require("../assets/images/two_columns.png"),
  },
  {
    value: "1",
    icon: require("../assets/images/one_column.png"),
  },
]

export const maximumImageSize = 1024

export const imageOptions: Options = {
  width: 1024,
  height: 1024,
  cropping: true,
  mediaType: "photo",
} as const

export const dictionary = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "J",
  "K",
  "L",
  "M",
  "N",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
]

export const switchColor = {
  active: "rgba(253, 157, 39, 0.38)",
  inactive: "rgba(95, 72, 185, 0.38)",
}

export const drawColors = {
  black: "#000",
  red: "#EE0202",
  blue: "#00D1FF",
  yellow: "#FFF500",
  green: "#5FD855",
}

export const textColors = {
  white: "#FFF",
  red: "#F7BAC2",
  orange: "#FDD9C3",
  blue: "#A6DFFF",
  yellow: "#FFF500",
  green: "#B8FFA6",
}

export const iconSize = {
  common: 24,
  regular: 40,
}
