import { DrawRef } from "@benjeau/react-native-draw"
import { useTheme } from "@siruplab/capsule"
import _ from "lodash"
import React, { Dispatch, FC, RefObject, useCallback, useMemo } from "react"
import { FlatList, ListRenderItem, StyleSheet, ViewStyle } from "react-native"
import { Image } from "react-native-image-crop-picker"
import ReactPainter from "react-painter"

import { drawColors, textColors } from "../config/Constants"
import { Source } from "../screens/DrawScreen/DrawScreen.web"
import CameraButton from "./CameraButton"
import ColorButton from "./ColorButton"

export interface IData {
  name: string
  option: string
}

export const mapDrawOptions = [
  ..._.map<Record<string, string>, IData>(drawColors, (value, key) => ({
    name: key,
    option: value,
  })),
  { name: "camera", option: "camera" },
]

export const mapTextColors = _.map<Record<string, string>, IData>(textColors, (value, key) => ({
  name: key,
  option: value,
}))

interface IProps {
  isText?: boolean
  selectedColor: string
  onSelectColor: (color: string) => void
  setSource?: Dispatch<React.SetStateAction<Image | undefined>>
  source?: Source
  drawRef?: RefObject<ReactPainter | DrawRef>
  isFromTakePicScreen?: boolean
  onClearWeb?: () => void
}

const keyExtractor = (item: IData) => item.name

export const ColorsList: FC<IProps> = ({
  source,
  setSource,
  onSelectColor,
  selectedColor,
  isText,
  drawRef,
  onClearWeb,
  isFromTakePicScreen = false,
}) => {
  const {
    dimensions: { spacing },
  } = useTheme()
  const s = useMemo(
    () => ({
      flatList: {
        paddingTop: spacing,
        paddingBottom: spacing / 2,
        display: (isFromTakePicScreen && !source ? "none" : "flex") as ViewStyle["display"],
      },
    }),
    [spacing, source, isFromTakePicScreen],
  )
  const renderItem: ListRenderItem<IData> = useCallback(
    ({ item: { option } }) =>
      drawRef && option === "camera" ? (
        <CameraButton
          drawRef={drawRef}
          isSelected={selectedColor === option}
          source={source}
          setSource={setSource}
          isFromTakePicScreen={isFromTakePicScreen}
          onClearWeb={onClearWeb}
        />
      ) : (
        <ColorButton
          color={option}
          onPress={onSelectColor}
          isSelected={selectedColor === "option"}
        />
      ),
    [drawRef, isFromTakePicScreen, onClearWeb, onSelectColor, selectedColor, setSource, source],
  )

  return (
    <FlatList
      horizontal
      style={s.flatList}
      scrollEnabled={false}
      contentContainerStyle={styles.contentContainer}
      {...{ keyExtractor, renderItem, data: isText ? mapTextColors : mapDrawOptions }}
    />
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
})
