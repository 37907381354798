export const ns = "displayHierarchy"

export default {
  [ns]: {
    columns_routeParameters: "Nombre de colonnes",
    columns_informations:
      "Le nombre de colonnes ne peut pas être modifié lorsque le bouton pour valider les étapes d'un parcours est activé",
    validation: "Valider une séquence",
    validation_informations:
      "Cette option permet d'activer le bouton de validation des étapes d'un parcours",
    share: "Partager le code du parcours",
  },
}
