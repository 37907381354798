import { RouteProp } from "@react-navigation/core"
import { useNavigation } from "@react-navigation/native"
import { CommonActions } from "@react-navigation/routers"
import {
  delay,
  logger,
  storage,
  useAlert,
  useCallbackConnected,
  userContext,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { KeyboardAvoidingView, Platform, ScrollView, StyleSheet } from "react-native"
import { Image } from "react-native-image-crop-picker"
import ShortUniqueId from "short-unique-id"

import { BottomDoubleButtonsMaterial, ColorsList, ConfirmationModal, Mode } from "../../components"
import { textColors } from "../../config/Constants"
import { FirCardWithId } from "../../features/models/Card"
import { getUserCollectionMinOrder } from "../../features/models/UserDataFunctions"
import { tabsRoutes } from "../../features/Navigation/Constants"
import { usePicture } from "../../features/Providers/PictureProvider"
import isWeb from "../../utils/isWeb"
import { useAutoCallback } from "../../utils/useAutoCallback"
import {
  ICreateParamList,
  IFreeSpaceFavParamList,
  IGalleryParamList,
  IHomeParamList,
} from "../MainScreen/MainTabs"
import CreateCard from "./CreateCard"
import { ns } from "./i18n/fr"

type HomeCardScreenRouteProp = RouteProp<IHomeParamList, "Home_Edit">
type CreateCardScreenRouteProp = RouteProp<ICreateParamList, "CreateCard_Main">
type GalleryCardScreenRouteProp = RouteProp<IGalleryParamList, "Gallery_Edit">
type FreeSpaceFavGalRouteProp = RouteProp<IFreeSpaceFavParamList, "FreeSpaceFav_GalleryEdit">

interface IProps {
  route:
    | CreateCardScreenRouteProp
    | HomeCardScreenRouteProp
    | GalleryCardScreenRouteProp
    | FreeSpaceFavGalRouteProp
}

const CreateCardScreen: FC<IProps> = ({ route }) => {
  // Contexts
  const {
    dimensions: { spacing },
    colors: { overrides },
    screenStyle,
  } = useTheme()
  const { t } = useTranslation(ns)
  const navigation = useNavigation()
  const { userDocRef } = useContext(userContext)
  const { showSnack } = useAlert()
  const routeType = useMemo(
    () => ({
      isFreeSpace: !!(route as FreeSpaceFavGalRouteProp)?.params?.freeSpace,
      isHome: !!(route as HomeCardScreenRouteProp)?.params?.path,
      isGallery: !!(route as GalleryCardScreenRouteProp)?.params?.gallery,
      isCreate: !!(route as CreateCardScreenRouteProp)?.params?.isCreate,
    }),
    [route],
  )

  const isEdit = useMemo(
    () => routeType.isHome || ((routeType.isGallery || routeType.isFreeSpace) && route.params.edit),
    [route, routeType],
  )

  // Firestore fields
  const collection = routeType.isFreeSpace
    ? "c"
    : routeType.isGallery || routeType.isCreate
    ? "g"
    : "h"
  const relativeCardPath = isEdit
    ? _.replace(
        (route as HomeCardScreenRouteProp).params.path ?? "",
        `${userDocRef?.path}/${collection}/`,
        "",
      )
    : undefined

  const [card] = useDocumentData<FirCardWithId>(
    relativeCardPath ? userDocRef?.collection(collection).doc(relativeCardPath) : null,
    {
      idField: "id",
    },
  )

  // Local states
  const [text, setText] = useState("")
  const [source, setSource] = useState<Image | undefined>(undefined)

  const [selectedColor, setSelectedColor] = useState(textColors.white)
  const [hasChanged, setHasChanged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  const { picture } = usePicture()

  const imageUri =
    isEdit || routeType.isGallery || routeType.isFreeSpace
      ? source?.path ?? card?.u ?? ""
      : !routeType.isGallery
      ? picture ?? ""
      : ""

  const s = useMemo(
    () => ({
      view: [screenStyle, styles.view],
      contentContainer: [
        styles.contentContainer,
        {
          padding: spacing,
        },
      ],
    }),
    [screenStyle, spacing],
  )

  // determine if new or older image and create a storage ref
  const getImagePath = useCallback(async () => {
    if (source || routeType.isCreate) {
      const uid = new ShortUniqueId()
      const imageRef = storage().ref(`${userDocRef?.id}/${uid()}`)
      const filePath = routeType.isCreate ? imageUri : source?.path
      if (!filePath) {
        return
      }
      if (isWeb) {
        await imageRef.putString(filePath, "data_url")
      } else {
        await imageRef.putFile(filePath)
      }
      return imageRef.getDownloadURL()
    }
    return card?.u
  }, [source, routeType, card, userDocRef, imageUri])

  const setChange = (fn: Dispatch<SetStateAction<any>>) => (arg: any) => {
    setHasChanged(true)
    fn(arg)
  }

  // init component with existing data if edit mode
  useEffect(() => {
    if (!isEdit || !card) {
      return
    }
    setText(card?.t)
    setSelectedColor(card?.c)
  }, [card, isEdit, overrides, route])

  const onClear = useCallback(() => {
    navigation.goBack()
  }, [navigation])

  const onConfirm = useCallback(async () => {
    await delay(200)
    if (routeType.isCreate) {
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: tabsRoutes.CREATE }],
        }),
      )
    } else if (routeType.isGallery && !route.params.edit) {
      setText("")
      setSource(undefined)
      setSelectedColor(textColors.white)
      return
    }
    navigation.goBack()
  }, [routeType.isCreate, routeType.isGallery, route.params.edit, navigation])

  const { onPress: onConfirmPressed, start } = useAutoCallback(onConfirm)
  // save new card or update older one, determine navigation based on route params
  const onSave = useCallbackConnected(async () => {
    try {
      setLoading(true)
      setVisible(true)
      const path = await getImagePath()

      if (isEdit) {
        userDocRef
          ?.collection(collection)
          .doc(relativeCardPath)
          .set({ t: text, c: selectedColor, u: path }, { merge: true })
      } else {
        const order =
          (await getUserCollectionMinOrder(routeType.isFreeSpace ? "c" : "g", userDocRef)) - 1

        userDocRef?.collection(collection).add({ t: text, c: selectedColor, u: path, o: order })
      }
      await start()
    } catch (e) {
      showSnack({ message: t("error") })
      logger("create card, on save error", e)
    } finally {
      setVisible(false)
      setLoading(false)
    }
  }, [
    getImagePath,
    isEdit,
    userDocRef,
    collection,
    relativeCardPath,
    text,
    selectedColor,
    showSnack,
    t,
  ])

  return (
    <KeyboardAvoidingView
      style={styles.view}
      contentContainerStyle={styles.view}
      behavior={Platform.OS === "ios" ? "position" : undefined}
    >
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={s.contentContainer}>
        <CreateCard
          edit={isEdit || routeType.isGallery || routeType.isFreeSpace}
          setText={setChange(setText)}
          setSource={setChange(setSource)}
          {...{ text, imageUri, selectedColor }}
        />
        <ColorsList
          isText={true}
          selectedColor={selectedColor}
          onSelectColor={setChange(setSelectedColor)}
        />
        <BottomDoubleButtonsMaterial
          clearIcon="close-circle"
          {...{ onClear, onSave }}
          disabled={
            _.isEmpty(text) || (routeType.isGallery && !route.params.edit && !source) || !hasChanged
          }
          saveIcon="checkbox-marked-circle"
          clearText={t("common:button.cancel")}
          saveText={t("common:button.validate")}
        />
      </ScrollView>
      <ConfirmationModal
        {...{ visible }}
        dialogProps={{
          loading,
          onConfirm: onConfirmPressed,
          mode: Mode.create,
          card: {
            title: text,
            id: card?.id ?? "",
            color: selectedColor,
            url: source ? source?.path : routeType.isCreate ? imageUri : card?.u ?? "",
          },
        }}
      />
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
  contentContainer: {
    alignItems: "center",
  },
})

export default CreateCardScreen
