import { Body2, generateShadow, H6 } from "@siruplab/capsule"
import React, { FC, useMemo } from "react"
import { StyleSheet } from "react-native"
import FastImage from "react-native-fast-image"
import { Card as PaperCard } from "react-native-paper"

import { maxWidth } from "../features/config/useTabland"
import { Card } from "../features/models/Card"
import { useTheme } from "../utils/defaultStyle"
import isWeb from "../utils/isWeb"

interface IProps {
  card?: Card
  sentence?: boolean
  isSingle?: boolean
  onCardClick?: () => void
}

export const SentenceCard: FC<IProps> = ({ card, sentence, isSingle, onCardClick }) => {
  const {
    colors: { black },
    dimensions: { spacing, borderRadius },
  } = useTheme()

  const s = useMemo(
    () => ({
      cardContent: [
        styles.content,
        {
          borderRadius,
          margin: spacing / 2,
          paddingHorizontal: 0,
          backgroundColor: card?.color,
        },
      ],
      card: [
        styles.container,
        {
          borderRadius,
          maxWidth: !sentence ? 116 : isSingle && isWeb ? maxWidth / 2 : undefined,
          margin: spacing / 2,
        },
      ],
      title: [
        styles.title,
        {
          paddingVertical: spacing / 2,
        },
      ],
    }),
    [card, sentence, spacing, isSingle, borderRadius],
  )

  return (
    <PaperCard style={s.card} onPress={onCardClick}>
      <FastImage style={styles.image} source={{ uri: card?.url }} />
      <PaperCard.Content style={s.cardContent}>
        {sentence ? (
          <H6 color={black.highEmphasis} style={s.title} numberOfLines={2}>
            {card?.title}
          </H6>
        ) : (
          <Body2 color={black.highEmphasis} style={s.title} numberOfLines={2}>
            {card?.title}
          </Body2>
        )}
      </PaperCard.Content>
    </PaperCard>
  )
}

const styles = StyleSheet.create({
  content: {
    overflow: "hidden",
    paddingBottom: 0,
  },
  container: {
    flex: 1,
    ...generateShadow(4),
  },
  image: {
    width: "100%",
    aspectRatio: 1,
    borderTopEndRadius: 8,
    borderTopStartRadius: 8,
  },
  title: {
    textAlign: "center",
  },
})
