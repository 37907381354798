import { Card, FirCard, FirCardWithId } from "./Card"

export function FirCardToCard(firCard: FirCard, id: string): Card
export function FirCardToCard(firCard: FirCardWithId, id?: undefined): Card
export function FirCardToCard(firCard: FirCard | FirCardWithId, id: string | undefined): Card {
  return {
    // @ts-expect-error the id parameter is provided when firCard has no id
    id: "id" in firCard ? firCard.id : id,
    url: firCard.u,
    title: firCard.t,
    color: firCard.c,
    order: firCard.o ?? 0,
    validation: firCard.v,
    column: firCard.col,
    // This change may be necessary for some reason: ...(!!firCard.s && { sentence: firCard.s }),
    ...(firCard.s && { sentence: firCard.s }),
  }
}
