import { generateShadow, MaterialIcons } from "@siruplab/capsule"
import React, { Dispatch, FC, SetStateAction, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { Platform, Pressable, StyleSheet, TextInput, View } from "react-native"
import FastImage from "react-native-fast-image"
import { Image } from "react-native-image-crop-picker"

import ImagesUploader from "../../components/ImagesUploader"
import { imageOptions, textColors } from "../../config/Constants"
import { useTheme } from "../../utils/defaultStyle"
import isWeb from "../../utils/isWeb"
import { ns } from "./i18n/fr"

interface IProps {
  text: string
  edit: boolean
  imageUri: string
  selectedColor: string
  setText: Dispatch<SetStateAction<string>>
  setSource: Dispatch<SetStateAction<Image | undefined>>
}

const CreateCard: FC<IProps> = ({ edit, setSource, selectedColor, imageUri, text, setText }) => {
  const {
    colors: { black, overrides, white },
    dimensions: { spacing, borderRadius },
    typography: { h6 },
  } = useTheme()

  const inputRef = useRef<TextInput>(null)

  const [t] = useTranslation(ns)

  const onPress = () => {
    inputRef.current?.focus()
  }

  const s = useMemo(
    () => ({
      card: [
        styles.card,
        {
          borderRadius,
          backgroundColor: white.highEmphasis,
        },
      ],
      cardBottom: {
        paddingHorizontal: spacing,
        paddingVertical: spacing / 2,
        backgroundColor: white.highEmphasis,
      },
      textInputView: [
        styles.textInputView,
        {
          borderRadius,
          backgroundColor:
            selectedColor === textColors.white ? overrides?.textInput : selectedColor,
        },
      ],
      // An issue is present when we want to apply a bold font in Android
      // https://github.com/facebook/react-native/issues/18820#issuecomment-481693150
      textInput: [
        styles.textInput,
        h6,
        Platform.OS === "android" ? styles.font : undefined,
        {
          borderRadius,
          height: isWeb ? "100%" : "auto",
          paddingVertical: isWeb ? spacing / 2 : 0,
        },
      ],
      shadow: [styles.shadow, { borderRadius }],
    }),
    [spacing, white.highEmphasis, h6, selectedColor, overrides, borderRadius],
  )

  return (
    <View style={s.shadow}>
      <View style={s.card}>
        <ImagesUploader
          disabled={!edit}
          // @ts-ignore
          updatePics={setSource}
          style={styles.cover}
          imageOptions={imageOptions}
        >
          <>
            <FastImage style={styles.image} source={{ uri: imageUri }} />
            {edit ? (
              <MaterialIcons
                style={styles.icon}
                name="photo-camera"
                size={80}
                color={black.inactive}
                accessibilityLabel={t("chooseImage")}
              />
            ) : null}
          </>
        </ImagesUploader>
        <View style={s.cardBottom}>
          <Pressable style={s.textInputView} onPress={onPress}>
            <TextInput
              multiline
              value={text}
              ref={inputRef}
              style={s.textInput}
              onChangeText={setText}
              returnKeyType="done"
              blurOnSubmit={true}
              placeholder={t("placeholder")}
              placeholderTextColor={black.inactive}
            />
          </Pressable>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    width: 247,
    overflow: "hidden",
    justifyContent: "center",
  },
  cover: {
    width: 247,
    height: 247,
    justifyContent: "center",
  },
  icon: {
    zIndex: 1,
    top: "50%",
    left: "50%",
    transform: [{ translateX: -40 }, { translateY: -40 }],
    position: "absolute",
    flexDirection: "row",
  },
  textInputView: {
    height: 68,
    justifyContent: "center",
  },
  image: {
    width: 247,
    aspectRatio: 1,
    borderRadius: 0,
  },
  textInput: {
    paddingTop: 0,
    overflow: "hidden",
    textAlign: "center",
    borderTopEndRadius: 8,
    borderTopStartRadius: 8,
  },
  shadow: {
    ...generateShadow(2),
  },
  font: {
    fontWeight: "normal",
  },
})

export default CreateCard
