import Bugsnag from "@bugsnag/react-native"
import { crashlytics, logger } from "@siruplab/capsule"
import _ from "lodash"
import { Platform } from "react-native"

import { config } from "../../../package.json"

// TODO: issue with bugsnag called more than one
const bugsnag =
  // @ts-ignore
  Platform.OS === "web" ? Bugsnag.start(config.bugsnagKey) : Bugsnag.start()

const reportError = (errorCode: number, errorMessage: string, err?: Error): void => {
  const error =
    err ??
    new Error(
      errorCode + " - " + !_.isString(errorMessage) ? JSON.stringify(errorMessage) : errorMessage,
    )
  bugsnag.leaveBreadcrumb("reportError", { errorCode, errorMessage }, "error")
  crashlytics().recordError(error)
  bugsnag.notify(error)
  logger("reportError", errorCode, errorMessage)
}

const errorCodes = {
  notificationUrl: 10,
  settingsUrl: 11,
  linkHandlingError: 12,
  emailNotFound: 13,
  recoUrl: 14,
  newsUrl: 15,
  safeLinking: 16,
  iap: 17,
  sessionDecrement: 18,
  sessionStart: 19,
  sessionStarted: 20,
  ttsEngine: 21,
  authentication: 22,
}

export default { reportError, errorCodes, bugsnag }
