import { RouteProp } from "@react-navigation/native"
import {
  Body2,
  Button,
  Delayed,
  EmptyView,
  H6,
  IUserContext,
  OptimisticSwitch,
  RadioElement,
  SingleToggleGroup,
  Subtitle1,
  userContext,
  useTheme,
  VectorIcon,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { FC, useCallback, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { FlatList, ListRenderItem, ScrollView, View } from "react-native"

import { columns, radioFonts } from "../../config/Constants"
import { RadioFont } from "../../features/models/RadioFont"
import { UserData } from "../../features/models/UserData"
import { getUserBreadcrumbs, updateUserData } from "../../features/models/UserDataFunctions"
import { mainRoutes } from "../../features/Navigation/Constants"
import { IRootParamList } from "../../features/Navigation/RootNavigator"
import { useShareDisplayScreenLogic } from "../../utils/useShareDisplayScreenLogic"
import { ns } from "./i18n/fr"

const keyExtractor = (item: RadioFont, index: number) => `${index}-${item.id}`

type DisplayScreenRouteProp = RouteProp<IRootParamList, typeof mainRoutes.DISPLAY>

interface IProps {
  route: DisplayScreenRouteProp
}

const DisplayScreen: FC<IProps> = ({ route }) => {
  const {
    s,
    navigation,
    styles,
    primary,
    secondary,
    accent3,
    userDocRef,
  } = useShareDisplayScreenLogic()

  const { fontMaker } = useTheme()
  const { t } = useTranslation(ns)
  const { userData } = useContext<IUserContext<UserData>>(userContext)

  const [font, setFont] = useState<RadioFont>(radioFonts[(userData?.theme?.font as number) ?? 0])
  const [breadcrumbs, setBreadcrumbs] = useState(userData && getUserBreadcrumbs(userData))
  const [currentColumns, setCurrentColumns] = useState<string>(
    `${userData?.theme?.columns ?? columns[1].value}`,
  )
  const [currentColumnsFreeSpace, setCurrentColumnsFreeSpace] = useState<string>(
    `${userData?.theme?.columnsFreeSpace ?? columns[1].value}`,
  )
  const onValidate = useCallback(() => {
    // noinspection JSIgnoredPromiseFromCall
    updateUserData(userDocRef, {
      theme: {
        // SingleToggleGroup & column.value are string when userData.theme.column is number
        columns: parseInt(currentColumns, 10),
        columnsFreeSpace: parseInt(currentColumnsFreeSpace, 10),
        font: font.id,
        breadcrumbs: breadcrumbs ?? true,
      },
    })
    if (route?.params?.edit) {
      navigation.goBack()
    }
  }, [font, currentColumns, currentColumnsFreeSpace, navigation, route, userDocRef, breadcrumbs])

  const renderLabel = useCallback(
    (label: string, fontFamily: string) => (
      <Subtitle1 style={[s.subtitle, fontMaker({ family: fontFamily })]}>{`${t(label)}`}</Subtitle1>
    ),
    [fontMaker, s, t],
  )

  const renderItem: ListRenderItem<RadioFont> = useCallback(
    ({ item }) => (
      <RadioElement
        {...item}
        id={`${item.id}`}
        value={`${item.id}`}
        flexDirection="row"
        touchableStyle={s.touchable}
        onPress={() => setFont(item)}
        isChecked={font?.id === item.id}
        renderLabel={() => renderLabel(item.label ?? "", item.fontFamily ?? "")}
      />
    ),
    [font, renderLabel, s],
  )

  const renderIcon = useCallback(
    () => (
      <VectorIcon size={26} color={primary} name="check-circle" category="MaterialCommunityIcons" />
    ),
    [primary],
  )

  if (_.isNil(userData)) {
    return (
      <Delayed>
        <EmptyView title={t("common:alert.network")} subtitle="" />
      </Delayed>
    )
  }

  return (
    <ScrollView style={s.container} contentContainerStyle={s.contentContainer}>
      <View style={styles.innerView}>
        <H6 style={s.title}>{t("columns")}</H6>
        <SingleToggleGroup
          ref={null}
          items={columns}
          value={currentColumns}
          selectedColor={accent3}
          rowStyle={s.toggleGroup}
          contentStyle={styles.contentToggleGroup}
          onValueChange={(value: string) => setCurrentColumns(value)}
        />
        <H6 style={s.title}>{t("columns_freeSpace")}</H6>
        <SingleToggleGroup
          ref={null}
          items={columns}
          value={currentColumnsFreeSpace}
          selectedColor={accent3}
          rowStyle={s.toggleGroup}
          contentStyle={styles.contentToggleGroup}
          onValueChange={(value: string) => setCurrentColumnsFreeSpace(value)}
        />
        <View style={styles.switchView}>
          <H6 style={s.title}>{t("ariane")}</H6>
          <OptimisticSwitch
            {...s.switch}
            value={breadcrumbs}
            onValueChange={() => setBreadcrumbs(prev => !prev)}
            thumbColor={breadcrumbs ? secondary : primary}
          />
        </View>
        <Body2 style={s.toggleGroup}>{t("ariane_informations")}</Body2>
        <H6 style={s.title}>{t("fonts")}</H6>
        <FlatList
          data={radioFonts}
          scrollEnabled={false}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
        />
      </View>
      <Button
        style={s.button}
        icon={renderIcon}
        onPress={onValidate}
        contentStyle={s.contentButton}
      >
        {t("common:button.validate")}
      </Button>
    </ScrollView>
  )
}
export default DisplayScreen
