import { useTheme } from "@siruplab/capsule"
import React, { FC, useMemo } from "react"
import { StyleSheet, View } from "react-native"
import { Modal, Portal } from "react-native-paper"

import { maxWidthModal } from "../features/config/useTabland"
import { ConfirmationDialogContent, ConfirmationDialogProps } from "./ConfirmationDialogContent"

interface IProps {
  visible: boolean
  dialogProps: ConfirmationDialogProps
}

export const ConfirmationModal: FC<IProps> = ({ visible, dialogProps }) => {
  const {
    colors: { surface },
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      modal: [
        styles.modal,
        {
          maxWidth: maxWidthModal,
          paddingTop: spacing / 2,
        },
      ],
      alert: {
        borderRadius: spacing / 2,
        paddingVertical: spacing / 2,
        backgroundColor: surface.appUi,
        padding: spacing / 2,
      },
    }),
    [spacing, surface],
  )

  return (
    <Portal>
      {visible ? (
        <Modal
          {...{ visible }}
          style={styles.alert}
          onDismiss={dialogProps.onClear}
          dismissable={!dialogProps.loading}
          contentContainerStyle={s.modal}
        >
          <View style={s.alert}>
            <ConfirmationDialogContent {...dialogProps} />
          </View>
        </Modal>
      ) : undefined}
    </Portal>
  )
}

const styles = StyleSheet.create({
  alert: {
    paddingTop: 48,
    justifyContent: "flex-start",
  },
  modal: {
    width: "100%",
    alignSelf: "center",
  },
})

export default ConfirmationModal
