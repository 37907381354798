import React from "react"
import { FC } from "react"
import { Image, ImageProps, View } from "react-native"

const FastImage: FC<ImageProps> = props => (
  <>
    <Image source={props.source} style={props.style} />
    <View style={{ position: "absolute", right: 0, top: 0 }}>{props.children}</View>
  </>
)

export default FastImage
