import {
  firestore,
  IRowItemProps,
  ISectionHeader,
  ParametersScreen,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { SectionListData } from "react-native"

import { Collections } from "../../common/types"
import { Link } from "../../features/models/Link"
import { leftChild, rightChild } from "./ChildComponents"

const SettingsLinkItems = () => {
  const {
    colors: { surface, black },
    typography: { h6 },
  } = useTheme()
  const [links, loading] = useCollectionData<Link>(firestore().collection(Collections.LINKS))
  const sortedLinks = _.sortBy(links, ["order"])
  const linksItems: Array<SectionListData<IRowItemProps | ISectionHeader>> = [
    {
      disableShadow: true,
      // @ts-ignore
      data: _.map(sortedLinks, link => ({
        rightChild,
        url: link.url,
        name: link.name,
        textStyle: h6,
        color: black.mediumEmphasis,
        leftChild: { ...leftChild, name: link.icon_name, category: link.icon_family },
      })),
    },
  ]

  return loading ? null : (
    // @ts-ignore
    <ParametersScreen sections={linksItems} rowBackgroundColor={surface.appUi} />
  )
}

export default SettingsLinkItems
