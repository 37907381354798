import { EmptyView, ErrorScreen, useTheme } from "@siruplab/capsule"
import React from "react"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet } from "react-native"
import { ActivityIndicator } from "react-native-paper"
import WebView from "react-native-webview"

import { urls } from "../../config/Constants"
import { mainRoutes } from "../../features/Navigation/Constants"

const SignupScreen = () => {
  const { t } = useTranslation(mainRoutes.SIGNUP)
  const { colors } = useTheme()

  if (Platform.OS === "ios" || Platform.OS === "web") {
    return <EmptyView title={"Contactez l'équipe PAIPS"} subtitle="" />
  }

  return (
    <WebView
      source={{ uri: urls.SIGN_UP }}
      startInLoadingState={true}
      renderLoading={() => <ActivityIndicator style={styles.full} color={colors.primary} />}
      renderError={() => <ErrorScreen title={t("error")} />}
    />
  )
}

const styles = StyleSheet.create({
  full: { width: "100%", height: "100%" },
})

export default SignupScreen
