import { logger } from "@siruplab/capsule"
import RNShare, { ShareOptions } from "react-native-share"

export const share = async (options: ShareOptions) => {
  try {
    return await RNShare.open(options)
  } catch (e) {
    logger("share error", e)
    return undefined
  }
}
