import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { ParamListBase } from "@react-navigation/core"
import { useNavigation } from "@react-navigation/native"
import {
  AlertButton,
  ButtonType,
  generateShadow,
  IDialogInternal,
  IUserContext,
  statusBarHeight,
  TextInputProps,
  useAlert,
  userContext,
  useTextInputDialog,
  VectorIcon,
} from "@siruplab/capsule"
import React, { useCallback, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View, ViewStyle } from "react-native"
import { TextInput } from "react-native-paper"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import BottomTabBar from "../../components/bottomTabBar"
import { ICON_SIZE, TAB_BAR_SIZE_TAB_LAND } from "../../config/Constants"
import useTabLand, { maxWidthModal, tabSizeLand } from "../../features/config/useTabland"
import { Perso, UserData } from "../../features/models/UserData"
import { isAdminable, updateUserData } from "../../features/models/UserDataFunctions"
import { tabsRoutes } from "../../features/Navigation/Constants"
import { useTheme } from "../../utils/defaultStyle"
import { IOptimisticContext, optimisticContext } from "../../utils/optimisticProvider"
import useNotchSize from "../../utils/useNotchSize"
import { codeValidator } from "../../utils/validators"
import { ns } from "../SettingsScreen/i18n/fr"
import { ns as mainNs } from "./i18n/fr"
import {
  MainCreateTab,
  MainFreeSpaceFavTab,
  MainGalleryTab,
  MainHomeTab,
  MainSettingsTab,
} from "./MainTabs"

export interface IMainParamList extends ParamListBase {
  [tabsRoutes.HOME]: undefined
  [tabsRoutes.CREATE]: undefined
  [tabsRoutes.GALLERY]: undefined
  [tabsRoutes.SETTINGS]: undefined
  [tabsRoutes.FAVORITES]: undefined
}

const mainNavigator = createBottomTabNavigator<IMainParamList>()

const MainNavigator = () => {
  const {
    colors: {
      black,
      error,
      primary,
      white: { highEmphasis: white },
    },
    typography: { h6 },
    dimensions: { spacing, buttonSize, borderRadius },
  } = useTheme()
  const { t } = useTranslation(mainNs)
  const navigation = useNavigation()
  const { showSnack } = useAlert()
  const { showTextDialog } = useTextInputDialog({ validator: codeValidator, limit: 4 })
  const { userData, userDocRef } = useContext<IUserContext<UserData>>(userContext)
  const { setValue } = useContext<IOptimisticContext<Partial<UserData>>>(optimisticContext)
  const middleIconStyle = [styles.middleIcon, { backgroundColor: primary }]
  const isLocked = userData && !isAdminable(userData)

  const s = useMemo(
    () => ({
      alertContent: [
        styles.alertContent,
        {
          marginTop: spacing + statusBarHeight,
        },
      ],
      alertView: [
        styles.alertView,
        {
          paddingHorizontal: spacing,
        },
      ],
      alertTextButton: { paddingLeft: spacing / 2 },
      buttonStyle: [
        styles.button,
        {
          height: buttonSize,
          backgroundColor: white,
        },
      ],
      textInput: [
        styles.textInput,
        h6,
        {
          borderRadius,
          marginHorizontal: spacing,
        },
      ],
    }),
    [h6, spacing, white, buttonSize, borderRadius],
  )

  const renderAlertButton = useCallback(
    (positive: boolean): AlertButton => ({
      mode: "outlined",
      textStyle: s.alertTextButton,
      type: positive ? ButtonType.POSITIVE : ButtonType.NEGATIVE,
      buttonStyle: s.buttonStyle,
      label: t(`common:button.${positive ? "validate" : "cancel"}`),
      icon: {
        color: positive ? primary : error,
        category: "MaterialCommunityIcons",
        name: positive ? "check-circle" : "close-circle",
      },
    }),
    [error, primary, s, t],
  )

  const dialogProps = useMemo(
    () => ({
      dialog: {
        visible: true,
        type: "text",
        style: s.alertView,
        contentContainerStyle: s.alertContent,
        title: t("dialog.unlockTitle", { ns }),
        titleStyle: styles.alertTitle,
        positive: renderAlertButton(true),
        negative: renderAlertButton(false),
        children: (
          <TextInput mode="flat" onPressIn={() => {}} onPressOut={() => {}} autoComplete>
            children
          </TextInput>
        ),
      } as IDialogInternal,
      textInput: {
        autoCapitalize: "none",
        keyboardType: "numeric",
        inputColor: black.mediumEmphasis,
        style: [s.textInput],
        label: t("dialog.placeholder", { ns }),
      } as TextInputProps,
    }),
    [s, t, renderAlertButton, black.mediumEmphasis],
  )

  const isTabLand = useTabLand()

  const onTabSettingsPress = useCallback(
    async e => {
      // Prevent default action
      e.preventDefault()
      if (isLocked) {
        const res = await showTextDialog(dialogProps.dialog, dialogProps.textInput)
        if (res.button === ButtonType.NEGATIVE || res.button === undefined) {
          return
        }
        if (res.button === ButtonType.POSITIVE && res.text === userData?.lockCode) {
          setValue(
            () => updateUserData(userDocRef, { perso: Perso.unlocked }),
            () => navigation.navigate(tabsRoutes.SETTINGS),
            { perso: Perso.unlocked },
          )
          return
        } else {
          showSnack({ message: t("snack.invalid", { ns }) })
          return
        }
      }
      navigation.navigate(tabsRoutes.SETTINGS)
    },
    [
      isLocked,
      navigation,
      showTextDialog,
      dialogProps,
      userData,
      setValue,
      userDocRef,
      showSnack,
      t,
    ],
  )

  const notchSize = useNotchSize()
  const { left } = useSafeAreaInsets()

  return (
    <mainNavigator.Navigator
      tabBarOptions={{
        showLabel: false,
        style: isTabLand ? styles.tabBarLand : undefined,
        // @ts-ignore
        contentStyle: isTabLand ? styles.tabBarContentLand : undefined,
        tabStyle: isTabLand ? styles.tabLand : styles.tab,
      }}
      tabBar={!isTabLand ? undefined : props => <BottomTabBar {...props} />}
      screenStyle={isTabLand ? styles.screenLand : undefined}
      sceneContainerStyle={{
        marginLeft: isTabLand
          ? TAB_BAR_SIZE_TAB_LAND + notchSize + (notchSize === 0 ? left : 0)
          : 0,
      }}
    >
      <mainNavigator.Screen
        name={tabsRoutes.HOME}
        component={MainHomeTab}
        options={{
          tabBarAccessibilityLabel: "Accueil",
          tabBarIcon: ({ focused }) => (
            <VectorIcon
              category="MaterialCommunityIcons"
              name="home"
              size={ICON_SIZE}
              color={focused ? primary : black.disabled}
            />
          ),
        }}
      />
      <mainNavigator.Screen
        name={tabsRoutes.GALLERY}
        component={MainGalleryTab}
        options={{
          tabBarAccessibilityLabel: "Gallerie",
          tabBarIcon: ({ focused }) => (
            <VectorIcon
              category="MaterialIcons"
              name="photo-library"
              size={ICON_SIZE}
              color={focused ? primary : black.disabled}
            />
          ),
        }}
      />
      <mainNavigator.Screen
        name={tabsRoutes.CREATE}
        component={MainCreateTab}
        options={{
          tabBarAccessibilityLabel: "Créer une note",
          tabBarIcon: () => (
            <View style={[middleIconStyle, isTabLand ? styles.middleLand : styles.middleNoLand]}>
              <VectorIcon
                category="MaterialCommunityIcons"
                name="square-edit-outline"
                size={ICON_SIZE}
                color={white}
              />
            </View>
          ),
        }}
      />
      <mainNavigator.Screen
        name={tabsRoutes.FAVORITES}
        component={MainFreeSpaceFavTab}
        options={{
          tabBarAccessibilityLabel: "Favoris",
          tabBarIcon: ({ focused }) => (
            <VectorIcon
              name="star"
              size={ICON_SIZE}
              category="MaterialIcons"
              color={focused ? primary : black.disabled}
            />
          ),
        }}
      />
      <mainNavigator.Screen
        name={tabsRoutes.SETTINGS}
        component={MainSettingsTab}
        listeners={{
          tabPress: onTabSettingsPress,
        }}
        options={{
          tabBarAccessibilityLabel: "Paramètres",
          tabBarIcon: ({ focused }) => (
            <VectorIcon
              category="MaterialCommunityIcons"
              name={`account${isLocked ? "-lock" : ""}`}
              size={ICON_SIZE}
              color={focused ? primary : black.disabled}
            />
          ),
        }}
      />
    </mainNavigator.Navigator>
  )
}

const styles = StyleSheet.create({
  alertContent: {
    maxWidth: maxWidthModal,
    width: "100%",
  },
  alertView: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  alertTitle: {
    textAlign: "center",
  },
  button: {
    justifyContent: "center",
    borderWidth: 0,
    ...generateShadow(4),
  },
  middleIcon: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  middleLand: {
    width: tabSizeLand,
    height: 80,
  },
  middleNoLand: {
    width: "100%",
    minWidth: tabSizeLand,
  },
  textInput: {
    maxHeight: 56,
  },
  tabBarLand: {
    position: "absolute",
    left: 0,
    bottom: 0,
    height: "100%",
    width: tabSizeLand,
    borderTopWidth: 0,
  } as ViewStyle,
  tabBarContentLand: {
    flexDirection: "column",
    height: 200,
    justifyContent: "center",
  },
  tabLand: {
    maxHeight: tabSizeLand,
    paddingHorizontal: 0,
  },
  tab: {
    maxWidth: 600,
  },
  screenLand: {
    marginLeft: tabSizeLand,
  },
})

export default MainNavigator
