import * as capsule from "@siruplab/capsule"
import { AppRegistry } from "react-native"

import App from "./App"
import firebaseInit from "./firebaseInit"
import isWeb from "./utils/isWeb"

require("@formatjs/intl-getcanonicallocales/polyfill")
require("@formatjs/intl-locale/polyfill")

require("@formatjs/intl-pluralrules/polyfill")
require("@formatjs/intl-pluralrules/locale-data/en.js")
require("@formatjs/intl-pluralrules/locale-data/fr.js")

require("@formatjs/intl-numberformat/polyfill")
require("@formatjs/intl-numberformat/locale-data/en.js")
require("@formatjs/intl-numberformat/locale-data/fr.js")

capsule.logger(firebaseInit)

AppRegistry.registerComponent("app", () => App)
if (isWeb) {
  AppRegistry.runApplication("app", {
    rootTag: document.getElementById("root"),
  })
}
