import { DrawRef, PathType } from "@benjeau/react-native-draw"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { useIsFocused, useNavigation } from "@react-navigation/native"
import { logger } from "@siruplab/capsule"
import _ from "lodash"
import { RefObject, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react"
import ViewShot from "react-native-view-shot"
import ReactPainter from "react-painter"

import { drawColors } from "../config/Constants"
import { maxWidth, maxWidthCardEditor } from "../features/config/useTabland"
import { createRoutes } from "../features/Navigation/Constants"
import { usePicture } from "../features/Providers/PictureProvider"
import { Source } from "../screens/DrawScreen/DrawScreen.web"
import { useTheme } from "./defaultStyle"

export const useShareDrawScreenLogic = () => {
  const viewShotRef = useRef<ViewShot>(null)
  const drawRef = useRef<DrawRef>(null)
  const drawRefWeb = useRef<ReactPainter>(null)
  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const {
    dimensions: { width, spacing },
  } = useTheme()
  const [selectedColor, setSelectedColor] = useState(drawColors.black)
  const [disabled, setDisabled] = useState(true)
  const [source, _setSource] = useState<Source>(undefined)

  const maxSize = useMemo(() => (width > maxWidth ? maxWidth : width) - spacing * 2, [
    spacing,
    width,
  ])
  const maxSizeWeb = useMemo(
    () => (width > maxWidthCardEditor ? maxWidthCardEditor : width) - spacing * 2,
    [spacing, width],
  )
  const [updated, forceUpdate] = useReducer(x => x + 1, 0)

  const setSource = useCallback(s => {
    _setSource(s)
    if (s == null) {
      AsyncStorage.removeItem("latestPicture")
    }
  }, [])

  const { setPicture } = usePicture()

  const onPathsChange = (paths: PathType[]) => {
    setDisabled(_.isEmpty(paths))
  }

  const onClear = (drawRefProps: RefObject<DrawRef>) => {
    drawRefProps.current.clear()
  }

  const changeColor = (color: string) => {
    setSelectedColor(color)
    drawRef.current.setColor(color)
  }

  const onClearWeb = () => {
    setDisabled(true)
    drawRefWeb?.current?.render()
    forceUpdate()
  }

  const changeColorWeb = (color: string) => {
    setSelectedColor(color)
    drawRefWeb.current?.handleSetColor(color)
  }

  const onClearSource = () => {
    setSource(undefined)
  }

  const onSave = async () => {
    if (!viewShotRef.current) {
      return
    }

    const res = await viewShotRef.current.capture?.()
    if (!res) {
      return
    }

    setPicture(res)
    if (!source?.path) {
      AsyncStorage.removeItem("latestPicture")
    } else {
      AsyncStorage.setItem("latestPicture", source?.path)
    }
    navigation.navigate(createRoutes.CREATECARD_MAIN, { edit: false, isCreate: true })
  }

  useEffect(() => {
    const fetchPicture = async () => {
      try {
        const latestImage = await AsyncStorage.getItem("latestPicture")
        if (latestImage === null || "") {
          return
        }

        const isFile = async path => {
          try {
            const response = await fetch(path, { method: "HEAD" })
            return response.ok
          } catch (error) {
            return false
          }
        }
        const fileExist = await isFile(latestImage)
        if (!fileExist) {
          return
        }
        setSource({ path: latestImage })
      } catch (error) {
        logger(error)
      }
    }

    fetchPicture()
  }, [setSource])

  return {
    onSave,
    setPicture,
    setSelectedColor,
    selectedColor,
    setDisabled,
    setSource,
    source,
    disabled,
    viewShotRef,
    drawRef,
    drawRefWeb,
    isFocused,
    maxSize,
    onClear,
    onClearWeb,
    changeColorWeb,
    changeColor,
    onPathsChange,
    onClearSource,
    updated,
    maxSizeWeb,
    forceUpdate,
  }
}
