import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import { delay, logger, userContext } from "@siruplab/capsule"
import _ from "lodash"
import { MutableRefObject, useContext, useEffect, useRef } from "react"
import { Platform } from "react-native"
import FastImage from "react-native-fast-image"

import { FirCard } from "../models/Card"

const loadCards = async (
  ref: FirebaseFirestoreTypes.CollectionReference<FirCard>,
  stop: MutableRefObject<boolean>,
): Promise<string[]> => {
  if (stop.current) {
    throw new Error("interrupted")
  }

  const cards = _.map((await ref.get()).docs, d => ({ ...d.data(), id: d.id }))

  if (stop.current) {
    throw new Error("interrupted")
  }

  if (_.isEmpty(cards)) {
    return []
  }

  // slight delay to avoid overloading the app
  await delay(100)

  if (stop.current) {
    throw new Error("interrupted")
  }

  let images = _(cards).map(c => c.u)
  if (!ref.path.endsWith("/g") && _.split(ref.path + "/", "/a/").length !== 3) {
    images = images.concat(
      _.flatten(
        await Promise.all(
          _.map(cards, c =>
            loadCards(
              ref.doc(c.id).collection("a") as FirebaseFirestoreTypes.CollectionReference<FirCard>,
              stop,
            ),
          ),
        ),
      ),
    )
  }

  return images.uniq().value()
}

const OfflineHandler = () => {
  const { userDocRef, user } = useContext(userContext)
  const preloadDone = useRef(false)

  useEffect(() => {
    if (preloadDone.current) {
      return undefined
    }

    const stop = { current: false }
    ;(async () => {
      if (!userDocRef) {
        return null
      }

      // don't start immediately to let the app settle
      await delay(1000)

      if (stop.current) {
        return null
      }

      try {
        const images = _(
          await loadCards(
            userDocRef.collection("h") as FirebaseFirestoreTypes.CollectionReference<FirCard>,
            stop,
          ),
        )
          .concat(
            await loadCards(
              userDocRef.collection("g") as FirebaseFirestoreTypes.CollectionReference<FirCard>,
              stop,
            ),
          )
          .uniq()
          .value()

        if (stop.current) {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error("interrupted")
        }
        logger(`Preloading ${images.length}…`)
        if (Platform.OS !== "web") {
          FastImage.preload(
            _(images)
              .filter(uri => !!uri)
              .map(uri => ({ uri }))
              .value(),
          )
        }

        logger("Preload success")

        preloadDone.current = true

        return null
      } catch (e) {
        logger("OfflineHandler", e)
        throw new Error("error with OfflineHandler")
      }
    })()

    return () => {
      stop.current = true
      logger("Stopping preload")
    }
  }, [userDocRef])

  // allow preload to restart if user logs out
  useEffect(() => {
    if (!user) {
      preloadDone.current = false
    }
  }, [user])

  return null
}

export default OfflineHandler
