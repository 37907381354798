export const ns = "AddCards"

export default {
  [ns]: {
    add: {
      card: "Ajouter une carte depuis la galerie",
      process: "Importer un parcours avec un code",
    },
    code_invalid: "Code invalide",
    share_failed: "Une erreur est survenue pendant l'importation du parcours",
  },
}
