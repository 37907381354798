import { MaterialBottomTabNavigationProp } from "@react-navigation/material-bottom-tabs"
import { CompositeNavigationProp, useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import {
  AlertButton,
  ButtonType,
  DialogResponse,
  generateShadow,
  IDialogInternal,
  IUserContext,
  OptimisticSwitch,
  RowItem,
  statusBarHeight,
  TextInputProps,
  useAlert,
  userContext,
  useTextInputDialog,
  VectorIconProps,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import { switchColor } from "../../config/Constants"
import { maxWidthModal } from "../../features/config/useTabland"
import { Perso, UserData } from "../../features/models/UserData"
import {
  getPerso,
  isAdminable,
  isEditable,
  updateUserData,
} from "../../features/models/UserDataFunctions"
import { tabsRoutes } from "../../features/Navigation/Constants"
import { IRootParamList } from "../../features/Navigation/RootNavigator"
import { useTheme } from "../../utils/defaultStyle"
import { codeValidator } from "../../utils/validators"
import { IMainParamList } from "../MainScreen/MainNavigator"
import { ISettingsParamList } from "../MainScreen/MainTabs"
import { leftChild } from "./ChildComponents"
import { ns } from "./i18n/fr"

export type SettingsScreenNavigationProp = CompositeNavigationProp<
  StackNavigationProp<ISettingsParamList, "Settings_Main">,
  CompositeNavigationProp<
    MaterialBottomTabNavigationProp<IMainParamList>,
    StackNavigationProp<IRootParamList>
  >
>

export function isDialogResponse(e: DialogResponse | void | undefined): e is DialogResponse {
  return !_.isUndefined((e as DialogResponse)?.button)
}

const SettingsSwitchItem = () => {
  const {
    screenStyle,
    dimensions: { spacing, buttonSize, borderRadius },
    colors: { white, black, primary, accent2, secondary, overrides, error, surface },
    typography: { h6 },
  } = useTheme()

  const { t } = useTranslation(ns)
  const { showSnack } = useAlert()
  const { showTextDialog } = useTextInputDialog({ validator: codeValidator, limit: 4 })
  const navigation = useNavigation<SettingsScreenNavigationProp>()
  const { userData, userDocRef } = useContext<IUserContext<UserData>>(userContext)
  const firstEntry = useRef<boolean>(true)
  const isEdit = isEditable(userData)
  const isLocked = !isAdminable(userData)

  const s = useMemo(
    () => ({
      container: [
        styles.container,
        {
          borderRadius: borderRadius * 2,
          margin: spacing,
        },
      ],
      textInput: [
        styles.textInput,
        h6,
        {
          borderRadius,
          marginHorizontal: spacing,
        },
      ],
      alertContent: [
        screenStyle,
        styles.alertContent,
        {
          backgroundColor: surface.appUi,
          marginTop: spacing + statusBarHeight,
        },
      ],
      alertView: [
        styles.alertView,
        {
          paddingHorizontal: spacing,
        },
      ],
      alertTextButton: { paddingLeft: spacing / 2 },
      buttonStyle: [
        styles.button,
        {
          height: buttonSize,
          backgroundColor: white.highEmphasis,
        },
      ],
      row: {
        color: primary,
        backgroundColor: accent2,
        textStyle: h6,
      },
      switch: {
        trackColor: {
          true: overrides?.switch?.lineActive ?? switchColor.active,
          false: overrides?.switch?.lineInactive ?? switchColor.inactive,
        },
      },
      switchEdit: {
        marginTop: spacing,
        marginBottom: spacing / 2,
      },
      switchParams: {
        marginTop: spacing / 2,
        marginBottom: spacing,
      },
    }),
    [
      screenStyle,
      spacing,
      surface,
      white,
      primary,
      accent2,
      h6,
      overrides,
      buttonSize,
      borderRadius,
    ],
  )

  // useEffect call when firstEntry only, check status perso exist
  useEffect(() => {
    if (firstEntry.current) {
      firstEntry.current = false
      getPerso(userData)
    }
  }, [userData])

  const renderAlertButton = useCallback(
    (positive: boolean): AlertButton => ({
      mode: "outlined",
      textStyle: s.alertTextButton,
      type: positive ? ButtonType.POSITIVE : ButtonType.NEGATIVE,
      buttonStyle: s.buttonStyle,
      label: t(`common:button.${positive ? "validate" : "cancel"}`),
      icon: {
        color: positive ? primary : error,
        category: "MaterialCommunityIcons",
        name: positive ? "check-circle" : "close-circle",
      },
    }),
    [error, primary, s, t],
  )

  const dialogProps = useMemo(
    () => ({
      dialog: {
        visible: true,
        type: "text",
        style: s.alertView,
        contentContainerStyle: s.alertContent,
        title: t("dialog.title"),
        titleStyle: styles.alertTitle,
        positive: renderAlertButton(true),
        negative: renderAlertButton(false),
      } as IDialogInternal,
      textInput: {
        autoCapitalize: "none",
        keyboardType: "numeric",
        inputColor: black.mediumEmphasis,
        label: t("dialog.placeholder"),
        style: [s.textInput],
      } as TextInputProps,
    }),
    [s, renderAlertButton, t, black.mediumEmphasis],
  )

  const onSwitchParamChange = useCallback(
    async val => {
      if (val) {
        if (!userData?.lockCode) {
          // @ts-ignore
          const result = await showTextDialog?.(dialogProps.dialog, dialogProps.textInput)
          if (result.button === ButtonType.NEGATIVE || result.button === undefined) {
            return
          }
          if (result.text.length !== 4) {
            showSnack({ message: t("snack.empty") })
            return
          }
          // noinspection ES6MissingAwait
          updateUserData(userDocRef, { lockCode: result.text, perso: Perso.paramLock })
        }
        // noinspection ES6MissingAwait
        updateUserData(userDocRef, { perso: Perso.paramLock })
        setTimeout(() => navigation.jumpTo(tabsRoutes.HOME), 200)
      } else {
        // noinspection ES6MissingAwait
        updateUserData(userDocRef, { perso: Perso.unlocked })
      }
    },
    [
      userData,
      userDocRef,
      showTextDialog,
      dialogProps.dialog,
      dialogProps.textInput,
      showSnack,
      t,
      navigation,
    ],
  )
  const onSwitchEditChange = useCallback(
    async val => {
      // noinspection ES6MissingAwait
      updateUserData(userDocRef, { perso: val ? Perso.unlocked : Perso.editLock })
    },
    [userDocRef],
  )

  return (
    <View style={s.container}>
      <RowItem
        {...s.row}
        name={t("customization")}
        accessibilityHint={`${t("customization")}, ${t(`${isEdit ? "checked" : "unchecked"}`)}`}
        onAccessibilityActivate={() => onSwitchEditChange(!isEdit)}
        onPress={() => onSwitchEditChange(!isEdit)}
        leftChild={{ ...leftChild, name: "view-grid-plus", color: primary } as VectorIconProps}
        rightChild={
          <OptimisticSwitch
            {...s.switch}
            style={s.switchEdit}
            value={isEdit}
            onValueChange={onSwitchEditChange}
            thumbColor={isEdit ? secondary : primary}
          />
        }
      />
      <RowItem
        {...s.row}
        name={t("settingsLock")}
        accessibilityHint={`${t("settingsLock")}, ${t(`${isLocked ? "checked" : "unchecked"}`)}`}
        leftChild={
          {
            ...leftChild,
            color: primary,
            name: isLocked ? "lock" : "lock-open-outline",
          } as VectorIconProps
        }
        onPress={() => onSwitchParamChange(!isLocked)}
        onAccessibilityActivate={() => onSwitchParamChange(!isLocked)}
        rightChild={
          <OptimisticSwitch
            {...s.switch}
            style={s.switchParams}
            ms={250}
            value={isLocked}
            onValueChange={onSwitchParamChange}
            thumbColor={isLocked ? secondary : primary}
          />
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  alertContent: {
    maxWidth: maxWidthModal,
    width: "100%",
  },
  alertView: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  alertTitle: {
    textAlign: "center",
  },
  button: {
    borderWidth: 0,
    ...generateShadow(4),
  },
  container: {
    overflow: "hidden",
  },
  textInput: {
    maxHeight: 56,
  },
})

export default SettingsSwitchItem
