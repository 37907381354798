import { useTheme } from "@siruplab/capsule"
import React, { FC, useMemo } from "react"
import { StyleSheet } from "react-native"
import { ProgressBar as PaperProgressBar } from "react-native-paper"

interface IProps {
  progress: number
}

export const ProgressBar: FC<IProps> = ({ progress }) => {
  const {
    colors: { secondary },
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      progressBar: [
        styles.progressBar,
        {
          margin: spacing,
          marginBottom: spacing / 2,
        },
      ],
    }),
    [spacing],
  )

  return (
    <PaperProgressBar
      color={secondary}
      accessible={false}
      progress={progress}
      style={s.progressBar}
      accessibilityElementsHidden={true}
      importantForAccessibility="no-hide-descendants"
    />
  )
}

const styles = StyleSheet.create({
  progressBar: {
    height: 16,
    borderRadius: 50,
  },
})
