export const ns = "CreateCardMenu"

export default {
  [ns]: {
    one: "Créer une carte",
    multiple: "Insérer plusieurs images",
    error: "Une erreur est survenue, une ou plusieurs de vos images ne sont pas valides",
    cropperTitle: "Redimensionner l'image",
    save: "Enregistrer",
    reset: "Réinitialiser",
    crop: "Redimensionnner",
    successUploading: "Vos images ont bien été enregistrées",
  },
}
