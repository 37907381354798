import generateShadow from "@siruplab/capsule/src/utils/generateShadow"
import { useCallback, useMemo, useRef, useState } from "react"
import { ReactCropperElement } from "react-cropper"
import { StyleSheet } from "react-native"

import { maxWidth } from "../features/config/useTabland"
import { useTheme } from "./defaultStyle"

export const useCropper = () => {
  const {
    dimensions: { spacing, borderRadius },
    colors: { white },
  } = useTheme()
  const cropperRef = useRef<ReactCropperElement>(null)
  const [imgUri, setImgUri] = useState<string>()
  const [scale, setScale] = useState(1)
  const [visibleCropper, setVisibleCropper] = useState(false)
  const onScaleChange = useCallback((value: number) => {
    if (cropperRef.current) {
      setScale(value)
      cropperRef.current.cropper.scale(value)
    }
  }, [])

  const onResetCrop = useCallback(() => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper
      cropper.reset()
      setScale(1)
    }
  }, [])

  const cropperStyle = useMemo(
    () => ({
      indicator: {
        paddingVertical: spacing / 2,
      },
      modalContainer: [
        styles.modalContent,
        {
          maxWidth,
          padding: spacing * 2,
          margin: spacing * 2,
          borderRadius,
          maxHeight: 600,
        },
      ],
      button: [
        styles.button,
        {
          backgroundColor: white,
          marginVertical: spacing,
        },
      ],
      cropOptions: [
        styles.cropOptions,
        {
          marginVertical: spacing,
          gap: spacing,
        },
      ],
    }),
    [spacing, white, borderRadius],
  )
  return {
    cropperRef,
    scale,
    onScaleChange,
    onResetCrop,
    imgUri,
    setImgUri,
    visibleCropper,
    setVisibleCropper,
    cropperStyle,
  }
}

const styles = StyleSheet.create({
  modalContent: {
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },

  button: {
    flex: 1,
    borderWidth: 0,
    justifyContent: "center",
    ...generateShadow(4),
  },
  cropOptions: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
})
