import { useNavigation } from "@react-navigation/native"
import { generateShadow, IUserContext, userContext } from "@siruplab/capsule"
import { useContext, useMemo } from "react"
import { StyleSheet } from "react-native"

import { UserData } from "../features/models/UserData"
import { useTheme } from "./defaultStyle"

export const useShareDisplayScreenLogic = () => {
  const {
    fontMaker,
    screenStyle,
    dimensions: { spacing, buttonSize },
    colors: { primary, secondary, black, surface, accent3, white, overrides },
  } = useTheme()
  const navigation = useNavigation()
  const { userDocRef } = useContext<IUserContext<UserData>>(userContext)

  const styles = StyleSheet.create({
    innerView: {
      width: "100%",
    },
    switchView: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    button: {
      width: 168,
      borderWidth: 0,
      alignSelf: "center",
    },
    largeButton: {
      width: "100%",
      marginTop: 16,
      marginBottom: 8,
    },
    contentButton: {
      height: buttonSize,
    },
    contentToggleGroup: {
      alignItems: "flex-start",
    },
    container: {
      flex: 1,
    },
    subtitle: {
      flex: 1,
      alignSelf: "center",
    },
  })

  const s = useMemo(
    () => ({
      title: { paddingTop: spacing },
      container: [
        styles.container,
        {
          paddingHorizontal: spacing,
          backgroundColor: surface.appUi,
        },
      ],
      contentContainer: [
        screenStyle,
        {
          paddingBottom: spacing / 2,
        },
      ],
      toggleGroup: { paddingTop: spacing },
      touchable: { paddingHorizontal: spacing },
      subtitle: [styles.subtitle, { color: black.highEmphasis }],
      contentButton: [styles.contentButton, { backgroundColor: white.highEmphasis }],
      button: [
        styles.button,
        generateShadow(4),
        { marginTop: spacing * 2.5, marginHorizontal: spacing * 6 },
      ],
      switch: {
        trackColor: {
          true: overrides?.switch?.lineActive ?? "",
          false: overrides?.switch?.lineInactive ?? "",
        },
      },
    }),
    [
      spacing,
      styles.container,
      styles.subtitle,
      styles.contentButton,
      styles.button,
      surface.appUi,
      screenStyle,
      black.highEmphasis,
      white.highEmphasis,
      overrides?.switch?.lineActive,
      overrides?.switch?.lineInactive,
    ],
  )

  return {
    fontMaker,
    s,
    navigation,
    styles,
    primary,
    secondary,
    accent3,
    userDocRef,
  }
}
