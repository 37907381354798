export const ns = "Draw"

export default {
  [ns]: {
    erase: "Effacer",
    save: "Enregistrer",
    colors: {
      black: "Noir",
      red: "Rouge",
      blue: "Bleu",
      yellow: "Jaune",
      green: "Vert",
      white: "Blanc",
      orange: "Orange",
    },
    camera: "Camera",
  },
}
