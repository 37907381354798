import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore"
import _ from "lodash"

export const getRelativeCardPath = (
  route: Readonly<{
    key: string
    name: string
    params?: Readonly<{ edit?: boolean; path?: string }>
  }>,
  collection: string,
  userDocRef: FirebaseFirestoreTypes.DocumentReference<FirebaseFirestoreTypes.DocumentData> | null,
): string => {
  const path = route.params?.path ?? ""
  const userDocRefPath = userDocRef?.path

  return _?.replace(path, `${userDocRefPath}/${collection}/`, "")
}
