import { mainRoutes } from "../Constants"

export default {
  [mainRoutes.WELCOME]: {
    title: "Bienvenue",
    welcome: [
      {
        title: "Bienvenue sur Paips",
        description: "L'outil de communication pour faciliter les échanges au quotidien",
      },
      {
        title: "Faites-vous comprendre en 3 étapes",
        description:
          "Choisissez, montrez l'image, puis cliquez dessus pour dérouler les étapes : situation, objet, action.",
      },
      {
        title: "Personnalisez le contenu",
        description: "Aidant, adaptez l'outil aux besoins et aux capacités du bénéficiaire",
      },
      {
        title: "Et bien plus !",
        description:
          "Écrire un message, dessiner, partager une image ou la mettre en favoris : découvrez les fonctionnalités de Paips",
      },
    ],
  },
  [mainRoutes.LOGIN]: {
    title: "Connexion",
  },
  [mainRoutes.DISPLAY]: {
    title: "Affichage",
  },
  [mainRoutes.DISPLAY_HIERARCHY]: {
    title: "Paramètres du parcours",
  },
  [mainRoutes.ADD_CARD]: {
    title: "Ajouter une carte",
  },
  [mainRoutes.ADD_PROCESS]: {
    title: "Ajouter un parcours",
  },
  common: {
    fieldLabels: {
      email: "Identifiant",
    },
    signUp: {
      termsOfUse:
        "<p>En vous inscrivant vous acceptez les <a href='https://paips.fr/cgu-comnous/'>conditions générales d’utilisation</a>.</p>",
    },
    signIn: {
      forgetPassword: "Réinitialiser votre mot de passe",
      connect: "Valider",
    },
    landing: {
      signUp: "Se connecter",
      signIn: "Créer un compte",
    },
  },
}
