import { useEffect, useState } from "react"
import DeviceInfo from "react-native-device-info"
import Orientation, {
  OrientationType,
  useDeviceOrientationChange,
} from "react-native-orientation-locker"

import { NOTCH_SIZE } from "../config/Constants"

const useNotchSize = (orientationTarget = "LANDSCAPE-LEFT") => {
  const hasNotch = DeviceInfo.hasNotch()
  const [orientation, setOrientation] = useState<OrientationType>()

  useEffect(() => {
    Orientation.getDeviceOrientation(setOrientation)
  }, [])

  useDeviceOrientationChange(setOrientation)

  return hasNotch && orientation === orientationTarget ? NOTCH_SIZE : 0
}

export default useNotchSize
