export const ns = "GalleryScreen"

export default {
  [ns]: {
    cardActions: {
      parameters: "Paramètrer le parcours",
      edit: "éditer la carte",
      delete: "supprimer la carte",
      share: "partager la carte",
      validate: "valider la carte",
    },
    placeholder: "Rechercher…",
    delete: "Cette image sera supprimée",
    error: {
      add_gallery: "Impossible d'ajouter une ou plusieurs cartes",
    },
    create: "Créer une carte",
  },
}
