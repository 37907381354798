export const ns = "Main"

export default {
  [ns]: {
    headers: {
      home: "Accueil",
      gallery: "Galerie",
      create: "Créer une note",
      createCard: "Créer une carte",
      editCard: "Éditer une carte",
      seeCard: "Voir une carte",
      sortCards: "Classer",
      favorites: "Favoris",
      sentence: "Phrase",
      settings: "Paramètres",
      display: "Affichage",
      back: "Retour",
    },
    userMustLogout: "Déconnectez-vous pour pouvoir accéder au compte d'un autre utilisateur.",
    locked: "Écran paramètres bloqué, touchez l'onglet pour saisir le code",
  },
}
