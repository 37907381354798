import { tabsRoutes } from "../../../features/Navigation/Constants"

export const ns = tabsRoutes.SETTINGS

export default {
  [ns]: {
    display: "Affichage",
    customization: "Mode personnalisation",
    settingsLock: "Bloquer les paramètres",
    checked: "Élément activé",
    unchecked: "Élément désactivé",
    snack: {
      invalid: "Code de blocage invalide",
      empty: "Le code doit comporter 4 chiffres",
    },
    dialog: {
      title: "Code de blocage",
      placeholder: "Entrer le code…",
      unlockTitle: "Déverrouiller les modifications",
    },
  },
}
