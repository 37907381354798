import { UserProvider } from "@siruplab/capsule"
import React, { FC, ReactNode } from "react"
import { SafeAreaProvider } from "react-native-safe-area-context"

import OfflineHandler from "../offline/OfflineHandler"
import ValidatedCardsProvider from "./ValidatedCardsProvider"

interface IProps {
  children: ReactNode
}

const RootProvider: FC<IProps> = props => (
  <SafeAreaProvider>
    <UserProvider disableToken={true}>
      <ValidatedCardsProvider>{props.children}</ValidatedCardsProvider>
      <OfflineHandler />
    </UserProvider>
  </SafeAreaProvider>
)

export default RootProvider
