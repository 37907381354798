import "cropperjs/dist/cropper.css"

import Slider from "@react-native-community/slider"
import { Button, H6, MaterialCommunityIcons, Touchable } from "@siruplab/capsule"
import React, { useCallback, useEffect } from "react"
import Cropper from "react-cropper"
import { ScrollView, StyleSheet, View } from "react-native"
import { Modal, Portal } from "react-native-paper"

import { BottomDoubleButtonsMaterial } from "../../components"
import { Mode } from "../../components/ConfirmationDialogContent"
import ConfirmationModal from "../../components/ConfirmationModal"
import { maxWidth } from "../../features/config/useTabland"
import { getUserCollectionMinOrder } from "../../features/models/UserDataFunctions"
import { galleryRoutes } from "../../features/Navigation/Constants"
import { useTheme } from "../../utils/defaultStyle"
import { useCropper } from "../../utils/useCropper"
import useCropWebLogic from "../../utils/useCropWebLogic"
import { createCardStyle, useShareCreateCard } from "./useShareCreateCard"

interface ImageBase64 {
  name: string
  type: string
  uri: string
  width?: number
  height?: number
}

const CreateCardMenu = () => {
  const {
    userDocRef,
    setVisible,
    setLoading,
    collection,
    t,
    navigate,
    buttonProps,
    iconProps,
    loading,
    visible,
    s,
    showSnack,
  } = useShareCreateCard()

  const {
    dimensions: { buttonSize },
    colors: { primary },
  } = useTheme()

  const { cropperStyle, cropperRef, scale, onScaleChange, onResetCrop } = useCropper()

  const { processAndUploadEachImage, onValidateCrop, state, onCancelProcess } = useCropWebLogic({
    setVisible,
    setLoading,
    userDocRef,
    cropperRef,
    collection,
  })

  const handleInputImageChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files
      if (!files) {
        return
      }
      const order = (await getUserCollectionMinOrder(collection, userDocRef)) - 1

      for (const [index, file] of Array.from(files).entries()) {
        await processAndUploadEachImage(file, order - index)
      }
      navigate(galleryRoutes.GALLERY_MAIN)
    },
    [collection, navigate, processAndUploadEachImage, userDocRef],
  )

  useEffect(() => {
    if (state.status === "error") {
      showSnack(t("error"))
    }
  }, [state.status, showSnack, t])

  return (
    <>
      <ScrollView style={createCardStyle.view} contentContainerStyle={s.containerView}>
        <Touchable
          {...buttonProps}
          style={s.button}
          onPress={() => navigate(galleryRoutes.GALLERY_CREATE)}
        >
          <View style={s.contentButton}>
            <MaterialCommunityIcons {...iconProps} name="card" />
            <H6 style={s.label}>{t("one")}</H6>
          </View>
        </Touchable>
        <label htmlFor="multiple-file">
          <input
            style={{ display: "none" }}
            type="file"
            id="multiple-file"
            multiple
            accept="image/*"
            onChange={handleInputImageChange}
          />
          <Touchable {...buttonProps} style={s.button}>
            <View style={s.contentButton}>
              <View style={createCardStyle.icon}>
                <MaterialCommunityIcons {...iconProps} name="cards" />
              </View>
              <H6 style={s.label}>{t("multiple")}</H6>
            </View>
          </Touchable>
        </label>
      </ScrollView>
      <ConfirmationModal
        {...{ visible }}
        dialogProps={{
          loading,
          mode: Mode.createMultiple,
        }}
      />
      <Portal>
        <Modal
          style={styles.modalContainer}
          contentContainerStyle={cropperStyle.modalContainer}
          visible={state.status === "running"}
        >
          <View>
            <Cropper
              src={state.payload}
              style={{ height: maxWidth / 2, width: "100%" }}
              aspectRatio={1}
              guides={false}
              viewMode={1}
              ref={cropperRef}
              cropBoxResizable={false}
            />
          </View>
          <View style={cropperStyle.cropOptions}>
            <Button icon="autorenew" onPress={onResetCrop}>
              {t("reset")}
            </Button>
            <Slider
              style={{ width: maxWidth / 4, height: buttonSize }}
              minimumValue={0.1}
              maximumValue={4}
              value={scale}
              onValueChange={onScaleChange}
              minimumTrackTintColor={primary}
              maximumTrackTintColor={primary}
              thumbTintColor={primary}
            />
          </View>
          <BottomDoubleButtonsMaterial
            clearIcon="close-circle"
            onClear={onCancelProcess}
            onSave={onValidateCrop}
            saveIcon="plus-circle"
            clearText={t("common:button.cancel")}
            saveText={t("save")}
          />
        </Modal>
      </Portal>
    </>
  )
}

export default CreateCardMenu

const styles = StyleSheet.create({
  modalContainer: {
    alignItems: "center",
    flex: 1,
  },
})
