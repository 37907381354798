import { H6, PresentationListView, Subtitle1, userContext, VectorIcon } from "@siruplab/capsule"
import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FlatList, StyleSheet, View } from "react-native"

import { FavoritesCard } from "../../components"
import { useTheme } from "../../utils/defaultStyle"
import { ns } from "./i18n/fr"

const keyExtractor = (item: string) => `${item}`

const FavoritesScreen = () => {
  const { userData, userDocRef } = useContext(userContext)
  const data = useMemo(() => userData?.favorites, [userData])

  const {
    colors: { primary },
    dimensions: { spacing, buttonSize },
    typography: { h6 },
  } = useTheme()

  const { t } = useTranslation(ns)

  const s = useMemo(
    () => ({
      noFavoritesIcon: {
        margin: spacing,
      },
      noFavoritesTitle: {
        textStyle: h6,
      },
      noFavoritesSubtitle: [
        styles.emptyText,
        {
          margin: spacing / 2,
        },
      ],
      noFavoritesContainer: [
        styles.noFavoritesContainer,
        {
          padding: spacing / 2,
        },
      ],
    }),
    [spacing, h6],
  )
  const renderContent = ({ item }) => <FavoritesCard url={item} userDocRef={userDocRef} />
  const renderChildInfo = useMemo(
    () => (
      <View style={s.noFavoritesContainer}>
        <View style={s.noFavoritesIcon}>
          <VectorIcon
            name="star"
            category="MaterialCommunityIcons"
            color={primary}
            size={buttonSize}
          />
        </View>
        <H6 style={styles.emptyText}> {t("empty")}</H6>
        <Subtitle1 style={s.noFavoritesSubtitle}>{t("addFavorite")}</Subtitle1>
      </View>
    ),
    [primary, s, t, buttonSize],
  )

  return (
    <PresentationListView
      isEmpty={userData?.favorites?.[0] === undefined}
      indicator={{ color: primary, size: "small" }}
      childInfo={renderChildInfo}
    >
      <FlatList<string> data={data} renderItem={renderContent} keyExtractor={keyExtractor} />
    </PresentationListView>
  )
}

const styles = StyleSheet.create({
  noFavoritesContainer: {
    alignItems: "center",
    height: 120,
    maxWidth: 256,
    flex: 1,
    alignSelf: "center",
  },
  emptyText: {
    textAlign: "center",
  },
})

export default FavoritesScreen
