import "moment/locale/fr"

import {
  ITypographyTheme,
  IUserContext,
  Theme,
  ThemeProvider,
  userContext,
} from "@siruplab/capsule"
import _ from "lodash"
import moment from "moment"
import React, { FC, useContext } from "react"
import { useTranslation } from "react-i18next"
import { useWindowDimensions } from "react-native"

import { ComicSansMS, ICON_SIZE, OpenDyslexic, Roboto } from "./config/Constants"
import { maxWidth } from "./features/config/useTabland"
import { UserData } from "./features/models/UserData"
import { getUserFont } from "./features/models/UserDataFunctions"

interface ExtendedDimensions {
  iconSpacing: number
  buttonSize: number
  borderRadius: number
}
export interface ExtendedTheme {
  dimensions: Theme["dimensions"] & ExtendedDimensions
}

const ThemeApp: FC = ({ children }) => {
  const { width: windowWidth, height: windowHeight } = useWindowDimensions()
  const { userData } = useContext<IUserContext<UserData>>(userContext)
  const { i18n } = useTranslation()

  const momentLocale = _.split(_.toLower(i18n.language), "-")[0]
  moment.locale(momentLocale)

  return (
    <ThemeProvider<Theme & ExtendedTheme>
      customTheme={{
        button: { mode: "contained", roundness: 8 },
        roundness: 0,
        colors: {
          surface: {
            appUi: "#F0F0F0",
            header: "#ECE8F7",
            textInput: "#FFFFFF",
          },
          primary: "#5F48B9",
          secondary: "#FFC821",
          accent1: "rgba(253, 157, 39, 0.38)",
          accent2: "#CFC7EA",
          accent3: "#FFEAB5",
          black: {
            disabledButton: "rgba(0, 0, 0, 0.12)",
          },
          error: "#FF0C3E",
          danger: "#E04242",
          overrides: {
            ripple: "#5F48B9",
            button: "#FFF",
            textInput: "#DADADA",
            switch: {
              lineInactive: "rgba(95, 72, 185, 0.38)",
              lineActive: "rgba(253, 157, 39, 0.38)",
            },
          },
        },
        dimensions: {
          buttonSize: 48,
          borderRadius: 8,
          marginSides: windowWidth > maxWidth || windowHeight > 680 ? 48 : 16, // on iPhone SE, keep smaller margins
          iconSpacing: windowWidth > maxWidth ? ICON_SIZE * 1.3 : ICON_SIZE, // Define special spacing for icon to facilitate eye mode
        },
        fonts: {
          default: getUserFont(userData),
          Roboto,
          OpenDyslexic,
          ComicSansMS,
        },
        // @ts-ignore
        typoGen: ({
          colors,
          fontMaker,
        }: Omit<Omit<Theme, "typography">, "typoGen">): Partial<ITypographyTheme> => ({
          h6: {
            ...fontMaker({ weight: "Bold" }),
            lineHeight: undefined,
          },
          subtitle1: {
            ...fontMaker({ weight: "Bold" }),
          },
          button: {
            ...fontMaker(),
            color: colors.black.mediumEmphasis,
            fontSize: 14,
          },
        }),
        screenStyle: {
          maxWidth,
          width: "100%",
          alignSelf: "center",
        },
      }}
    >
      {children}
    </ThemeProvider>
  )
}

export default ThemeApp
