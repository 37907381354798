import { RouteProp, useNavigation } from "@react-navigation/native"
import { HeaderBackButton, StackNavigationProp } from "@react-navigation/stack"
import {
  ActionBar,
  delay,
  firebase,
  IUserContext,
  logger,
  userContext,
  useTheme,
} from "@siruplab/capsule"
import _ from "lodash"
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { Platform, ScrollView, StyleSheet, View } from "react-native"
import Tts from "react-native-tts"
import { useTimer } from "use-timer"

import { ProgressBar, SentenceCard } from "../../components"
import { HeaderIcon } from "../../components/HeaderIcon"
import { ValidateCardButton } from "../../components/ValidateCardButton"
import { iconSize, MAX_TIME } from "../../config/Constants"
import { maxWidth } from "../../features/config/useTabland"
import { FirCardWithId } from "../../features/models/Card"
import { FirCardToCard } from "../../features/models/CardFunctions"
import { UserData } from "../../features/models/UserData"
import { isAdminable, isEditable } from "../../features/models/UserDataFunctions"
import { mainRoutes, tabsRoutes } from "../../features/Navigation/Constants"
import { IRootParamList } from "../../features/Navigation/RootNavigator"
import { useValidatedCards } from "../../features/Providers/ValidatedCardsProvider"
import crashlytics from "../../services/Analytics/crashlytics"
import { share } from "../../utils/share"
import { IFreeSpaceFavParamList, IHomeParamList } from "../MainScreen/MainTabs"
import { ns } from "./i18n/fr"
import SentenceFooter from "./SentenceFooter"
import SentenceHeader from "./SentenceHeader"

type SentenceScreenRouteProp = RouteProp<IRootParamList, typeof mainRoutes.SENTENCE>
type FreeSpaceFavSentenceRouteProp = RouteProp<
  IFreeSpaceFavParamList,
  typeof tabsRoutes.FREESPACE_FAV
>

interface IProps {
  route: SentenceScreenRouteProp | FreeSpaceFavSentenceRouteProp
  singleCardId?: string
}

if (Platform.OS !== "web") {
  // noinspection JSIgnoredPromiseFromCall
  Tts.setDefaultLanguage("fr-FR")
  // noinspection JSIgnoredPromiseFromCall
  Tts.setIgnoreSilentSwitch("ignore")
}

type HomeScreenNavigationProp = StackNavigationProp<IHomeParamList, "Home_Main">

const SentenceScreen: FC<IProps> = ({ route }) => {
  const navigation = useNavigation<HomeScreenNavigationProp>()
  const { t } = useTranslation(ns)
  const { userData, userDocRef } = useContext<IUserContext<UserData>>(userContext)
  const {
    screenStyle,
    colors: { primary },
    dimensions: { spacing },
  } = useTheme()

  const singleCardId = route?.params?.singleCardId
  const [singleCard] = useDocumentData<FirCardWithId>(
    singleCardId ? userDocRef?.collection("c").doc(singleCardId) : null,
    {
      idField: "id",
    },
  )
  const fromFav = route?.params?.fromFav
  const alreadyWentBack = useRef(false)

  // Retrieve FireCard l3 and l2
  const l3cardPath = route?.params?.l3card
  const l3cardRef = l3cardPath ? userDocRef?.collection("h").doc(l3cardPath) : undefined
  const l2cardRef = _.replace(l3cardRef?.parent?.parent?.path ?? "", `${userDocRef?.path}/h/`, "")
  const [l3card] = useDocumentData<FirCardWithId>(
    l3cardPath ? userDocRef?.collection("h").doc(l3cardPath) : null,
    {
      idField: "id",
    },
  )
  const [l2card] = useDocumentData<FirCardWithId>(
    l2cardRef ? userDocRef?.collection("h").doc(l2cardRef) : null,
    {
      idField: "id",
    },
  )

  const { validatedCards, setValidatedCards } = useValidatedCards()

  const validationCardKey = l3card?.id ?? ""

  const isValidation = route?.params?.isValidation

  // Timer
  const firstStart = useRef<boolean>(true)
  const [vocInitDone, setVocInitDone] = useState(false)
  const [isTtsEnded, setIsTtsEnded] = useState<boolean>(true)
  const { start, time, status, pause, reset } = useTimer({
    timerType: "INCREMENTAL",
  })
  const isStopped = status === "STOPPED" || status === "PAUSED"
  const [sentence, setSentence] = useState<string>()
  const [defaultSentence, setDefaultSentence] = useState<string>()

  const goBack = useCallback(() => {
    if (alreadyWentBack.current) {
      logger("Already went back")
      return
    }
    alreadyWentBack.current = true
    navigation.goBack()
  }, [navigation])

  const onSaveAndBack = useCallback(() => {
    if (userData && isEditable(userData) && l3card?.s !== sentence) {
      if (singleCard) {
        userDocRef
          ?.collection("c")
          .doc(singleCardId)
          .set(
            { s: _.isEmpty(sentence) ? firebase.firestore.FieldValue.delete() : sentence },
            { merge: true },
          )
        return
      }
      userDocRef
        ?.collection("h")
        .doc(l3cardPath)
        .set(
          {
            s: _.isEmpty(sentence) ? firebase.firestore.FieldValue.delete() : sentence,
          },
          { merge: true },
        )
    }
  }, [l3card, l3cardPath, sentence, singleCard, singleCardId, userData, userDocRef])

  // check if android missing engine and request
  useEffect(() => {
    Tts.getInitStatus()
      .then(result => logger("ttStatus", result))
      .catch(e => {
        logger("tts error", e)
        crashlytics.reportError(crashlytics.errorCodes.ttsEngine, `Tts  error : ${e.code}`, e)
        // noinspection JSIgnoredPromiseFromCall
        Tts.requestInstallEngine()
      })
  }, [])

  // useEffect to just go back when pressed
  useEffect(() => {
    if (fromFav) {
      return
    }
    // noinspection JSUnusedGlobalSymbols
    navigation.setOptions({
      headerLeft: props => <HeaderBackButton {...props} onPress={goBack} />,
    })
  }, [fromFav, goBack, navigation])

  // useEffect to save changes on the sentence and go back when pressed
  useEffect(() => {
    // noinspection JSUnusedGlobalSymbols
    navigation.setOptions({
      title: "",
      headerRight: props =>
        userData && isAdminable(userData) && isEditable(userData) ? (
          <ActionBar
            {...props}
            icons={[
              {
                id: 1,
                icon: (
                  <HeaderIcon
                    iconName="check"
                    onPress={() => {
                      onSaveAndBack()
                      goBack()
                    }}
                    label={t("validate")}
                  />
                ),
              },
            ]}
          />
        ) : undefined,
    })
  }, [userData, l3cardPath, navigation, onSaveAndBack, fromFav, goBack, t])

  const onTtsFinish = useCallback(() => {
    setIsTtsEnded(true)
    Tts.stop()
  }, [])

  useEffect(() => {
    const subscribe = Tts.addListener?.("tts-finish", onTtsFinish)
    return () => subscribe?.remove?.()
  }, [onTtsFinish])

  useEffect(() => {
    if (singleCard) {
      setSentence(singleCard.s ?? singleCard.t)
      return
    }
    if (_.isEmpty(l3card?.s)) {
      if (l2card?.t && l3card?.t) {
        setDefaultSentence(`${l2card.t} ${l3card.t}`)
      }
      return
    }
    setSentence(l3card?.s)
  }, [l3card, l2card, singleCard])

  // Start/Stop reading the sentence
  const onManageTts = useCallback(() => {
    if (!isTtsEnded) {
      setIsTtsEnded(true)
      // noinspection JSIgnoredPromiseFromCall
      Tts.stop()
    } else {
      setIsTtsEnded(false)
      pause()
      Tts.speak(sentence ?? defaultSentence ?? "")
    }
  }, [isTtsEnded, pause, sentence, defaultSentence])

  useEffect(() => {
    if ((sentence || defaultSentence) && userData && !isEditable(userData) && !vocInitDone) {
      Tts.speak(sentence ?? defaultSentence ?? "")
      setVocInitDone(true)
    }
  }, [defaultSentence, onManageTts, sentence, userData, vocInitDone])

  // useEffect to start the count automatically
  useEffect(() => {
    if (!firstStart.current || (!l3card && !l2card && !singleCard)) {
      return
    }
    if (userData && !isEditable(userData) && !isValidation) {
      start()
    }
    firstStart.current = false
  }, [defaultSentence, isValidation, l2card, l3card, sentence, singleCard, start, userData])

  // useEffect for the end of the count
  useEffect(() => {
    if (time === MAX_TIME) {
      goBack()
      reset()
    }
  }, [time, reset, navigation, l3card, sentence, userData, userDocRef, l3cardPath, goBack])

  const onShare = useCallback(async () => {
    await share({ message: sentence ?? defaultSentence })
  }, [sentence, defaultSentence])

  // play or pause the count
  const onManageCount = useCallback(() => {
    if (isStopped) {
      setIsTtsEnded(true)
      // noinspection JSIgnoredPromiseFromCall
      Tts.stop()
      start()
    } else {
      pause()
    }
  }, [pause, start, isStopped])

  const s = useMemo(
    () => ({
      indicator: {
        paddingTop: spacing,
      },
      container: [
        screenStyle,
        {
          paddingBottom: spacing * 2,
        },
      ],
      cardContainer: [
        styles.view,
        {
          marginHorizontal: spacing / 2,
        },
      ],
      progress: [
        styles.progressBar,
        {
          marginHorizontal: spacing / 2,
        },
      ],
      validateIconProps: {
        size: iconSize.regular,
        color: primary,
      },
      validateIconButton: {
        marginHorizontal: spacing,
      },
    }),
    [primary, screenStyle, spacing],
  )
  const onValidateChange = useCallback(async () => {
    if (!l3card?.id) {
      return
    }
    setValidatedCards(prevState => ({
      ...prevState,
      [validationCardKey]: !prevState[validationCardKey],
    }))
    await delay(500)
    goBack()
  }, [l3card, setValidatedCards, goBack, validationCardKey])

  return (
    <ScrollView
      style={styles.container}
      keyboardShouldPersistTaps="handled"
      contentContainerStyle={s.container}
    >
      {!userData?.theme.breadcrumbs ? <ProgressBar progress={1} /> : null}
      {singleCard || (l3card && l2card && (defaultSentence || sentence)) ? (
        <>
          <SentenceHeader
            onPress={onManageTts}
            isSingle={!!singleCard}
            sentence={sentence ?? defaultSentence ?? ""}
            {...{ isTtsEnded, setSentence }}
          />
          <View style={s.cardContainer}>
            {singleCard ? (
              <SentenceCard sentence isSingle card={FirCardToCard(singleCard)} />
            ) : (
              _.map([l2card, l3card], (elem, idx) => (
                <SentenceCard key={idx} sentence card={elem && FirCardToCard(elem)} />
              ))
            )}
          </View>

          {userData && !isEditable(userData) && isValidation ? (
            <ValidateCardButton
              style={s.validateIconButton}
              isCardValidated={validatedCards[validationCardKey]}
              onPress={onValidateChange}
            />
          ) : null}

          {/* @ts-ignore  */}
          <SentenceFooter
            {...{ time, onShare, l3cardPath, isStopped, onManageCount, isFreeSpace: !!singleCard }}
          />
        </>
      ) : undefined}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  view: {
    flexDirection: "row",
    justifyContent: "center",
  },
  progressBar: {
    height: 16,
    borderRadius: 50,
  },
  content: {
    maxWidth,
    width: "100%",
    alignSelf: "center",
  },
  container: {
    flex: 1,
  },
})

export default SentenceScreen
