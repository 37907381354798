import React, { createContext, Dispatch, FC, SetStateAction, useContext, useState } from "react"

export type ValidatedCards = Record<string, boolean>

interface ValidatedCardsProviderContext {
  validatedCards: ValidatedCards
  setValidatedCards: Dispatch<SetStateAction<ValidatedCards>>
}

const validatedCardsProviderContext = createContext<ValidatedCardsProviderContext>({
  validatedCards: {},
} as ValidatedCardsProviderContext)

const ValidatedCardsProvider: FC = ({ children }) => {
  const [validatedCards, setValidatedCards] = useState<ValidatedCards>({})

  return (
    <validatedCardsProviderContext.Provider value={{ validatedCards, setValidatedCards }}>
      {children}
    </validatedCardsProviderContext.Provider>
  )
}

export const useValidatedCards = () => {
  const context = useContext<ValidatedCardsProviderContext>(validatedCardsProviderContext)
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider")
  }
  return context
}

export default ValidatedCardsProvider
