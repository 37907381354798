import { Button, generateShadow } from "@siruplab/capsule"
import React, { FC, useCallback, useMemo } from "react"
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native"

import { useTheme } from "../utils/defaultStyle"

interface IProps {
  disabled?: boolean
  loading?: boolean
  saveIcon: () => JSX.Element
  saveText: string
  clearText: string
  clearIcon: () => JSX.Element
  onSave: () => void
  onClear: () => void
  bottomViewStyle?: StyleProp<ViewStyle>
}

export const BottomDoubleButtons: FC<IProps> = ({
  onSave,
  onClear,
  loading,
  disabled,
  saveIcon,
  saveText,
  clearIcon,
  clearText,
  bottomViewStyle,
}) => {
  const {
    dimensions: { spacing, buttonSize },
    colors: {
      error,
      primary,
      white: { highEmphasis: white },
      black: { disabled: disabledBlack, mediumEmphasis },
    },
  } = useTheme()

  const s = useMemo(
    () => ({
      button: [
        styles.button,
        {
          backgroundColor: white,
          marginVertical: spacing,
        },
      ],
      leftButton: {
        marginRight: spacing,
      },
      labelStyle: [
        {
          color: disabledBlack,
          marginLeft: spacing,
          textTransform: "none" as TextStyle["textTransform"],
        },
      ],
      buttonText: {
        marginLeft: spacing,
        color: disabledBlack,
      },
      buttonContent: {
        height: buttonSize,
      },
    }),
    [disabledBlack, spacing, white, buttonSize],
  )

  const ButtonComp = useCallback(
    ({ style, icon, text, onButtonPress, cancelDisabled, confirmDisabled, busy }) => (
      <Button
        icon={icon}
        disabled={cancelDisabled || confirmDisabled}
        loading={busy}
        onPress={onButtonPress}
        style={[s.button, style]}
        labelStyle={[
          s.labelStyle,
          { color: confirmDisabled || cancelDisabled ? disabledBlack : mediumEmphasis },
        ]}
        contentStyle={s.buttonContent}
      >
        {text}
      </Button>
    ),
    [disabledBlack, mediumEmphasis, s],
  )

  return (
    <View style={[styles.buttonMainView, bottomViewStyle]}>
      {ButtonComp({
        busy: false,
        color: error,
        icon: clearIcon,
        text: clearText,
        style: s.leftButton,
        cancelDisabled: disabled,
        onButtonPress: onClear,
      })}
      {ButtonComp({
        text: saveText,
        color: primary,
        icon: saveIcon,
        busy: loading,
        onButtonPress: onSave,
        confirmDisabled: disabled || loading,
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  buttonMainView: {
    width: 480,
    maxWidth: "100%",
    alignSelf: "center",
    flexDirection: "row",
  },
  button: {
    flex: 1,
    borderWidth: 0,
    justifyContent: "center",
    ...generateShadow(4),
  },
  buttonView: {
    height: 36,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
})
