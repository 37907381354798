import { generateShadow, MaterialCommunityIcons, MaterialIcons } from "@siruplab/capsule"
import React, { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ImageBackground, ScrollView, StyleSheet, View } from "react-native"
import ViewShot from "react-native-view-shot"
import { ReactPainter } from "react-painter"

import { BottomDoubleButtons, ColorsList } from "../../components"
import ImagesUploader from "../../components/ImagesUploader"
import { ICON_MEDIUM_SIZE, imageOptions } from "../../config/Constants"
import { maxWidthCardEditor } from "../../features/config/useTabland"
import { useTheme } from "../../utils/defaultStyle"
import { useShareDrawScreenLogic } from "../../utils/useShareDrawScreenLogic"
import { ns } from "./i18n/fr"

const TakePicScreen: FC = () => {
  const {
    screenStyle,
    colors: {
      black: { shadow, inactive, disabled: disabledBlack },
      white,
      error,
      primary,
    },
    dimensions: { spacing, borderRadius },
  } = useTheme()

  const {
    viewShotRef,
    source,
    setSource,
    onSave,
    selectedColor,
    changeColorWeb: changeColor,
    setDisabled,
    drawRefWeb,
    updated,
    maxSizeWeb: maxSize,
    onClearWeb,
  } = useShareDrawScreenLogic()
  const { t } = useTranslation(ns)
  const onClear = () => {
    setSource(undefined)
  }
  const s = useMemo(
    () => ({
      dialog: [
        styles.dialog,
        {
          marginLeft: spacing,
        },
      ],
      view: [
        screenStyle,
        styles.view,
        {
          maxWidth: maxWidthCardEditor,
          paddingTop: spacing,
          paddingHorizontal: spacing,
        },
      ],
      contentContainer: [
        screenStyle,
        styles.contentContainer,
        {
          padding: spacing,
        },
      ],
      image: [
        styles.image,
        {
          borderRadius,
        },
      ],
      viewShot: {
        flex: 1,
        width: maxSize,
        height: maxSize,
        aspectRatio: 1,
      },
      canvas: [
        styles.canvas,
        {
          borderRadius,
          backgroundColor: white,
          maxWidth: maxWidthCardEditor,
        },
      ],
      emptyContentContainer: [
        styles.emptyContentContainer,
        {
          borderRadius,
          backgroundColor: shadow,
        },
      ],
    }),
    [spacing, screenStyle, borderRadius, maxSize, white, shadow],
  )

  const saveIcon = useCallback(
    () => (
      <MaterialIcons
        name="add-circle-outline"
        size={ICON_MEDIUM_SIZE}
        color={!source ? disabledBlack : primary}
      />
    ),
    [disabledBlack, primary, source],
  )

  const clearIcon = useCallback(
    () => (
      <MaterialCommunityIcons
        name="camera-off"
        size={ICON_MEDIUM_SIZE}
        color={!source ? disabledBlack : error}
      />
    ),
    [disabledBlack, error, source],
  )

  return (
    <ScrollView contentContainerStyle={s.view}>
      {source ? (
        <ViewShot style={s.emptyContentContainer} ref={viewShotRef}>
          <View>
            <ReactPainter
              render={({ getCanvasProps }) => (
                <ImageBackground
                  source={{
                    uri: source?.path,
                  }}
                  style={styles.image}
                >
                  <canvas {...getCanvasProps({})} onClick={() => setDisabled(false)} />
                </ImageBackground>
              )}
              key={updated}
              ref={drawRefWeb}
              width={maxSize}
              height={maxSize}
            />
          </View>
        </ViewShot>
      ) : (
        <ImagesUploader
          setSource={setSource}
          updatePics={setSource}
          style={s.emptyContentContainer}
          imageOptions={imageOptions}
          autoLaunch={false}
        >
          <MaterialIcons name="photo-camera" size={80} color={inactive} />
        </ImagesUploader>
      )}

      <View style={styles.btnContainer}>
        <ColorsList
          drawRef={drawRefWeb}
          isFromTakePicScreen={true}
          source={source}
          setSource={setSource}
          selectedColor={selectedColor}
          onSelectColor={changeColor}
          onClearWeb={onClearWeb} // We need to pass this function to the ColorsList component to be able to clear the canvas on web only instead of using hook
        />
        <BottomDoubleButtons
          {...{ onSave, onClear }}
          clearIcon={clearIcon}
          disabled={!source}
          saveText={t("save")}
          clearText={t("erase")}
          saveIcon={saveIcon}
        />
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  dialog: {
    alignSelf: "flex-start",
  },
  view: {
    flex: 1,
  },
  image: {
    maxWidth: maxWidthCardEditor,
    width: "100%",
    height: "100%",
    aspectRatio: 1,
  },
  contentContainer: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  emptyContentContainer: {
    aspectRatio: 1,
    maxWidth: maxWidthCardEditor,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  viewShot: {
    flex: 1,
    aspectRatio: 1,
  },
  canvas: {
    ...generateShadow(4),
  },
  btnContainer: {
    alignItems: "center",
  },
})

export default TakePicScreen
